import React from "react";
import { FaMoneyBillWave, FaWallet } from "react-icons/fa";

const Tarifs = () => {
  return (
    <div className="   p-8 max-w-lg mx-auto shadow-2xl  ">
      <div className="mb-8">
        <h2 className="text-xl font-black mb-3 flex items-center text-white">
          <FaMoneyBillWave className="mr-2 text-bleu" /> Tarifs pour les
          Recharges
        </h2>
        <p className="text-gray-300 border-l-4 border-gray-600 pl-4 italic">
          Nous ne prenons aucun frais pour les recharges, elles sont totalement
          gratuites.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-black mb-3 flex items-center text-white">
          <FaWallet className="mr-2 text-bleu" /> Tarifs pour les Retraits
        </h2>
        <p className="text-gray-300 border-l-4 border-gray-600 pl-4 italic">
          Nous ne prenons aucun frais pour les retraits, ils sont totalement
          gratuits.
        </p>
      </div>

      <div className="bg-gray-800 p-5 rounded-lg shadow-inner transition transform hover:-translate-y-1">
        <p className="text-sm text-gray-300">
          <strong className="text-white">NB :</strong> Les frais d'envoi (
          <strong className="text-white">100 F</strong>) du moyen de paiement (
          <strong className="text-white">MTN MoMo</strong>) sont à la charge du
          client.
        </p>
      </div>
    </div>
  );
};

export default Tarifs;
