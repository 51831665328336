import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
const TransactionCard = ({
  icon,
  title,
  date,
  amount,
  id,
  bgColor,
  idTran,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/clients/infos-transaction/${id}/${idTran}`);
      }}
      className="bg-[#272728] md:mx-52 mx-6 flex flex-row h-16 rounded-2xl border border-[#333333] opacity-100  mt-5 items-center px-1"
    >
      <div
        className={`w-10 h-10 ${bgColor} rounded-full text-white flex mx-2 justify-center items-center`}
      >
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="flex flex-col basis-3/6 justify-center items-center">
        <div className="text-white text-sm">{title}</div>
        <div className="text-white text-[10px] font-light">{date}</div>
      </div>

      <div className="flex flex-col basis-2/6">
        <div className="text-white text-sm">{amount}</div>
        <div className="text-white text-[10px] font-light">{id}</div>
      </div>
    </div>
  );
};

export default TransactionCard;
