import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Tarifs from "./Tarifs"; // Import du composant Tarifs

import Procedure from "./Procedure";

import CommentRetirer from "./CommentRetirer";
import NotificationWhatsApp from "./NotificationWhatsApp";

import AssistanceWhatsApp from "./AssistanceWhatsApp";

const AideIndex = () => {
  const navigate = useNavigate();
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleQuestionClick = (question) => {
    setActiveQuestion(activeQuestion === question ? null : question);

    // Actions spécifiques pour chaque question
    switch (question) {
      case "Tarifs":
        console.log("Tarifs clicked");
        break;
      case "Comment se recharger?":
        console.log("Comment se recharger? clicked");
        break;
      case "Comment retirer?":
        console.log("Comment retirer? clicked");
        break;
      case "Pourquoi je ne reçois pas de notification WhatsApp?":
        console.log(
          "Pourquoi je ne reçois pas de notification WhatsApp? clicked"
        );
        break;
      case "Nous contacter":
        console.log("Nous contacter clicked");
        // alert("jiid");
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-black min-h-screen flex flex-col pt-5 justify-center ">
      <div className=" text-white md:mt-16 mt-2 md:mx-80">
        <div className="flex flex-col mx-16 space-y-6">
          {[
            "Tarifs",
            "Comment se recharger?",
            "Comment retirer?",
            "Pourquoi je ne reçois pas de notification WhatsApp?",
            "Nous contacter",
          ].map((question, index) => (
            <div key={index}>
              <div
                className="flex flex-row justify-between items-center cursor-pointer"
                onClick={() => handleQuestionClick(question)}
              >
                <div className="font-bold">{question}</div>
                <div>
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </div>
              </div>
              {/* Affiche le composant Tarifs si "Tarifs" est sélectionné, sinon affiche un texte descriptif */}
              {activeQuestion === question && (
                <div className="mt-2 bg-gray-800 text-sm text-gray-300 rounded-lg transition-all duration-300 ease-in-out">
                  {question === "Tarifs" && (
                    <Tarifs /> // Rend le composant Tarifs
                  )}

                  {question === "Comment se recharger?" && (
                    <Procedure /> // Rend le composant Tarifs
                  )}

                  {question === "Comment retirer?" && (
                    <CommentRetirer /> // Rend le composant Tarifs
                  )}

                  {question ===
                    "Pourquoi je ne reçois pas de notification WhatsApp?" && (
                    <NotificationWhatsApp /> // Rend le composant Tarifs
                  )}

                  {question === "Nous contacter" && (
                    <AssistanceWhatsApp /> // Rend le composant Tarifs
                  )}
                </div>
              )}
              <hr className="border-gray-600 my-2" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AideIndex;
