import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  // Si l'utilisateur n'est pas authentifié (isAuthenticated != 1), rediriger
  if (isAuthenticated != "1") {
    return <Navigate to="/login" replace />;
  }

  // Si authentifié, afficher les enfants (les routes protégées)
  return children;
};

export default ProtectedRoute;
