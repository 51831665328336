import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
  faArrowCircleLeft,
  faKeyboard,
  faCalendar,
  faCalendarDay,
  faCalendarCheck,
  faShower,
  faEye,
  faNotesMedical,
  faBook,
  faCalendarWeek,
  faMon,
} from "@fortawesome/free-solid-svg-icons";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "react-feather";
import { useState, useEffect, useContext } from "react";

import InfosRetrait from "./InfosRetrait";

import Filtres from "../Transactions/Filtres";

import InfosRetraitpc from "./InfosRetraitPc";

import { CopyToClipboard } from "react-copy-to-clipboard";
//import { ToastContainer, toast } from "react-toastify";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles
import { Link, useNavigate } from "react-router-dom";

//import { ajouterTransactionretrait } from "../../requtes/clients/ajouterTransactionretrait";

//import { ajouterTransactionretrait } from "../../requtes/clients/ajouterdepot.js";

import ajouterdepot from "../../requtes/clients/ajouterdepot.js";

import InfosContext from "../../contexts/InfosAppProvider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import du CSS

export default function RetraitManuel() {
  //const [inputValue, setInputValue] = useState("4444444444444");
  //const [montant, setMontant] = useState("montant");

  const navigate = useNavigate(); // Utilisation de useNavigate
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);

  const idUser = localStorage.getItem("idUser");

  const [inputValue, setInputValue] = useState(userInfos.idCompte1xbet);
  const [nom, setNom] = useState(userInfos.nomMomo);
  const [numero, setNumero] = useState(userInfos.numeroMomo);
  const [montant, setMontant] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple

  const [vide, setVide] = useState(0);

  const [open, setOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const [mon, setMon] = useState(0);

  const [code, setCode] = useState(0);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    if (montant > 99) {
      setVisible(true);
      setMon(montant - 100);
    } else {
      setVisible(false);
    }
  }, [montant]);

  useEffect(() => {
    // Fonction pour vérifier si l'écran est de grande taille
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Vérifie si l'écran est large (PC)
    };

    // Vérification initiale de la taille de l'écran
    handleResize();

    // Ajoute un écouteur d'événements pour détecter les changements de taille d'écran
    window.addEventListener("resize", handleResize);

    // Nettoie l'écouteur lorsque le composant est démonté
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Example condition to determine if the sheet can be dismissed
  const canDismiss = () => {
    // Replace with your own logic
    // For example, check if there's unsaved data
    return true; // Change to true when you want to allow dismiss
  };

  const handleDismiss = () => {
    if (canDismiss()) {
      setOpen(false); // Only close if the condition is met
    }
  };

  //const code = "*880*41*1000000*589170#";

  // const code = "*880*41*000*589170#";
  // const encodedCode = `${code.slice(0, -1)}%23`; // Encode le # avec %23

  const handleClickPhone = () => {
    // Ouvre l'application de téléphone avec le code
    window.location.href = `tel:${code}`;
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeMontant = (event) => {
    setMontant(event.target.value);
  };

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const handleChangeNom = (event) => {
    setNom(event.target.value);
  };

  const handleChangeNumero = (event) => {
    setNumero(event.target.value);
  };

  //const amounts = ["500", "1.000", "1.500", "5.000"];

  const newValuesStr = infosAdmin.montant; // Par exemple "1500, 2500, 3500"

  // Convertir la chaîne en un tableau
  const updatedAmounts = newValuesStr
    .split(",")
    .map((value) => parseInt(value.trim()));

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [colors, setColors] = useState(["black", "black", "black", "black"]);

  const handleClick = (index) => {
    const newColors = colors.map((color, i) =>
      i === index ? "bg-bleu" : "bg-black"
    );
    setColors(newColors);
    setSelectedAmount(updatedAmounts[index]);
    // Enlève les points (ou autres séparateurs de milliers) avant de définir le montant
    // const amountWithoutCommas = amounts[index].replace(/\./g, "");
    setSelectedAmount(updatedAmounts[index]);
    setMontant(updatedAmounts[index]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // Validation personnalisée
    if (!inputValue || !montant || !code || !numero || !nom) {
      // alert("Veuillez remplir tous les champs requis.");
    } else {
      // Logique d'envoi du formulaire
      /* alert(
        `Données soumises:\nID 1xBet: ${inputValue}\nMontant: ${montant}\nID de la transaction: ${nom}`
      );
      */

      /*
 type,
  idUser,
  idCompte1xbet,
  codeRetrait,
  moyenPaiement,
  montant,
  mode,
  idTransactionMobile
      */

      const handleTransaction = async () => {
        try {
          /*
           type: type,
      idUser: idUser,
      idCompte1xbet: idCompte1xbet,
      codeRetrait: codeRetrait,
      moyenPaiement: moyenPaiement,
      montant: montant,
      mode: mode,
      numeroRetraitMomo: numeroRetraitMomo,
      nomMtnMomo: nomMtnMomo,

          */
          // Appel de la fonction ajouterTransaction
          const res = await ajouterdepot(
            "retrait",
            idUser,
            inputValue,
            code,
            "MTN",
            montant,
            "manuel",
            numero,
            nom,
            token
          );

          localStorage.setItem("depotxbet", inputValue);
          localStorage.setItem("depotmontant", montant);

          localStorage.setItem("depotnumero", numero);
          localStorage.setItem("depotnom", nom);
          fetchUserInfos(idUser);
          setLoading(false);
          navigate(`/clients/retrait-manuel-succes/${res.idTransaction} `);
          // alert("Transaction ajoutée avec succès" + res.idTransaction);
        } catch (error) {
          setLoading(false);
          // Gérer l'erreur ici
          // alert("Erreur lors de l'ajout de la transaction :", error);
        }
      };

      handleTransaction();

      // ha;
    }
  };
  return (
    <>
      <div className="bg-black min-h-screen flex flex-col pt-5 md:hidden">
        <div className="text-white h-10  font-albert text-1xl px-3 fixed top-0 w-full bg-black flex items-center">
          <FontAwesomeIcon icon={faArrowLeft} className="px-3" />
          Retour
        </div>

        <div className="text-white font-albert   px-6 mt-8 text-[17px] ">
          {" "}
          Remplissez soigneusement le formulaire avant de soumettre.
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col mt-5">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="bg-[#272728] shadow-2xl    h-[47px] flex font-albert flex-row items-center justify-center rounded-xl border border-[#333333] opacity-100 mx-5 mt-1 text-white"
          >
            <div>
              {" "}
              <FontAwesomeIcon icon={faCalendarWeek} />{" "}
              <span className="ml-3"> Voir la procédure de Retrait</span>
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[12px]  text-white">
                Saisissez l'ID du compte 1xBet
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="number"
                pattern="\d*"
                placeholder="ID 1xbet"
                value={inputValue}
                required
                onChange={handleChange}
              />
            </div>

            <div className="text-white font-mono text-[12px] px-6">
              Vous pouvez changer l'ID 1xbet par défaut dans les paramètres.
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[12px] font-albert  text-white">
                Saisissez le code de Retrait 1xBet
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px] pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
                type="text"
                placeholder="Code de retrait 1xbet"
                required
                onChange={handleChangeCode}
                onInput={(e) => {
                  // Limite l'entrée à 4 caractères (peu importe le type)
                  e.target.value = e.target.value.slice(0, 4);
                }}
              />
            </div>
          </div>

          <div className=" flex flex-col mt-8">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Choisissez le montant à recharger
              </div>
            </div>

            <div className="flex space-x-4 mb-4 mx-9">
              {updatedAmounts.map((amount, index) => (
                <div
                  key={amount}
                  className={`w-16 h-8 flex items-center justify-center border-2 border-[#272728FF] mx-3 mt-5 rounded-xl ${colors[index]} cursor-pointer transition duration-300`}
                  onClick={() => handleClick(index)}
                >
                  <span className="text-lg text-white font-semibold">
                    {amount}
                  </span>
                </div>
              ))}
            </div>

            <div className=" flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px]  text-white">
                  Ou saisissez votre montant ici
                </div>
                <div className="font-albert-sans text-[15px]  text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px]   pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                  type="number"
                  pattern="\d*"
                  placeholder="Montant"
                  value={montant}
                  required
                  onChange={handleChangeMontant}
                />
              </div>
            </div>
          </div>

          {visible && (
            <div className=" flex flex-col mt-5 mb-6 ">
              <div className="flex flex-row justify-between mx-7">
                <button className="text-white h-11 flex flex-row   items-center justify-center space-x-3 border-[#0087BF80] rounded-2xl px-2 bg-[#0087BF80] w-full py-1 ">
                  <div>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                    >
                      <g fill="white" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M15.702 10.115C14.78 9.742 13.47 9.5 12 9.5v-1c1.567 0 3.008.256 4.076.687c.533.216.998.484 1.337.809c.34.325.587.74.587 1.226c0 .487-.246.901-.587 1.227c-.34.324-.804.593-1.337.808c-1.068.432-2.51.687-4.076.687c-1.567 0-3.008-.255-4.076-.687c-.533-.215-.998-.484-1.337-.808c-.34-.326-.587-.74-.587-1.227h1c0 .127.062.297.278.504s.558.417 1.02.604c.923.373 2.231.614 3.702.614c1.47 0 2.78-.241 3.702-.614c.462-.187.803-.397 1.02-.604s.278-.377.278-.504s-.062-.297-.278-.503c-.217-.208-.558-.418-1.02-.604" />
                        <path d="M6.5 11.167a.5.5 0 0 1 .5.5v3.11c0 .128.062.298.278.504c.217.208.558.418 1.02.605c.923.372 2.231.614 3.702.614c1.47 0 2.78-.242 3.702-.614c.462-.187.803-.397 1.02-.605c.216-.206.278-.376.278-.503v-3.111a.5.5 0 0 1 1 0v3.11c0 .488-.246.902-.587 1.227s-.804.593-1.337.809c-1.068.431-2.51.687-4.076.687c-1.567 0-3.008-.256-4.076-.687c-.533-.215-.998-.484-1.337-.809c-.34-.325-.587-.74-.587-1.226v-3.111a.5.5 0 0 1 .5-.5" />
                        <path d="M8.7 16.726V13h1v3.726zm4.4-.226v-3h1v3zM2.278 4.719c-.216.206-.278.376-.278.503s.062.297.278.504s.558.417 1.02.604c.923.373 2.231.614 3.702.614c1.47 0 2.78-.241 3.702-.614c.462-.187.803-.397 1.02-.604S12 5.349 12 5.222s-.062-.297-.278-.503c-.217-.208-.558-.418-1.02-.605C9.779 3.742 8.47 3.5 7 3.5s-2.78.242-3.702.614c-.462.187-.803.397-1.02.605m.646-1.532C3.992 2.756 5.434 2.5 7 2.5c1.567 0 3.008.256 4.076.687c.533.216.998.484 1.337.809c.34.325.587.74.587 1.226c0 .487-.246.901-.587 1.227c-.34.324-.804.593-1.337.808c-1.068.432-2.51.687-4.076.687c-1.567 0-3.008-.255-4.076-.687c-.533-.215-.998-.484-1.337-.808C1.247 6.123 1 5.709 1 5.222c0-.486.246-.9.587-1.226s.804-.593 1.337-.809" />
                        <path d="M1.5 5.167a.5.5 0 0 1 .5.5v3.11c0 .128.062.298.278.504c.217.208.558.418 1.02.605c.923.372 2.231.614 3.702.614c1.47 0 2.78-.242 3.702-.614c.462-.187.803-.397 1.02-.605c.216-.206.278-.376.278-.503V5.667a.5.5 0 0 1 1 0v3.11c0 .488-.246.902-.587 1.227s-.804.594-1.337.809c-1.068.431-2.51.687-4.076.687c-1.567 0-3.008-.256-4.076-.687c-.533-.216-.998-.484-1.337-.809C1.247 9.68 1 9.264 1 8.778V5.667a.5.5 0 0 1 .5-.5" />
                        <path d="M3.7 10.5V7.113h1V10.5zm4.4 0v-3h1v3z" />
                      </g>
                    </svg>{" "}
                  </div>
                  <div> Vous recevrez {mon} par Momo</div>
                </button>
              </div>
            </div>
          )}

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Numéro de retrait MTN MoMo
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="number"
                inputMode="numeric"
                pattern="\d*"
                placeholder="229.."
                value={numero}
                required
                onChange={handleChangeNumero}
              />
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Nom et Prénom MTN MoMo
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="number"
                placeholder="229.."
                pattern="\d*"
                value={nom}
                required
                onChange={handleChangeNom}
              />
            </div>
          </div>

          <div className=" flex flex-col mt-5 mb-6 pb-10">
            <div className="flex flex-row justify-between mx-1">
              <button
                className="bg-bleu w-[336px] pr-[20px] h-12 flex flex-row justify-center items-center text-white rounded-2xl border border-[#333333]  mx-5 mt-5"
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-8 h-8 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}

                <span className="text-white">
                  {loading ? "Enregistrement..." : "  soumettre"}
                </span>
              </button>
            </div>
          </div>
        </form>
        {
          //fin
        }
        {!isLargeScreen && (
          <BottomSheet
            open={open}
            onDismiss={handleDismiss} // Use custom dismiss logic
            snapPoints={({ minHeight }) => [minHeight, 490]} // Minimum height of 450
          >
            <InfosRetrait onClose={handleDismiss} />
          </BottomSheet>
        )}
      </div>

      <div className="bg-black min-h-screen hidden flex-col pt-5 md:flex   px-56 ">
        <div className="text-white font-albert px-6 mt-2 text-[18px] mx-72">
          Retrait
        </div>
        <div className="text-white font-albert   px-6 mt-8 text-[17px] mx-72 ">
          {" "}
          Remplissez soigneusement le formulaire avant de soumettre.
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col mt-5 mx-72">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="bg-[#272728] shadow-2xl    h-[47px] flex font-albert flex-row items-center justify-center rounded-xl border border-[#333333] opacity-100 mx-5 mt-1 text-white"
          >
            <div>
              {" "}
              <FontAwesomeIcon icon={faCalendarWeek} />{" "}
              <span className="ml-3"> Voir la procédure de Retrait</span>
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[12px]  text-white">
                Saisissez l'ID du compte 1xBet
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="text"
                placeholder="ID 1xbet"
                value={inputValue}
                onChange={handleChange}
                required
              />
            </div>

            <div className="text-white font-mono text-[12px] px-6">
              Vous pouvez changer l'ID 1xbet par défaut dans les paramètres.
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[12px] font-albert  text-white">
                Saisissez le code de Retrait 1xBet
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="text"
                placeholder="Code de retrait 1xbet"
                onChange={handleChangeCode}
                required
              />
            </div>
          </div>

          <div className=" flex flex-col mt-8">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Choisissez le montant à recharger
              </div>
            </div>

            <div className="flex space-x-4 mb-4 mx-9">
              {updatedAmounts.map((amount, index) => (
                <div
                  key={amount}
                  className={`w-16 h-8 flex items-center  justify-center border-2 border-[#272728FF] mx-1 mt-5 rounded-[8px] ${colors[index]} cursor-pointer transition duration-300`}
                  onClick={() => handleClick(index)}
                >
                  <span className="text-lg text-white font-semibold">
                    {amount}
                  </span>
                </div>
              ))}
            </div>

            <div className=" flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px]  text-white">
                  Ou saisissez votre montant ici
                </div>
                <div className="font-albert-sans text-[15px]  text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px]   pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                  type="number"
                  placeholder="Montant"
                  value={montant}
                  onChange={handleChangeMontant}
                  required
                />
              </div>
            </div>
          </div>

          {visible && (
            <div className=" flex flex-col mt-5 mb-6 ">
              <div className="flex flex-row justify-between mx-7">
                <button className="text-white h-11 flex flex-row   items-center justify-center space-x-3 border-[#0087BF80] rounded-2xl px-2 bg-[#0087BF80] w-full py-1 ">
                  <div>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                    >
                      <g fill="white" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M15.702 10.115C14.78 9.742 13.47 9.5 12 9.5v-1c1.567 0 3.008.256 4.076.687c.533.216.998.484 1.337.809c.34.325.587.74.587 1.226c0 .487-.246.901-.587 1.227c-.34.324-.804.593-1.337.808c-1.068.432-2.51.687-4.076.687c-1.567 0-3.008-.255-4.076-.687c-.533-.215-.998-.484-1.337-.808c-.34-.326-.587-.74-.587-1.227h1c0 .127.062.297.278.504s.558.417 1.02.604c.923.373 2.231.614 3.702.614c1.47 0 2.78-.241 3.702-.614c.462-.187.803-.397 1.02-.604s.278-.377.278-.504s-.062-.297-.278-.503c-.217-.208-.558-.418-1.02-.604" />
                        <path d="M6.5 11.167a.5.5 0 0 1 .5.5v3.11c0 .128.062.298.278.504c.217.208.558.418 1.02.605c.923.372 2.231.614 3.702.614c1.47 0 2.78-.242 3.702-.614c.462-.187.803-.397 1.02-.605c.216-.206.278-.376.278-.503v-3.111a.5.5 0 0 1 1 0v3.11c0 .488-.246.902-.587 1.227s-.804.593-1.337.809c-1.068.431-2.51.687-4.076.687c-1.567 0-3.008-.256-4.076-.687c-.533-.215-.998-.484-1.337-.809c-.34-.325-.587-.74-.587-1.226v-3.111a.5.5 0 0 1 .5-.5" />
                        <path d="M8.7 16.726V13h1v3.726zm4.4-.226v-3h1v3zM2.278 4.719c-.216.206-.278.376-.278.503s.062.297.278.504s.558.417 1.02.604c.923.373 2.231.614 3.702.614c1.47 0 2.78-.241 3.702-.614c.462-.187.803-.397 1.02-.604S12 5.349 12 5.222s-.062-.297-.278-.503c-.217-.208-.558-.418-1.02-.605C9.779 3.742 8.47 3.5 7 3.5s-2.78.242-3.702.614c-.462.187-.803.397-1.02.605m.646-1.532C3.992 2.756 5.434 2.5 7 2.5c1.567 0 3.008.256 4.076.687c.533.216.998.484 1.337.809c.34.325.587.74.587 1.226c0 .487-.246.901-.587 1.227c-.34.324-.804.593-1.337.808c-1.068.432-2.51.687-4.076.687c-1.567 0-3.008-.255-4.076-.687c-.533-.215-.998-.484-1.337-.808C1.247 6.123 1 5.709 1 5.222c0-.486.246-.9.587-1.226s.804-.593 1.337-.809" />
                        <path d="M1.5 5.167a.5.5 0 0 1 .5.5v3.11c0 .128.062.298.278.504c.217.208.558.418 1.02.605c.923.372 2.231.614 3.702.614c1.47 0 2.78-.242 3.702-.614c.462-.187.803-.397 1.02-.605c.216-.206.278-.376.278-.503V5.667a.5.5 0 0 1 1 0v3.11c0 .488-.246.902-.587 1.227s-.804.594-1.337.809c-1.068.431-2.51.687-4.076.687c-1.567 0-3.008-.256-4.076-.687c-.533-.216-.998-.484-1.337-.809C1.247 9.68 1 9.264 1 8.778V5.667a.5.5 0 0 1 .5-.5" />
                        <path d="M3.7 10.5V7.113h1V10.5zm4.4 0v-3h1v3z" />
                      </g>
                    </svg>{" "}
                  </div>
                  <div> Vous recevrez {mon} par Momo</div>
                </button>
              </div>
            </div>
          )}

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Numéro de retrait MTN MoMo
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="number"
                inputMode="numeric"
                pattern="\d*"
                placeholder="229.."
                value={numero}
                required
                onChange={handleChangeNumero}
              />
            </div>
          </div>

          <div className=" flex flex-col mt-5">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px]  text-white">
                Nom et Prénom MTN MoMo
              </div>
              <div className="font-albert-sans text-[15px]  text-white">
                requis
              </div>
            </div>

            <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
              <input
                className="w-[307px] h-[47px]   pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
                type="number"
                placeholder="229.."
                pattern="\d*"
                value={nom}
                required
                onChange={handleChangeNom}
              />
            </div>
          </div>

          <div className=" flex flex-col mt-5 mb-6 pb-10">
            <div className="flex flex-row justify-between mx-1">
              <button
                className="bg-bleu w-full pr-[20px] h-12 flex flex-row justify-center items-center text-white rounded-2xl border border-[#333333]  mx-5 mt-5"
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-8 h-8 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}

                <span className="text-white">
                  {loading ? "Enregistrement..." : "  soumettre"}
                </span>
              </button>
            </div>
          </div>
        </form>

        {
          //fin
        }

        {open && (
          <div className="fixed inset-0 hidden md:flex  items-center justify-center bg-black opacity-100">
            <InfosRetraitpc onClose={handleDismiss} />
          </div>
        )}
      </div>
    </>
  );
}
