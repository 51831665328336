import Swal from "sweetalert2";

export const ajouteEffectueCaisse = () => {
  return Swal.fire({
    title: "Succès!",
    text: "Ajout effectué avec succès.",
    icon: "success",
    confirmButtonText: "OK",
    customClass: {
      popup: "max-w-sm p-6 rounded-lg shadow-lg", // Taille, padding et coins arrondis
      title: "text-xl font-bold text-gray-800", // Style du titre
      content: "text-gray-700", // Style du texte
      confirmButton:
        "bg-green-600 text-white hover:bg-green-700 py-2 px-4 rounded-lg transition", // Style du bouton "OK"
    },
    heightAuto: false, // Pour empêcher la hauteur automatique
    backdrop: `rgba(0, 0, 0, 0.5)`, // Arrière-plan semi-transparent
    position: "center", // Positionne la popup au centre
  });
};
