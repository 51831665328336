const CommentRetirer = () => {
  return (
    <div className="max-w-2xl mx-auto mt-10 p-8  rounded-lg shadow-lg">
      <div className="space-y-8">
        {/* Première étape */}
        <div>
          <h2 className="text-xl font-semibold text-white mb-4">
            1ère étape (dans votre compte 1XBET)
          </h2>
          <ul className="list-decimal list-inside text-white space-y-2">
            <li>Appuyez sur Retirer</li>
            <li>Choisissez le moyen de paiement 1XBET (Liquide/Espèces)</li>
            <li>Saisissez le Montant à Retirer</li>
            <li>Choisissez Ville : Parakou</li>
            <li>
              Choisissez Rue : Cela dépend de la Caisse. Quand vous soumettez la
              demande, appuyez sur "Voir la Procédure de retrait" pour voir quoi
              mettre exactement.
            </li>
            <li>
              Confirmez avec le code envoyé par 1XBET sur le numéro lié au
              compte, puis validez.
            </li>
            <li>
              Patientez un instant que la transaction affiche "Approuvée", puis
              appuyez sur "Obtenir le Code". Une fois le code généré, copiez-le
              et redirigez vers l’option Retirer.
            </li>
          </ul>
        </div>

        {/* Deuxième étape */}
        <div>
          <h2 className="text-xl font-semibold text-white mb-4">
            2ème étape (dans l’option retirer du site)
          </h2>
          <ul className="list-decimal list-inside text-white space-y-2">
            <li>Collez le code obtenu dans la case Code</li>
            <li>Saisissez votre ID 1XBET</li>
            <li>Saisissez le numéro MTN MoMo</li>
            <li>Saisissez le nom lié à ce numéro MTN MoMo</li>
            <li>Appuyez sur le bouton Retirer</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommentRetirer;
