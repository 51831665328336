import { faArrowLeft, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";

import InfosContext from "../../contexts/InfosAppProvider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import du CSS

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import {
  faChevronDown,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

import { CopyToClipboard } from "react-copy-to-clipboard";
//import { ToastContainer, toast } from "react-toastify";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles
import { Link, useNavigate } from "react-router-dom";

import { ajouterTransaction } from "../../requtes/clients/ajouterTransaction";
export default function DepotManuel() {
  const [textToCopy, setTextToCopy] = useState("Voici le texte à copier !");

  const notifySuccess = () => {
    toast.success("code copié", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000, // 3 secondes avant de disparaître
    });
  };
  const navigate = useNavigate(); // Utilisation de useNavigate
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);

  const [inputValue, setInputValue] = useState(userInfos.idCompte1xbet);
  const [montant, setMontant] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const amounts = ["500", "1.000", "1.500", "5.000"];
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [colors, setColors] = useState(["black", "black", "black", "black"]);

  const [visible, setVisible] = useState(false);

  //const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours

  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple

  // const newValuesStr = infosAdmin.montant;

  //c//onst newValues = newValuesStr.split(", ").map((value) => parseInt(value));

  // Remplacer les anciennes valeurs par les nouvelles

  /*
  const updatedAmounts = amounts.map(
    (_, index) => newValues[index] || newValues[newValues.length - 1]
  );
*/

  useEffect(() => {
    //const mon = parseFloat(event.target.value); // Obtenir la valeur de l'input
    if (!isNaN(montant) && montant > 99) {
      console.log("Montant valide");
      setTextToCopy(`*880*41*589170*${montant}#`);
      setVisible(true);
      // Effectue d'autres actions si le montant est valide
    } else {
      setVisible(false);
    }
  }, [montant]);

  const newValuesStr = infosAdmin.montant; // Par exemple "1500, 2500, 3500"

  // Convertir la chaîne en un tableau
  const updatedAmounts = newValuesStr
    .split(",")
    .map((value) => parseInt(value.trim()));

  const idUser = localStorage.getItem("idUser");

  //alert("id user est " + idUser);

  const handleClickPhone = () => {
    const code = "*880*41*000*589170#";
    window.location.href = `tel:${code}`;
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeMontant = (event) => {
    setMontant(event.target.value);
    const montantt = parseFloat(event.target.value); // Obtenir la valeur de l'input
    if (!isNaN(montantt) && montant > 99) {
      console.log("Montant valide");
      setVisible(true);
      // Effectue d'autres actions si le montant est valide
    }
  };

  const handleChangeTransactionId = (event) => {
    setTransactionId(event.target.value);
  };

  const handleClick = (index) => {
    const newColors = colors.map((color, i) =>
      i === index ? "bg-bleu" : "bg-black"
    );
    setColors(newColors);
    setSelectedAmount(updatedAmounts[index]);
    //  const amountWithoutCommas = updatedAmounts[index].replace(/\./g, "");
    setMontant(updatedAmounts[index]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // Validation personnalisée
    if (!inputValue || !montant || !transactionId) {
      // alert("Veuillez remplir tous les champs requis.");
    } else {
      // Logique d'envoi du formulaire
      /* alert(
        `Données soumises:\nID 1xBet: ${inputValue}\nMontant: ${montant}\nID de la transaction: ${transactionId}`
      );
      */

      /*
 type,
  idUser,
  idCompte1xbet,
  codeRetrait,
  moyenPaiement,
  montant,
  mode,
  idTransactionMobile
      */

      const handleTransaction = async () => {
        try {
          // Appel de la fonction ajouterTransaction
          const res = await ajouterTransaction(
            "depot",
            idUser,
            inputValue,
            "rien",
            "MTN",
            montant,
            "manuel",
            transactionId,
            token
          );

          localStorage.setItem("depotxbet", inputValue);
          localStorage.setItem("depotmontant", montant);
          fetchUserInfos(idUser);
          setLoading(false);
          navigate(`/clients/depot-manuel-succes/${res.idTransaction} `);
          // alert("Transaction ajoutée avec succès" + res.idTransaction);
        } catch (error) {
          setLoading(false);
          // Gérer l'erreur ici
          // alert("Erreur lors de l'ajout de la transaction :", error);
        }
      };

      handleTransaction();

      // ha;
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-black min-h-screen flex flex-col pt-5 md:hidden">
        <div
          onClick={() => {
            window.history.back();
          }}
          className="text-white h-10 font-albert text-1xl px-3 fixed top-0 w-full bg-black flex items-center"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="px-3" />
          Retour
        </div>

        <div className="text-white font-albert px-6 mt-8 text-[18px]">
          Remplissez soigneusement le formulaire avant de soumettre.
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px] text-white">
              Saisissez l'ID du compte 1xBet
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              className="w-[307px] h-[47px] pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
              type="number"
              pattern="\d*"
              inputMode="numeric"
              placeholder="ID 1xbet"
              value={inputValue}
              onChange={handleChange}
              required
            />
          </div>

          <div className="text-white font-mono text-[12px] px-6">
            Vous pouvez changer l'ID 1xbet par défaut dans les paramètres.
          </div>

          <div className="flex flex-col mt-8">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px] text-white">
                Choisissez le montant à recharger
              </div>
            </div>

            <div className="flex space-x-4 mb-4 mx-9">
              {updatedAmounts.map((amount, index) => (
                <div
                  key={amount}
                  className={`w-16 h-8 flex items-center  justify-center border-2 border-[#272728FF] mx-1 mt-5 rounded-[8px] ${colors[index]} cursor-pointer transition duration-300`}
                  onClick={() => handleClick(index)}
                >
                  <span className="text-lg text-white font-semibold">
                    {amount}
                  </span>
                </div>
              ))}
            </div>

            <div className="flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px] text-white">
                  Ou saisissez votre montant ici (⩾100)
                </div>
                <div className="font-albert-sans text-[15px] text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px] pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder="Montant"
                  value={montant}
                  onChange={handleChangeMontant}
                  min="100"
                  required
                />
              </div>
              <div className="text-red-700 px-6 font-albert text-sm">
                La valeur doit être supérieure ou égale à 100
              </div>
            </div>
          </div>

          {visible && (
            <div className="flex-col mt-5">
              <div className="bg-gris text-white flex flex-row h-10 py-8 rounded-xl border justify-center items-center border-[#333333] opacity-100 mx-5 mt-1">
                <div className="text-white flex items-center justify-center basis-1/5">
                  <FontAwesomeIcon icon={faKeyboard} className="px-3" />
                </div>

                <div className="text-white flex flex-col items-center justify-center basis-3/5">
                  <div> Requête MoMo</div>
                  <div>*880*41*589170*{montant}#</div>
                </div>

                <div className="basis-2/5 pl-2">
                  <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
                    <button
                      type="button"
                      className="text-white border border-bleu rounded-xl px-2 bg-bleu py-1"
                    >
                      copier
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          )}

          {visible && (
            <div className="flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px] text-white">
                  ID de la transaction Mobile Money
                </div>
                <div className="font-albert-sans text-[15px] text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px] pl-2 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
                  type="number"
                  pattern="\d*"
                  placeholder="Entrez les 10 chiffres"
                  value={transactionId}
                  inputMode="numeric"
                  onChange={handleChangeTransactionId}
                  maxLength={10} // Optionnel pour assurer que vous ne pouvez pas saisir plus de 10 caractères
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10); // Limite à 10 chiffres
                    }
                  }}
                  required
                />
              </div>
            </div>
          )}

          <div className="flex flex-col mt-5 mb-6 pb-10">
            <div className="flex flex-row justify-between mx-2">
              <button
                className="bg-bleu w-[346px] pr-[20px] h-12 flex flex-row justify-center items-center text-white rounded-2xl border border-[#333333]  mx-5 mt-5"
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-8 h-8 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}

                <span className="text-white">
                  {loading ? "Enregistrement..." : "Efectuer le paiement"}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="bg-black min-h-screen hidden flex-col pt-5 md:flex px-56 ">
        <div className="text-white font-albert px-6 mt-2 text-[18px] mx-72">
          Dépot
        </div>

        <div className="text-white font-albert px-6 mt-8 text-[16px] mx-72 ">
          Remplissez soigneusement le formulaire avant de soumettre.
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col mt-5 mx-72">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px] text-white">
              Saisissez l'ID du compte 1xBet
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              className="w-[307px] h-[47px] pl-1 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
              type="number"
              placeholder="ID 1xbet"
              //   value={userInfos.idCompte1xbet}
              value={inputValue}
              onChange={handleChange}
              required
            />
          </div>

          <div className="text-white font-mono text-[12px] px-6">
            Vous pouvez changer l'ID 1xbet par défaut dans les paramètres.
          </div>

          <div className="flex flex-col mt-8">
            <div className="flex flex-row justify-between mx-7">
              <div className="font-albert-sans text-[15px] text-white">
                Choisissez le montant à recharger
              </div>
            </div>

            <div className="flex space-x-4 mb-4 mx-9">
              {updatedAmounts.map((amount, index) => (
                <div
                  key={amount}
                  className={`w-16 h-8 flex items-center  justify-center border-2 border-[#272728FF] mx-1 mt-5 rounded-[8px] ${colors[index]} cursor-pointer transition duration-300`}
                  onClick={() => handleClick(index)}
                >
                  <span className="text-lg text-white font-semibold">
                    {amount}
                  </span>
                </div>
              ))}
            </div>

            <div className="flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px] text-white">
                  Ou saisissez votre montant ici (⩾100)
                </div>
                <div className="font-albert-sans text-[15px] text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px] pl-1 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
                  type="number"
                  placeholder="Montant"
                  pattern="\d*"
                  value={montant}
                  onChange={handleChangeMontant}
                  min="100"
                  required
                />
              </div>
              <div className="text-red-700 px-6 font-albert text-sm">
                La valeur doit être supérieure ou égale à 100
              </div>
            </div>
          </div>

          {visible && (
            <div className="flex-col mt-5">
              <div className="bg-gris text-white flex flex-row h-10 py-8 rounded-xl border justify-center items-center border-[#333333] opacity-100 mx-5 mt-1">
                <div className="text-white flex items-center justify-center basis-1/5">
                  <FontAwesomeIcon icon={faKeyboard} className="px-3" />
                </div>

                <div className="text-white flex flex-col items-center justify-center basis-3/5">
                  <div> Requête MoMo</div>
                  <div>*880*41*589170*{montant}#</div>
                </div>

                <div className="basis-2/5 pl-2">
                  <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
                    <button
                      type="button"
                      className="text-white border border-bleu rounded-xl px-2 bg-bleu py-1"
                    >
                      copier
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          )}

          {visible && (
            <div className="flex flex-col mt-5">
              <div className="flex flex-row justify-between mx-7">
                <div className="font-albert-sans text-[15px] text-white">
                  ID de la transaction Mobile Money
                </div>
                <div className="font-albert-sans text-[15px] text-white">
                  requis
                </div>
              </div>

              <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <input
                  className="w-[307px] h-[47px] pl-1 pr-[20px] font-albert-sans text-[18px] leading-[28px] bg-transparent text-white outline-none"
                  type="number"
                  pattern="\d*"
                  placeholder="Entrez les 10 chiffres"
                  value={transactionId}
                  inputMode="numeric"
                  onChange={handleChangeTransactionId}
                  maxLength={10} // Optionnel pour assurer que vous ne pouvez pas saisir plus de 10 caractères
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10); // Limite à 10 chiffres
                    }
                  }}
                  required
                />
              </div>
            </div>
          )}
          <div className="flex flex-col mt-5 mb-6 pb-10">
            <div className="flex flex-row justify-between mx-5  items-center">
              <button
                className="bg-bleu w-full pr-[20px] h-12 flex flex-row justify-center items-center text-white rounded-2xl border border-[#333333]  mt-5"
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-8 h-8 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}

                <span className="text-white">
                  {loading ? "Enregistrement..." : "Efectuer le paiement"}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
