import React, { useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";
import InfosContext from "../../contexts/InfosAppProvider";

import Swal from "sweetalert2";

import { CopyToClipboard } from "react-copy-to-clipboard";
//import { ToastContainer, toast } from "react-toastify";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import du CSS

import updateTransaction from "./updateTransaction";
//import { Link, useNavigate } from "react-router-dom";

//import { InfosAppProvider } from ".../contexts/InfosAppProvider";

import { messagea } from "./alert/message";

const AccepterRetrait = () => {
  //const { id } = useParams();
  const navigate = useNavigate(); // Utilisation de useNavigate

  const { id, idTran } = useParams();

  //const [textToCopy, setTextToCopy] = useState("Voici le texte à copier !");

  const notifySuccess = () => {
    toast.success("code copié", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000, // 3 secondes avant de disparaître
    });
  };

  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple
  const [loadingd, setLoadingd] = useState(false); // Etat pour indiquer si le chargement est en cours

  const transaction = {
    idCompte1xbet: "111222",
    idTransaction: "-O96NnLdcXJb1vV9v_On",
    idTransactionMobile: "5555888889",
    idUser: "5",
    montant: "4000",
    moyenPaiement: "MTN",
    statut: "en cours",
    type: "depot",
  };

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    listeUser,
    listeCaisses,
    token,
  } = useContext(InfosContext);

  const infos = Object.values(transactions).find(
    (transaction) => transaction.idTransaction == idTran
  );

  const [textToCopy, setTextToCopy] = useState(`${infos.idCompte1xbet}`);
  // alert(infos.idUser);

  const user = Object.values(listeUser).find(
    (transaction) => transaction.id == infos.idUser
  );

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(transaction.idCompte1xbet);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Réinitialisation après 2 secondes
  };

  const handleValidate = async () => {
    setLoading(true);

    const re = await updateTransaction(token, infos.idUser, idTran, "terminé");
    console.log("Transaction validée");

    const result = await messagea("retrait validé");
    setLoading(false);
    // Act based on the user's response
    if (result.isConfirmed) {
      //alert("hi");

      navigate("/agents/");
    }
  };

  const handleReject = async () => {
    setLoadingd(true);

    const re = await updateTransaction(token, infos.idUser, idTran, "echoué");
    console.log("Transaction validée");

    const result = await messagea("retrait annulé");
    setLoadingd(false);
    // Act based on the user's response
    if (result.isConfirmed) {
      //alert("hi");
      navigate("/agents/");
    }
  };

  const handleBack = () => {
    window.history.back(); // Retour à la page précédente
  };

  return (
    <>
      {" "}
      <ToastContainer />
      <div className="min-h-screen flex justify-center bg-gradient-to-br from-gray-900 to-black">
        <div className="max-w-lg w-full bg-[#1C1C1CFF] text-white px-8 py-2 rounded-xl shadow-2xl space-y-6 border border-gray-700">
          {/* Bouton Back */}
          <button
            onClick={handleBack}
            className="mb-4 py-2 px-4 bg-gray-800 hover:bg-gray-700 text-white font-bold rounded-lg shadow-md"
          >
            ← Retour
          </button>

          <h2 className="text-xl font-extrabold text-center tracking-wide text-gray-200">
            Détails de la Transaction
          </h2>

          <div className="space-y-4 text-gray-300">
            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">ID Compte 1xbet:</span>
              <span>{infos.idCompte1xbet}</span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">ID Transaction Mobile:</span>
              <span>{idTran}</span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">Numéro WhatsApp:</span>
              <span>{user.whatsapp}</span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">Montant:</span>
              <span>{infos.montant} FCFA</span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">Numéro MoMo:</span>
              <span>{infos.numeroRetraitMomo}</span>
            </div>

            <div className="flex justify-between items-center border-b border-gray-600 pb-2">
              <span className="font-semibold">Nom MoMo</span>
              <span>{infos.nomMtnMomo}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Type:</span>
              <span>{infos.type}</span>
            </div>
          </div>

          {/* Bouton de copie */}
          <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
            <button
              className={`w-full py-3 mt-4 rounded-lg text-white font-semibold shadow-md transition-all duration-300 ${
                copied
                  ? "bg-emerald-500 hover:bg-emerald-400"
                  : "bg-blue-500 hover:bg-blue-400"
              }`}
            >
              {copied ? "ID Copié !" : "Copier l'ID Compte 1xbet"}
            </button>
          </CopyToClipboard>

          <div className="flex justify-between space-x-4">
            <button
              onClick={handleValidate}
              className="w-1/2 flex flex-row justify-center items-center bg-gradient-to-r from-green-500 to-green-600 hover:from-green-400 hover:to-green-500 rounded-lg text-white font-bold shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
            >
              {loading ? (
                // Barre de progression circulaire en rotation
                <div className="w-4 h-4 mr-2 animate-spin">
                  <CircularProgressbar
                    value={progress}
                    styles={buildStyles({
                      pathColor: "white",
                      trailColor: "transparent",
                      strokeLinecap: "round",
                    })}
                  />
                </div>
              ) : (
                ""
              )}

              <span className="text-white">{loading ? "" : "Valider"}</span>
            </button>
            <button
              onClick={handleReject}
              className="w-1/2 flex flex-row justify-center items-center py-2 bg-gradient-to-r from-red-500 to-red-600 hover:from-red-400 hover:to-red-500 rounded-lg text-white font-bold shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
            >
              {loadingd ? (
                // Barre de progression circulaire en rotation
                <div className="w-4 h-4 mr-2 animate-spin">
                  <CircularProgressbar
                    value={progress}
                    styles={buildStyles({
                      pathColor: "white",
                      trailColor: "transparent",
                      strokeLinecap: "round",
                    })}
                  />
                </div>
              ) : (
                ""
              )}

              <span className="text-white">{loadingd ? "" : "Rejeter"}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccepterRetrait;
