// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import getUserInfos from "../requtes/clients/getUserInfos";

//import AuthContext from "./AuthContext";
import Splash from "./Splash";
import { database, ref, onValue, set, get } from "./FirebaseConfig";

const InfosContext = createContext();

export function InfosAppProvider({ children }) {
  // const { isSplashVisible, setSplashVisible } = useContext(AuthContext);

  const idUserv = localStorage.getItem("idUser");

  const navigate = useNavigate(); // Utilisation de useNavigate

  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = useState(false);

  const [listeUser, setListeUser] = useState(null);

  const [listeCaisses, setListeCaisses] = useState(null);
  const [update, setUpdate] = useState(0);
  const [updatek, setUpdatek] = useState(0);

  const [allRetraitAffiliations, setAllRetraitAffiliations] = useState(null);

  const [selectedStatuses, setSelectedStatuses] = useState([
    "en cours",
    "réussi",
    "échoué",
  ]); // Tous les statuts sont sélectionnés par défaut

  // Fonction pour retirer un statut
  const removeStatus = (statusToRemove) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.filter((status) => status !== statusToRemove)
    );
  };

  // Fonction pour ajouter un statut
  const addStatus = (newStatus) => {
    setSelectedStatuses((prevStatuses) => {
      if (!prevStatuses.includes(newStatus)) {
        return [...prevStatuses, newStatus];
      }
      return prevStatuses; // Ne rien faire si le statut est déjà présent
    });
  };

  const [userInfos, setUserInfos] = useState(null);
  const [infosAdmin, setInfosAdmin] = useState(null);
  const [isSplashVisible, setSplashVisible] = useState(true);

  const [transactions, setTransactions] = useState(null);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  //const [update, setUpatde] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    const isAuthenticatedlocal = localStorage.getItem("isAuthenticated");
    const tokenlocal = localStorage.getItem("token");

    //alert("idUser est " + idUser);

    // S'assurer que localStorage a bien la bonne valeur
    // localStorage.setItem("isAuthenticated", "1");

    //console.log("Valeur récupérée depuis localStorage:", isAuthenticatedlocal);

    // Mettre à jour l'état correctement si isAuthenticatedlocal est "1"
    if (isAuthenticatedlocal == "1") {
      setIsAuthenticated(true); // Utiliser un booléen pour plus de clarté
      setToken(tokenlocal);
      setId(idUser);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // useEffect dépendant de isAuthenticated
  useEffect(() => {
    if (isAuthenticated !== null) {
      // alert("État de isAuthenticated: " + isAuthenticated);

      //   navigate("/signup");

      if (isAuthenticated) {
        // Appeler une fonction pour récupérer les infos de l'utilisateur
        // alert("id est" + id + "token" + token);
        getUserInfos(id, token)
          .then((response) => {
            // alert("reponse est " + response);
            if (!response.error) {
              const userInfosString = JSON.stringify(
                response.infos.transactions
              );
              //  alert("reponse est " + userInfosString);
              const role = response.infos.userInfos.role;

              if (role == "client") {
                setInfosAdmin(response.infos.infosAdmin);
                setUserInfos(response.infos.userInfos);

                //  setTransactions(response.infos.transactions);
                setListeUser(response.infos.listeUser);

                let transformedData = response.infos.transactions;

                let reversedTransactions = {};
                let keys = Object.keys(transformedData).reverse();

                keys.forEach((key) => {
                  reversedTransactions[key] = transformedData[key];
                });

                setTransactions(reversedTransactions);

                /*
                alert(
                  "Transactions  agent " +
                    Object.keys(response.infos.transactions).length
                );

*/
                /// navigate("/clients/espace/");

                // setSplashVisible(false);
                /*const timer = setTimeout(() => {
                  setSplashVisible(false); // Masquer le splash après 3 secondes
                }, 0000);
                */
                setSplashVisible(false);

                // Nettoyage du timer quand le composant est démonté
                //  return () => clearTimeout(timer);
              }

              if (role == "agent") {
                // navigate("/agent/espace");

                setUserInfos(response.infos.userInfos);

                setListeUser(response.infos.listeUser);

                let transformedData = {};

                Object.keys(response.infos.transactions).forEach((idCaisse) => {
                  response.infos.transactions[idCaisse].forEach(
                    (transaction) => {
                      const idTransaction = transaction.idTransaction;
                      transformedData[idTransaction] = { ...transaction };
                    }
                  );
                });

                let reversedTransactions = {};
                let keys = Object.keys(transformedData).reverse();

                keys.forEach((key) => {
                  reversedTransactions[key] = transformedData[key];
                });

                setTransactions(reversedTransactions);
                /*
                alert(
                  "Transactions  agent " + Object.keys(transformedData).length
                );
                */
                //setListeUser(response.infos.listeUser);
                // setSplashVisible(false);

                const timer = setTimeout(() => {
                  setSplashVisible(false); // Masquer le splash après 3 secondes
                }, 3000);

                // Nettoyage du timer quand le composant est démonté
                return () => clearTimeout(timer);
              }
              if (role == "admin") {
                setInfosAdmin(response.infos.infosAdmin);
                setUserInfos(response.infos.userInfos);

                setTransactions(response.infos.transactions);

                setListeUser(response.infos.listeUser);

                setListeCaisses(response.infos.listeCaisses);

                setAllRetraitAffiliations(
                  response.infos.allRetraitAffiliations
                );

                //navigate("/admin/");
                //

                // setSplashVisible(false);
                const timer = setTimeout(() => {
                  setSplashVisible(false); // Masquer le splash après 3 secondes
                }, 3000);

                // Nettoyage du timer quand le composant est démonté
                return () => clearTimeout(timer);

                //  const [listeUser, setListeUser] = useState(null);

                //   const [listeCaisses, setListeCaisses] = useState(null);

                //   const [allRetraitAffiliations, setAllRetraitAffiliations] =
                //  useState(null);

                // navigate("/clients/espace");
                // navigate("/admin/espace");
              }

              /* setInfosAdmin(response.infos.infosAdmin);
              setUserInfos(response.infos.userInfos);
              setTransactions(response.infos.transactions);*/
              //setSplashVisible(false);
            } else {
              alert("erreur" + 55555555555);
            }
          })
          .catch((error) => {
            setSplashVisible(false);
            alert("erreur" + error);

            ///  alert("Erreur : " + error.message || error);

            //  alert("Erreur: " + error.message); // Affiche le message d'erreur principal
            console.error(
              "Erreur lors de la récupération des informations utilisateur:",
              error
            );
          });
      } else {
        //alert("hiii non ");
        //  navigate("/signup");
        setSplashVisible(false);
      }
    }
  }, [isAuthenticated, id, token, update, updatek]);

  const fetchUserInfos = (id) => {
    getUserInfos(id, token)
      .then((response) => {
        // alert("reponse est " + response);
        if (!response.error) {
          const userInfosString = JSON.stringify(response.infos.transactions);
          //  alert("reponse est " + userInfosString);
          const role = response.infos.userInfos.role;

          if (role == "client") {
            setInfosAdmin(response.infos.infosAdmin);
            setUserInfos(response.infos.userInfos);

            //  setTransactions(response.infos.transactions);
            setListeUser(response.infos.listeUser);

            let transformedData = response.infos.transactions;

            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();

            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });

            setTransactions(reversedTransactions);

            /*
                alert(
                  "Transactions  agent " +
                    Object.keys(response.infos.transactions).length
                );

*/
            /// navigate("/clients/espace/");

            // setSplashVisible(false);
            /*const timer = setTimeout(() => {
                  setSplashVisible(false); // Masquer le splash après 3 secondes
                }, 0000);
                */
            setSplashVisible(false);

            // Nettoyage du timer quand le composant est démonté
            //  return () => clearTimeout(timer);
          }

          if (role == "agent") {
            // navigate("/agent/espace");

            setUserInfos(response.infos.userInfos);

            setListeUser(response.infos.listeUser);

            let transformedData = {};

            Object.keys(response.infos.transactions).forEach((idCaisse) => {
              response.infos.transactions[idCaisse].forEach((transaction) => {
                const idTransaction = transaction.idTransaction;
                transformedData[idTransaction] = { ...transaction };
              });
            });

            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();

            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });

            setTransactions(reversedTransactions);
            /*
                alert(
                  "Transactions  agent " + Object.keys(transformedData).length
                );
                */
            //setListeUser(response.infos.listeUser);
            // setSplashVisible(false);

            const timer = setTimeout(() => {
              setSplashVisible(false); // Masquer le splash après 3 secondes
            }, 3000);

            // Nettoyage du timer quand le composant est démonté
            return () => clearTimeout(timer);
          }
          if (role == "admin") {
            setInfosAdmin(response.infos.infosAdmin);
            setUserInfos(response.infos.userInfos);

            setTransactions(response.infos.transactions);

            setListeUser(response.infos.listeUser);

            setListeCaisses(response.infos.listeCaisses);

            setAllRetraitAffiliations(response.infos.allRetraitAffiliations);

            //navigate("/admin/");
            //

            // setSplashVisible(false);
            const timer = setTimeout(() => {
              setSplashVisible(false); // Masquer le splash après 3 secondes
            }, 3000);

            // Nettoyage du timer quand le composant est démonté
            return () => clearTimeout(timer);

            //  const [listeUser, setListeUser] = useState(null);

            //   const [listeCaisses, setListeCaisses] = useState(null);

            //   const [allRetraitAffiliations, setAllRetraitAffiliations] =
            //  useState(null);

            // navigate("/clients/espace");
            // navigate("/admin/espace");
          }

          /* setInfosAdmin(response.infos.infosAdmin);
              setUserInfos(response.infos.userInfos);
              setTransactions(response.infos.transactions);*/
          //setSplashVisible(false);
        } else {
          alert("erreur" + 55555555555);
        }
      })
      .catch((error) => {
        setSplashVisible(false);
        alert("erreur" + error);

        ///  alert("Erreur : " + error.message || error);

        //  alert("Erreur: " + error.message); // Affiche le message d'erreur principal
        console.error(
          "Erreur lors de la récupération des informations utilisateur:",
          error
        );
      });
  };

  useEffect(() => {
    // Référence à la partie de la base de données que vous voulez surveiller

    if (isAuthenticated) {
      const dataRef = ref(database, `infos-admin`);

      // Écouter les changements en temps réel
      const unsubscribe = onValue(dataRef, (snapshot) => {
        //    alert("ya changement");
        // setData(snapshot.val());
        const timestampUnix = Math.floor(Date.now() / 1000);
        // alert(timestampUnix);
        //setUpdate(timestampUnix);
        setUpdatek(timestampUnix);

        // setUpdate(update + 1);
        //  fetchUserInfos(idUser);
        console.log("Value changed:", snapshot.val());
      });

      // Nettoyer l'écouteur lors du démontage du composant
      return () => unsubscribe();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Référence à la partie de la base de données que vous voulez surveiller

    if (isAuthenticated) {
      const dataRef = ref(database, `users`);

      // Écouter les changements en temps réel
      const unsubscribe = onValue(dataRef, (snapshot) => {
        //    alert("ya changement");
        // setData(snapshot.val());
        const timestampUnix = Math.floor(Date.now() / 1000);
        // alert(timestampUnix);
        //setUpdate(timestampUnix);
        setUpdatek(timestampUnix);

        //  setUpdate(update + 1);
        //  fetchUserInfos(idUser);
        console.log("Value changed:", snapshot.val());
      });

      // Nettoyer l'écouteur lors du démontage du composant
      return () => unsubscribe();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Référence à la partie de la base de données que vous voulez surveiller

    if (isAuthenticated) {
      const dataRef = ref(database, `transactions`);

      // Écouter les changements en temps réel
      const unsubscribe = onValue(dataRef, (snapshot) => {
        //    alert("ya changement");
        // setData(snapshot.val());
        const timestampUnix = Math.floor(Date.now() / 1000);
        // alert(timestampUnix);
        //setUpdate(timestampUnix);
        setUpdatek(timestampUnix);

        //  setUpdate(update + 1);
        //  fetchUserInfos(idUser);
        console.log("Value changed:", snapshot.val());
      });

      // Nettoyer l'écouteur lors du démontage du composant
      return () => unsubscribe();
    }
  }, [isAuthenticated]);

  return (
    <InfosContext.Provider
      value={{
        id,
        setId,
        token,
        setToken,
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        isSplashVisible,
        setSplashVisible,
        selectedStatuses,
        removeStatus,
        addStatus,
        open,
        setOpen,
        searchTerm,
        setSearchTerm,

        searchTerm,
        setSearchTerm,
        listeUser,
        setListeUser,
        listeCaisses,
        setListeCaisses,
        allRetraitAffiliations,
        setAllRetraitAffiliations,
        update,
        setUpdate,
        isAuthenticated,
        setIsAuthenticated,
        fetchUserInfos,
        updatek,
        setUpdatek,
      }}
    >
      {isSplashVisible ? <Splash /> : children}
    </InfosContext.Provider>
  );
}

export default InfosContext;
