import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import {
  faChevronDown,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

//import logo from "../../resources/image/tmm.webp";

import checks from "../ressources/images/check.png";

import logo from "../ressources/images/logo.png";

import AuthContext from "../contexts/AuthContext";

import { message } from "./alert/message";

import { existelogin } from "./alert/existelogin";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [inputFocused, setInputFocused] = useState(false);
  const { email, setEmail, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext);
  //alert(email);
  const [mail, setMail] = useState(null);
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple
  const baseURL = "https://back.payforbet.com/api/";

  const handleChange = (event) => {
    setMail(event.target.value); // Met à jour l'état avec la nouvelle valeur de l'input
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //alert(mail);
    // Faire la requête API avec les données du formulaire
    setLoading(true);
    handleLogin();
  };

  const handleLogin = async () => {
    try {
      const res = await axios.get(`${baseURL}/login`, {
        params: { email: mail },
      });

      //alert(res);
      //alert(`Réponse du serveur :\n${JSON.stringify(res.data, null, 2)}`);
      localStorage.setItem("userEmail", mail);

      // Afficher chaque élément dans la console
      console.log("Error:", res.data.error);
      console.log("Message:", res.data.message);
      console.log("idUser:", res.data.idUser);

      localStorage.setItem("idUser", res.data.idUser);
      if (res.data.error) {
        setLoading(false);
        const result = await existelogin();
        if (result.isConfirmed) {
          // Logique pour "Continuer"
          console.log("Continuer action");
          //  navigate("/signup");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Logique pour "Se connecter"
          console.log("Se connecter action");
          navigate("/signup");
        }
      } else {
        setLoading(false);
        navigate("/authentification");
        //const result = existelogin();
      }

      //  setResponseData(res.data); // Stocker la réponse ici
    } catch (error) {
      setLoading(false);
      //   const result = existelogin();

      const result = await existelogin();

      console.error("Erreur lors de la requête:", error);
    }
  };

  return (
    <div
      className={`bg-black min-h-screen flex flex-col pt-36 ${
        inputFocused ? "keyboard-open" : ""
      }`}
    >
      <form className="md:hidden" onSubmit={handleSubmit}>
        <div className="h-24 w-24 mx-28 rounded-3xl flex justify-center items-center">
          {/*
 <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "white", fontSize: "48px" }}
        />
        */}

          <img
            src={logo}
            alt="Première image"
            loading="lazy"
            className="w-2/3 h-96 object-contain"
          />
        </div>

        <div className="text-white mx-12 mt-5 flex justify-center items-center font-albert">
          Entrez votre adresse e-mail pour recevoir votre code de connexion
        </div>

        <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-5">
          <span className="inset-y-0 left-0 flex items-center pl-4 text-gray-500">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>

          <input
            name="xbetId" // Ajouter un attribut 'name' pour lier à formData
            onChange={handleChange}
            className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            required
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <button
          type="submit"
          className="bg-bleu w-[314px] pr-[20px] h-14 flex flex-row justify-center items-center text-white rounded-xl border border-[#333333]  mx-5 mt-5"
        >
          {loading ? (
            // Barre de progression circulaire en rotation
            <div className="w-8 h-8 mr-2 animate-spin">
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "white",
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          )}

          <span className="text-white">
            {loading ? "Enregistrement..." : "Envoyer le code"}
          </span>
        </button>
      </form>

      <form
        className="hidden md:flex flex-col w-1/3    mx-auto items-center justify-center   rounded-xl border border-[#333333] py-10"
        onSubmit={handleSubmit}
      >
        <div className="bg-bleu h-24 w-24 mx-28 rounded-3xl flex justify-center items-center">
          {/*
 <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "white", fontSize: "48px" }}
        />
        */}

          <img
            src={checks}
            alt="Première image"
            loading="lazy"
            className="w-2/3 h-96 object-contain"
          />
        </div>

        <div className="text-white px-28 mt-5 flex justify-center items-center font-albert">
          Entrez votre adresse e-mail pour recevoir votre code de connexion
        </div>

        <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-5">
          <span className="inset-y-0 left-0 flex items-center pl-4 text-gray-500">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>

          <input
            name="xbetId" // Ajouter un attribut 'name' pour lier à formData
            onChange={handleChange}
            className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            required
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <button
          type="submit"
          className="bg-bleu w-[336px] pr-[20px] h-14 flex flex-row justify-center items-center text-white rounded-xl border border-[#333333]  mx-5 mt-5"
        >
          {loading ? (
            // Barre de progression circulaire en rotation
            <div className="w-8 h-8 mr-2 animate-spin">
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "white",
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          )}

          <span className="text-white">
            {loading ? "Enregistrement..." : "Envoyer le code"}
          </span>
        </button>
      </form>
    </div>
  );
};

export default Login;
