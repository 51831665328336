import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ImEye } from "react-icons/im";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
//import { TransactionsContext } from '../../contexts/TransactionsContext';

import InfosContext from "../../contexts/InfosAppProvider";

const ListClients = () => {
   // const { listeUser, loading } = useContext(TransactionsContext);
    const [searchTerm, setSearchTerm] = useState('');  // État pour la recherche
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10);
  
    // Handler pour la saisie dans le champ de recherche
    const searchTermHandler = (event) => {
        setSearchTerm(event.target.value);
    };

       const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
     listeUser,
     token
   
  } = useContext(InfosContext);

    // Filtrer les utilisateurs pour obtenir uniquement ceux avec le rôle "client"
    const clientsArray = Object.values(listeUser).filter(user => user.role === "client");

    // Filtrer les clients selon la recherche
    const filteredClients = clientsArray.filter(client => 
        client.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination
    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
    const totalClients = filteredClients.length;

    // Changer de page vers la prochaine
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    // Changer de page vers la précédente
    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Handler pour changer le nombre de clients par page
    const handleClientsPerPageChange = (event) => {
        setClientsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

   

    // Si les données sont en cours de chargement, afficher un message de chargement
    //if (loading) return <p>Loading Clients...</p>;

    return (
        <div className='m-5'>
            <h2 className='text-3xl text-blue my-5'>Clients</h2>
            <div className='flex items-center justify-between bg-white rounded py-4 px-2'>
                <div>
                    <form onSubmit={event => event.preventDefault()}>
                        <div className='sm:min-w-72 min-w-full p-2 border border-gray rounded flex items-center gap-2 bg-white dark:bg-[#171923]'>
                            <input
                                type="text"
                                placeholder="Rechercher"
                                required
                                onChange={searchTermHandler}
                                value={searchTerm}
                                className="border-none outline-none bg-transparent"
                            />
                        </div>
                    </form>
                </div>
            </div>

            <div className='bg-white rounded mt-4'>
                <div className='flex items-center justify-between mx-5 border-b border-blue'>
                    <div className="flex gap-4 items-center py-2">
                        <div className="flex justify-between items-center">
                            <select
                                id="clientsPerPage"
                                value={clientsPerPage}
                                onChange={handleClientsPerPageChange}
                                className="border border-gray rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                        >
                            <FaChevronLeft className={`text-blue ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                        <div className="text-center text-xs">
                            <span>{indexOfFirstClient + 1} - {Math.min(indexOfLastClient, totalClients)} sur {totalClients}</span>
                        </div>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastClient >= totalClients}
                        >
                            <FaChevronRight className={`text-blue ${indexOfLastClient >= totalClients ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                    </div>
                </div>
               
                {/* Tableau des clients */}
                <div className="bg-white text-dark">
                    <div className="overflow-y-auto overflow-x-auto max-h-[calc(100vh-150px)] bg-white dark:bg-[#171923]">
                        <table className="min-w-full bg-white">
                            <thead className='bg-white'>
                                <tr className='text-center font-medium text-sm border-b-[1px] border-gray bg-lightGray bg-opacity-0 dark:bg-opacity-10'>
                                    <th className="p-4">#</th>
                                    <th className="p-4">Nom</th>
                                    <th className="p-4">Email</th>
                                    <th className="p-4">WhatsApp</th>
                                    <th className="p-4">Statut</th>
                                    <th className="p-4">Solde total</th>
                                    <th className="p-4">Détails clients</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Afficher un message si aucun client n'est trouvé */}
                                {totalClients === 0 ? (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4">Aucun client à afficher</td>
                                    </tr>
                                ) : (
                                    currentClients.map((client, index) => (
                                        <tr key={index} className="border-b-[1px] border-gray">
                                            <td className="py-4 px-8">{indexOfFirstClient + index + 1}</td>
                                            <td className="py-4 px-8">{client.nom}</td>
                                            <td className="py-4 px-8">{client.email}</td>
                                            <td className="py-4 px-8">{client.whatsapp}</td>
                                            <td className='py-4 w-full text-center'>
                                                <span className={`p-2 rounded-xl ${client.statut === 'actif' ? 'bg-green-200' : 'bg-red-200'}`}>{client.statut}</span>
                                            </td>
                                            <td className="py-4 px-8">{client.solde}</td>
                                            <td className="py-4 px-8">
                                            <Link to={`/admin/clientDetails/${client.id}`} className='border border-blue flex flex-col items-center text-blue p-2 rounded-sm'>
                                                <ImEye />
                                                <span>Détails</span>
                                            </Link>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ListClients;
