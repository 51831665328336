import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const IndexVisiteur = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refValue = queryParams.get("ref");

  useEffect(() => {
    if (refValue) {
      localStorage.setItem("ref", refValue);
    }
  }, [refValue]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white px-4">
      <div className="flex-grow flex flex-col items-center justify-center">
        <div className="bg-gray-800 rounded-lg shadow-lg p-10 max-w-md w-full text-center">
          <h1 className="text-3xl font-bold mb-4 text-green-400 font-albert">
            Payforbet
          </h1>

          {/* Boutons de connexion et inscription disposés verticalement */}
          <div className="space-y-4">
            <Link
              to="/login"
              className="bg-bleu hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out block"
            >
              Connexion
            </Link>
            <Link
              to="/signup"
              className="bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out block"
            >
              Inscription
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexVisiteur;
