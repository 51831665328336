// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: "AIzaSyBK9rkPFTX1uE-Bu8yaInfbOKsTqHbN120",
  authDomain: "kappela-51c85.firebaseapp.com",
  databaseURL: "https://kappela-51c85-default-rtdb.firebaseio.com",
  projectId: "kappela-51c85",
  storageBucket: "kappela-51c85.appspot.com",
  messagingSenderId: "153098698401",
  appId: "1:153098698401:web:aee77632593979317a7ae0",
  measurementId: "G-S7W0K5BPKN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

*/
//const analytics = getAnalytics(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getDatabase,
  ref,
  set,
  push,
  get,
  onValue,
  update,
  child,
} from "firebase/database"; // Import Firebase Realtime Database functions

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDbwoB97-TtVCNICHmXMUAe1aGMfAbFyB0",
  authDomain: "payforbet-584b4.firebaseapp.com",
  databaseURL: "https://payforbet-584b4-default-rtdb.firebaseio.com",
  projectId: "payforbet-584b4",
  storageBucket: "payforbet-584b4.appspot.com",
  messagingSenderId: "591701975605",
  appId: "1:591701975605:web:329f6004b5378ce186077c",
  measurementId: "G-EMZPK7W36G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app); // Initialize Realtime Database

const storage = getStorage(app);

export { database, ref, set, push, get, onValue, update, storage, child };
