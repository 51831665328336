import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import React, { useState, useRef, useEffect } from "react";

export default function InfosRetrait({ onClose }) {
  return (
    <div className="bg-[#414151FF] min-h-96   ref={divRef} ">
      <div className="flex flex-row p-4">
        <div className="flex w-full space-x-2/3 justify-end items-end  text-white font-bold"></div>

        <div className="flex w-full space-x-2/3 justify-end items-end  text-white   ">
          {" "}
          <div
            onClick={() => {
              // alert("Vous avez cliqué pour fermer le filtre");
              console.log("Close button clicked");
              onClose(); // Appelle la fonction de fermeture
            }}
            className="flex items-center justify-center h-6 w-6 rounded-full bg-red-500 cursor-pointer"
          >
            <FontAwesomeIcon icon={faTimes} className="h-5 w-5 text-white" />
          </div>
        </div>
      </div>

      <div className="text-white ml-8"> A faire dans votre compte</div>

      <div className="flex flex-col ml-4 mt-4 font-albert">
        <div className="text-white">1 – Appuyer sur Retirer</div>
        <div className="text-white">
          2 – Choisissez le moyen de paiement 1XBET (Liquide/Espèces)
        </div>
        <div className="text-white">3 – Saisissez le Montant à Retirer</div>
        <div className="text-white">4 – Choisissez Ville : Cotonou</div>
        <div className="text-white">5 – Choisissez Rue : PayforB Auto Gaw</div>
        <div className="text-white">
          6 – Confirmer avec le code envoyé par 1XBET sur le numéro lié au
          compte puis valider.
        </div>
        <div className="text-white">
          7 – Patienter un instant que la transaction affiche Approuvée et
          appuyer sur Obtenir le Code. Une fois le code généré, copier dans le
          champ ci-après.
        </div>
      </div>
    </div>
  );
}
