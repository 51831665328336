import React from 'react';

const Button = ({ title, icon }) => {
  return (
    <button className='py-2 px-4 mb-1 flex items-center w-full rounded-xl gap-2 hover:bg-lightBlue transition-all duration-75 text-dark text-base'>
      {icon && <span>{icon}</span>} {/* Affiche l'icône si elle est présente */}
      <span>{title}</span>
    </button>
  );
}

export default Button;
