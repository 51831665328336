const NotificationWhatsApp = () => {
  return (
    <div className="max-w-xl mx-auto mt-10 p-6  rounded-lg shadow-md">
      <p className="text-white leading-relaxed">
        La principale raison, c’est que vous avez mal renseigné votre numéro
        WhatsApp. Il doit être au{" "}
        <span className="font-semibold">format international</span>
        sans espaces, exactement comme ceci :{" "}
        <span className="font-mono text-blue-600">22997979797</span>.
      </p>

      <p className="text-white mt-4 leading-relaxed">
        Si vous ne respectez pas cela, vous ne recevrez pas de notification
        quand vos demandes sont <span className="font-semibold">traitées</span>{" "}
        ou <span className="font-semibold">rejetées</span>.
      </p>

      <p className="text-white mt-4 leading-relaxed">
        Allez dans <span className="font-semibold">« Mon compte »</span> puis
        appuyez sur le bouton{" "}
        <span className="font-semibold">« Modifier le profil »</span> pour
        modifier votre numéro WhatsApp.
      </p>
    </div>
  );
};

export default NotificationWhatsApp;
