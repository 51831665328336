import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import VisiteursRoutes from "./routes/VisiteursRoutes";

import ClientsRoutes from "./routes/ClientsRoutes";

import AdminRoutes from "./routes/AdminRoutes"

import AgentsRoutes from  "./routes/AgentsRoutes"

import { InfosAppProvider } from "./contexts/InfosAppProvider";

import { ChangementProvider } from "./contexts/ChangementProvider";

import ProtectedRoute from './ProtectedRoute'; // Le composant que tu as créé

export default function Navigator() {
  return (
    <BrowserRouter>
    <InfosAppProvider >
      <ChangementProvider> 

      <Routes>
        {
          //<Route path="/" element={<Splash />} />
        }

        {/* Routes utilisateurs */}
        <Route path="/*" element={<VisiteursRoutes />} />
        {/* Routes protégées : accessibles seulement si l'utilisateur est authentifié */}
      <Route
        path="/clients/*"
        element={
          <ProtectedRoute>
            <ClientsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <AdminRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/agents/*"
        element={
          <ProtectedRoute>
            <AgentsRoutes />
          </ProtectedRoute>
        }
      />
      </Routes>
      </ChangementProvider>
      </InfosAppProvider>
    </BrowserRouter>
  );
}
