const Procedure = () => {
  return (
    <div className="max-w-2xl mx-auto mt-10 p-8 rounded-lg shadow-lg">
      <ul className="space-y-4 text-lg text-white list-decimal list-inside">
        <li>1 – Saisissez votre ID 1XBET</li>
        <li>2 – Saisissez le Montant à recharger</li>
        <li>3 – Choisissez le Moyen de paiement (MTN MoMo)</li>
        <li>
          4 – Saisissez l’ID de la transaction de l’envoie de l’argent (MTN
          MoMo)
        </li>
        <li>5 – Saisissez votre Téléphone WhatsApp (valide)</li>
        <li>6 – Saisissez votre E-mail (valide)</li>
        <li>7 – Appuyez sur le bouton Recharger</li>
        <li>8 – Et enfin Valider votre transaction.</li>
      </ul>
    </div>
  );
};

export default Procedure;
