import React, { useState, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Importation de useNavigate
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import de l'icône de transaction

import HomeIcon from "@mui/icons-material/Home"; // Icône de maison

import SecurityIcon from "@mui/icons-material/Security"; // Icône de sécurité
import SupportAgentIcon from "@mui/icons-material/SupportAgent"; // Icône d'assistance
import SettingsIcon from "@mui/icons-material/Settings"; // Icône de paramètres
import PersonIcon from "@mui/icons-material/Person"; // Icône pour un homme/utilisateur

//import AideIndex from "./aides/AideIndex";

//import IndexParametre from "./parametre/IndexParametre";

const BottomNavBarAgent = () => {
  const [value, setValue] = useState(0);

  const [vide, setVide] = useState(true);
  const navigate = useNavigate(); // Utilisation de useNavigate

  const handleNavigation = (newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/agents/"); // Accueil
        break;
      case 1:
        // navigate("/clients/menu-transaction"); // Favoris  AideIndex
        navigate("/agents/setting");

        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      value={value}
      className="bg-black text-white "
      onChange={(event, newValue) => handleNavigation(newValue)}
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#1C1C1CFF",
        border: "1px solid #272728FF", // Ajout de la bordure ici
        borderRadius: "1px", // Pour un effet arrondi (facultatif)
      }}
    >
      <BottomNavigationAction
        label="Accueil"
        icon={<HomeIcon />}
        sx={{
          color: "gray", // Couleur par défaut lorsque l'élément n'a pas le curseur
        }}
      />

      <BottomNavigationAction
        sx={{
          color: value === 0 ? "gray" : "gray",
        }}
        label="Paramètres"
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
};

export default BottomNavBarAgent;
