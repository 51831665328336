import React, { useState, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Importation de useNavigate
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import de l'icône de transaction

import HomeIcon from "@mui/icons-material/Home"; // Icône de maison

import SecurityIcon from "@mui/icons-material/Security"; // Icône de sécurité
import SupportAgentIcon from "@mui/icons-material/SupportAgent"; // Icône d'assistance
import SettingsIcon from "@mui/icons-material/Settings"; // Icône de paramètres
import PersonIcon from "@mui/icons-material/Person"; // Icône pour un homme/utilisateur

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import AideIndex from "./aides/AideIndex";

import IndexParametre from "./parametre/IndexParametre";

//import { ReactComponent as Coin } from "../animation/check.svg";

import check from "../ressources/images/check.png";

import logout from "../ressources/images/logout.png";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

//import { ReactComponent as Coin } from

const BottomNavBar = () => {
  const [value, setValue] = useState(0);

  const [vide, setVide] = useState(true);
  const navigate = useNavigate(); // Utilisation de useNavigate

  const handleNavigation = (newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/clients/espace"); // Accueil
        break;
      case 1:
        navigate("/clients/menu-transaction"); // Favoris  AideIndex
        break;
      case 2:
        navigate("/clients/aide"); // À proximité
        break;

      case 3:
        navigate("/clients/setting"); // À proximité
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="md:hidden">
        <BottomNavigation
          value={value}
          className="bg-black text-white  md:hidden"
          onChange={(event, newValue) => handleNavigation(newValue)}
          showLabels
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#1C1C1CFF",
            border: "4px solid #272728FF", // Ajout de la bordure ici
            borderRadius: "4px", // Pour un effet arrondi (facultatif)
          }}
        >
          <BottomNavigationAction
            label="Accueil"
            icon={<HomeIcon />}
            sx={{
              color: "gray", // Couleur par défaut lorsque l'élément n'a pas le curseur
            }}
          />
          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Transactions"
            icon={<SwapHorizIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Assistance"
            icon={<HelpOutlineIcon />}
          />

          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Paramètres"
            icon={<SettingsIcon />}
          />
        </BottomNavigation>
      </div>

      <div className="hidden md:flex bg-black fixed top-0 left-0 w-full z-50 ">
        <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] h-10 mt-8 items-center p-6 opacity-100 mx-auto space-x-7">
          <div className="bg-bleu flex rounded-xl flex-row justify-center items-center">
            <img
              src={check}
              alt="Première image"
              loading="lazy"
              className="w-8 h-8"
            />
          </div>

          <div
            onClick={() => navigate("/clients/espace")}
            className="text-white"
          >
            <button>Tableau de bord</button>
          </div>

          <div onClick={() => navigate("/clients/menu-transaction")}>
            <button
              type="submit"
              className="bg-bleu h-10 px-4 flex flex-row justify-center items-center text-white rounded-[10px] border border-[#333333]"
            >
              Transactions
            </button>
          </div>

          <div onClick={() => navigate("/clients/aide")} className="text-white">
            <button>Assistance</button>
          </div>

          <div
            onClick={() => navigate("/clients/setting")}
            className="text-white"
          >
            <button>Paramètres</button>
          </div>

          <div
            onClick={() => {
              localStorage.setItem("token", "");
              localStorage.setItem("isAuthenticated", 0);
              navigate("/login");
            }}
          >
            <button
              type="submit"
              className="bg-bleu px-4 h-10 flex flex-row justify-center items-center text-white rounded-[10px] border border-[#333333]"
            >
              <img
                src={logout}
                alt="Première image"
                loading="lazy"
                className="w-5 h-5"
              />
              <span className="ml-1">Se déconnecter</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNavBar;
