import axios from "axios";

const baseURL = "https://back.payforbet.com/api/"; // Replace with your actual base URL

// Function to send a login code to the specified email
export default async function sendCodeToEmail(email) {
  try {
    const res = await axios.get(`${baseURL}/login`, {
      params: { email: email },
    });

    // Process the response (if needed)
    if (res.data.success) {
      console.log("Code sent successfully to:", email);
      return res.data; // Return the response data (or do further processing)
    } else {
      console.log("Failed to send the code:", res.data.message);
      return null; // Or handle the failure as needed
    }
  } catch (error) {
    console.error("Error occurred while sending code:", error.message);
    return null; // Handle errors here (network issues, etc.)
  }
}

// Usage example:
