import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import TransactionCard from "../TransactionCard";
import InfosContext from "../../contexts/InfosAppProvider";

const PaginatedTransactions = ({ transactions, itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [filterType, setFilterType] = useState("all");
  //const [searchTerm, setSearchTerm] = useState("");
  const { selectedStatuses, searchTerm, setSearchTerm } =
    useContext(InfosContext);

  const truncateRef = (ref) =>
    ref.length > 8 ? ref.substring(0, 8) + "..." : ref;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Fonction pour vérifier si un terme de recherche correspond à un champ
  const includesSearchTerm = (value) => {
    if (typeof value === "number") {
      return value.toString().includes(searchTerm);
    }
    if (typeof value === "string") {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  };

  useEffect(() => {
    const filteredTransactions = Object.entries(transactions).filter(
      ([id, transaction]) => {
        if (!transaction) return false;

        const typeMatch =
          filterType === "all" || transaction.type === filterType;
        const statusMatch = selectedStatuses.includes(transaction.statut);

        const searchMatch =
          includesSearchTerm(transaction.idTransaction) ||
          includesSearchTerm(transaction.idTransactionMobile) ||
          includesSearchTerm(transaction.idCaisse) ||
          includesSearchTerm(transaction.idCompte1xbet) ||
          includesSearchTerm(transaction.montant) ||
          includesSearchTerm(transaction.moyenPaiement) ||
          includesSearchTerm(transaction.statut) ||
          includesSearchTerm(transaction.dateHeure) || // Vérifie également le timestamp
          (searchTerm &&
            new Date(transaction.dateHeure)
              .toLocaleString("fr-FR", { month: "long" })
              .includes(searchTerm.toLowerCase())) || // Recherche par mois
          includesSearchTerm(transaction.type); // Ajout de la recherche par type

        return typeMatch && statusMatch && searchMatch;
      }
    );

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredTransactions.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredTransactions.length / itemsPerPage));
  }, [
    itemOffset,
    itemsPerPage,
    transactions,
    filterType,
    selectedStatuses,
    searchTerm,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      Object.entries(transactions).filter(([id, transaction]) => {
        const typeMatch =
          filterType === "all" || transaction.type === filterType;
        const statusMatch = selectedStatuses.includes(transaction.statut);

        const searchMatch =
          includesSearchTerm(transaction.idTransaction) ||
          includesSearchTerm(transaction.idTransactionMobile) ||
          includesSearchTerm(transaction.idCaisse) ||
          includesSearchTerm(transaction.idCompte1xbet) ||
          includesSearchTerm(transaction.montant) ||
          includesSearchTerm(transaction.moyenPaiement) ||
          includesSearchTerm(transaction.statut) ||
          includesSearchTerm(transaction.dateHeure) || // Vérifie également le timestamp
          (searchTerm &&
            new Date(transaction.dateHeure)
              .toLocaleString("fr-FR", { month: "long" })
              .includes(searchTerm.toLowerCase())) || // Recherche par mois
          includesSearchTerm(transaction.type); // Ajout de la recherche par type

        return typeMatch && statusMatch && searchMatch;
      }).length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="transaction-list  md:mx-16">
        {currentItems.map(([id, transaction]) => (
          <TransactionCard
            key={id}
            icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
            title={`${transaction.type === "depot" ? "Dépôt" : "Retrait"} `}
            date={formatDate(transaction.dateHeure)}
            amount={`${transaction.montant.toLocaleString()} F CFA`}
            id={`id:${transaction.idCompte1xbet}`}
            bgColor={
              transaction.statut === "en cours"
                ? "bg-[#EFB034FF]"
                : transaction.type === "depot"
                ? "bg-[#28C281FF]"
                : "bg-[#AA1D22FF]"
            }
          />
        ))}
      </div>

      <ReactPaginate
        breakLabel={
          <span className="px-4 py-2 bg-bleu rounded h-full flex items-center">
            ...
          </span>
        }
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="flex justify-center space-x-2 mt-5 flex-wrap"
        pageClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
        activeClassName="bg-bleu text-white"
        previousClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
        nextClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
      />
    </>
  );
};

export default PaginatedTransactions;
