import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  FaMoneyBillWave, FaWallet, FaBox, FaExchangeAlt, FaRegSadCry, 
  FaArrowLeft, FaCheckCircle, FaTimesCircle 
} from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { AiOutlineSync } from 'react-icons/ai';
//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

const ClientDetails = () => {
  const { id } = useParams();  
  const [totalDepot, setTotalDepot] = useState(0);
  const [totalRetrait, setTotalRetrait] = useState(0);
  const [nombreTransactions, setNombreTransactions] = useState(0);
  const [transactionsEnCours, setTransactionsEnCours] = useState(0);
  const [transactionsTerminees, setTransactionsTerminees] = useState(0);
  const [transactionsEchoue, setTransactionsEchoue] = useState(0);
  const [transactionsAgent, setTransactionsAgent] = useState([]);

  const { 
    listeUser, transactions 
  } = useContext(InfosContext);



  // Trouver l'agent correspondant à l'ID de l'URL
  const agent = Object.values(listeUser).find(user => user.id === id);

useEffect(() => {
  console.log("transactions:", transactions);

  if (agent) {
    // Convertir l'objet transactions en tableau
    const transactionsArray = Object.values(transactions);
    
    // Filtrer les transactions de l'agent
    const agentTransactions = transactionsArray.filter(
      (transaction) => Number(transaction.idUser) === Number(agent.id)
    );
    setTransactionsAgent(agentTransactions);
    
    // Calculer le total des dépôts
    const totalD = agentTransactions
      .filter((t) => t.type === 'depot')
      .reduce((total, t) => total + (isNaN(Number(t.montant)) ? 0 : Number(t.montant)), 0);
    setTotalDepot(totalD);
    
    // Calculer le total des retraits
    const totalR = agentTransactions
      .filter((t) => t.type === 'retrait')
      .reduce((total, t) => total + (isNaN(Number(t.montant)) ? 0 : Number(t.montant)), 0);
    setTotalRetrait(totalR);

    // Calculer le nombre total de transactions
    setNombreTransactions(agentTransactions.length);

    // Transactions en cours
    const enCours = agentTransactions.filter((t) => t.statut === 'en cours').length;
    setTransactionsEnCours(enCours);

    // Transactions terminées
    const terminees = agentTransactions.filter((t) => t.statut === 'terminé').length;
    setTransactionsTerminees(terminees);

    // Transactions échouées
    const echouees = agentTransactions.filter((t) => t.statut === 'echoué').length;
    setTransactionsEchoue(echouees);
  }
}, [agent, transactions]); // Exécuter à chaque changement de `agent` ou `transactions`


  if (!agent) return <p>Agent non trouvé</p>;

  return (
    <div className="p-5">
      <h1 className="text-3xl font-bold text-gray-700 mb-8">
        Fiche de l'utilisateur: {agent.nom}
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <InfoCard 
          icon={<FaMoneyBillWave className="text-4xl mr-4" />} 
          title="Total Dépôt" value={`${totalDepot} F CFA`} 
          bgColor="bg-indigo-500" 
        />
        <InfoCard 
          icon={<FaWallet className="text-4xl mr-4" />} 
          title="Total Retrait" value={`${totalRetrait} F CFA`} 
          bgColor="bg-green-500" 
        />
        <InfoCard 
          icon={<MdError className="text-4xl mr-4" />} 
          title="Transactions Échouées" value={transactionsEchoue} 
          bgColor="bg-orange-500" 
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <InfoCard 
          icon={<FaExchangeAlt className="text-4xl mr-4" />} 
          title="Nombre de Transactions" value={nombreTransactions} 
          bgColor="bg-purple-500" 
        />
        <InfoCard 
          icon={<FaExchangeAlt className="text-4xl mr-4" />} 
          title="Transactions en cours" value={transactionsEnCours} 
          bgColor="bg-yellow-500" 
        />
        <InfoCard 
          icon={<FaExchangeAlt className="text-4xl mr-4" />} 
          title="Transactions terminées" value={transactionsTerminees} 
          bgColor="bg-teal-500" 
        />
      </div>

      <TransactionTable transactions={transactionsAgent} />
    </div>
  );
};

// Component for Info Cards
const InfoCard = ({ icon, title, value, bgColor }) => (
  <div className={`${bgColor} text-white p-6 rounded-lg shadow-lg flex items-center`}>
    {icon}
    <div>
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-2xl mt-2">{value}</p>
    </div>
  </div>
);

// Component for Transaction Table
const TransactionTable = ({ transactions }) => (
  <div className="bg-white p-8 rounded-xl shadow-lg max-w-6xl mx-auto">
    <div className="flex items-center justify-between mb-6">
      <h2 className="text-3xl font-extrabold text-gray-800">
        📋 Liste des Transactions de l'utilisateur
      </h2>
      <Link to="/listAgents">
        <button className="flex items-center gap-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition">
          <FaArrowLeft /> Retour
        </button>
      </Link>
    </div>

    {transactions.length > 0 ? (
      <table className="min-w-full table-auto border-collapse overflow-hidden rounded-lg shadow-sm">
        <thead>
          <tr className="bg-gray-100">
            {['ID Transaction', 'ID Utilisateur', 'Type', 'Date', 'Montant', 'Statut'].map((header) => (
              <th key={header} className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <tr key={transaction.idTransaction} className="hover:bg-gray-50 transition">
              <td className="px-6 py-4 text-sm font-medium text-gray-900">
                {transaction.idTransaction}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700">
                {transaction.idUser}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700">
                {transaction.type}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700">
                {new Date(transaction.dateHeure).toLocaleString()}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700">
                {transaction.montant} F CFA
              </td>
              <td className="px-6 py-4 text-sm flex items-center gap-2">
                {transaction.statut === 'terminé' ? (
                  <FaCheckCircle className="text-green-500" />
                ) : transaction.statut === 'en cours' ? (
                  <AiOutlineSync className="text-yellow-500 animate-spin" />
                ) : (
                  <FaTimesCircle className="text-red-500" />
                )}
                {transaction.statut}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div className="flex flex-col items-center justify-center gap-2 text-center py-10">
        <FaRegSadCry className="text-5xl text-gray-400" />
        <p className="text-lg font-semibold text-gray-600">
          Aucune transaction trouvée pour cet agent.
        </p>
      </div>
    )}
  </div>
);

export default ClientDetails;
