import React, { useState } from "react";

import Filtrespc from "./Filtrespc";
function Afficher() {
  const [showAlert, setShowAlert] = useState(true);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <button
        onClick={handleShowAlert}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        Afficher l'alerte
      </button>

      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <Filtrespc />
        </div>
      )}
    </div>
  );
}

export default Afficher;
