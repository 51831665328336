// AuthContext.js
import React, { createContext, useState } from "react";
import Splash from "./Splash";

const AuthContext = createContext();

export function AppProvider({ children }) {
  const [email, setEmail] = useState("hhh");
  const [id, setId] = useState(null);
  const [isSplashVisible, setSplashVisible] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [token, setToken] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        id,
        setId,
        isAuthenticated,
        setIsAuthenticated,
        token,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
