// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import getUserInfos from "../requtes/clients/getUserInfos";

import { database, ref, onValue, set, get } from "./FirebaseConfig";

import InfosContext from "./InfosAppProvider";

const ChangementContext = createContext();

export function ChangementProvider({ children }) {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    listeUser,
    token,
    update,
    setUpdate,
    isAuthenticated,
    setIsAuthenticated,
    fetchUserInfos,
    updatek,
    setUpdatek,
  } = useContext(InfosContext);

  const idUser = localStorage.getItem("idUser");

  useEffect(() => {
    // Référence à la partie de la base de données que vous voulez surveiller
    if (isAuthenticated) {
      const dataRef = ref(database, `users`);

      // Écouter les changements en temps réel
      const unsubscribe = onValue(dataRef, (snapshot) => {
        // setData(snapshot.val());
        const timestampUnix = Math.floor(Date.now() / 1000);
        setUpdate(timestampUnix);
        console.log("Value changed:", snapshot.val());
      });

      // Nettoyer l'écouteur lors du démontage du composant
      return () => unsubscribe();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Référence à la partie de la base de données que vous voulez surveiller

    if (isAuthenticated) {
      const dataRef = ref(database, `infos-admin`);

      // Écouter les changements en temps réel
      const unsubscribe = onValue(dataRef, (snapshot) => {
        //    alert("ya changement");
        // setData(snapshot.val());
        const timestampUnix = Math.floor(Date.now() / 1000);
        // alert(timestampUnix);
        //setUpdate(timestampUnix);
        setUpdatek(timestampUnix);

        setUpdate(update + 1);
        //  fetchUserInfos(idUser);
        console.log("Value changed:", snapshot.val());
      });

      // Nettoyer l'écouteur lors du démontage du composant
      return () => unsubscribe();
    }
  }, [isAuthenticated]);

  return <ChangementContext.Provider>{children}</ChangementContext.Provider>;
}

export default ChangementContext;
