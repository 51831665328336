import logo from "./logo.svg";
import "./App.css";

import React from "react";

import Navigator from "./Navigator";
import { AppProvider } from "./contexts/AuthContext";

import { InfosAppProvider } from "./contexts/InfosAppProvider";

//import { InfosAppProvider } from "./contexts/InfosAppProvider";

function App() {
  return (
    <AppProvider>
      <Navigator />
    </AppProvider>
  );
}

export default App;
