const AssistanceWhatsApp = () => {
  return (
    <div className="max-w-lg mx-auto mt-10 p-6  rounded-lg shadow-md text-center">
      <div className="space-y-6">
        {/* Assistance */}
        <div>
          <p className="text-white mb-2">
            Pour assistance via WhatsApp, cliquez sur ce numéro :
          </p>
          <a
            href="https://wa.me/22946875150"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 text-white bg-green-500 rounded-full hover:bg-green-600 transition"
          >
            +229 46 87 51 50
          </a>
        </div>

        {/* Plaintes */}
        <div>
          <p className="text-white mb-2">
            Pour plainte via WhatsApp, cliquez sur ce numéro :
          </p>
          <a
            href="https://wa.me/22961676767"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 text-white bg-red-500 rounded-full hover:bg-red-600 transition"
          >
            +229 61 67 67 67
          </a>
        </div>
      </div>
    </div>
  );
};

export default AssistanceWhatsApp;
