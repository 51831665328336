import { FaCoins, FaUsers, FaChartLine, FaClipboard } from 'react-icons/fa'; // Importer les icônes

import InfosContext from "../../contexts/InfosAppProvider";

import React, { useState, useContext, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import du CSS

import { CopyToClipboard } from "react-copy-to-clipboard";

import Swal from "sweetalert2";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import axios from "axios"


import { erreur } from "./erreur";


import { succes } from "./succes";



export default function IndexParrainage() {
    // Exemple de lien de parrainage

    
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple


     const idUserv = localStorage.getItem("idUser");
    const referralLink = "https://king-pronos.xyz/parrainage?ref=uniqueID";

     const [textToCopy, setTextToCopy] = useState(`https://panel.payforbet.com?ref=${idUserv}`);

  const notifySuccess = () => {
    toast.success("lien copié", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000, // 3 secondes avant de disparaître
    });
  };

    // Fonction pour copier le lien
  
 
 const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    updatek,
     listeUser,
     token
  } = useContext(InfosContext);


const[compteur,setCompteur]=useState(0)


useEffect(()=>{

      let k = 0;

      for (const key in  listeUser) {
        if (listeUser[key].idParrain ==idUserv) {
            k++;
           // setComp
        }

       
    }
     setCompteur(k)



},[listeUser])


 async function demande(){
    

const baseURL = 'https://back.payforbet.com/api/'; // Remplacez par votre véritable base URL
const endpoint = '/demande-retrait-affiliation';
const params = {
  idUser: 10,
  mode: 'manuel',
  numeroRetrait: '62225'
};

// Le token à ajouter (par exemple, un Bearer token)
const tokens = token

// Configuration de la requête avec les en-têtes d'authentification
const config = {
  headers: {
    'Authorization': `Bearer ${tokens}`
  },
  params
};

// Effectuer la requête GET avec Axios en incluant le token
axios.get(`${baseURL}${endpoint}`, config)
  .then((response) => {
     setLoading(false)

        
    // Vérifier si la réponse contient une erreur ou non
    if (response.data.error === false) {
      console.log("Succès:", response.data.message);

       succes()
    } else {
      console.error("Erreur:", response.data.message);
     
    }
  })
  .catch((error) => {
    setLoading(false)
    erreur()
    // Gérer les erreurs de requête
    console.error("Erreur lors de la requête:", error);
    if (error.response) {
        const userInfosString = JSON.stringify(error.response.data);
      //alert("Code de statut:"+error.response.status);
     // alert(userInfosString);
    } else {
     // alert("Aucune réponse reçue ou autre erreur:"+error.message);
    }
  });



 }

    return (

        <>  <ToastContainer /> 
        <div className="bg-black min-h-screen flex flex-col py-2 px-4 mb-10 overflow-auto md:hidden">
            <div className="flex flex-col md:flex-row md:justify-between md:w-full max-w-6xl ">
                {/* Card pour Gain */}
                <div className="bg-gradient-to-r from-green-400 to-bleu rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaCoins className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Solde actuel</h2>
                    <p className="text-white text-xl font-bold"> { userInfos.solde}  F CFA</p>
                </div>

                {/* Card pour Nombre de Filleuls */}
                <div className="bg-gradient-to-r from-pink-400 to-purple-500 rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaUsers className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Nombre de Filleuls</h2>
                    <p className="text-white text-xl font-bold">  {compteur} </p>
                </div>

                {/* Card pour Gain Total */}
                <div className="bg-gradient-to-r from-yellow-400 to-red-500 rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaChartLine className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Total retiré</h2>
                    <p className="text-white text-xl font-bold"> { userInfos.soldeTotal} F CFA</p>
                </div>
            </div>

            {/* Section pour Copier le Lien de Parrainage */}
            <div className="bg-gray-800 rounded-xl shadow-lg p-2 text-center mt-4 mx-2">
               
                <h2 className="text-white text-xl mb-2">Copier votre Lien de Parrainage</h2>
                <p className="text-gray-300 mb-4">{textToCopy}</p>
                  <CopyToClipboard text={textToCopy} onCopy={notifySuccess}> 
                <button
                   
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    Copier le Lien
                </button>
                </CopyToClipboard>
            </div>

            {/* Bouton pour Demander un Retrait */}
            <div className="text-center mt-6">
               

                 <button
                 onClick={()=>{setLoading(true)

                    demande()
                 }}
                className="bg-bleu  py-2 px-4 rounded text-white border border-[#333333]  "
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-4 h-4 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                 ""
                )}

                <span className="text-white">
                  {loading ? "" : "Demander un Retrait"}
                </span>
              </button>
            </div>
        </div>


        <div className="bg-black min-h-screen hidden flex-col   py-2 px-4 mb-10  pt-44 overflow-auto md:flex">
            <div className="flex flex-col md:flex-row md:justify-between md:w-full max-w-6xl mx-auto ">
                {/* Card pour Gain */}
                <div className=" basis-1/3  bg-gradient-to-r from-green-400 to-bleu rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaCoins className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Solde actuel</h2>
                    <p className="text-white text-xl font-bold"> { userInfos.solde}  F CFA</p>
                </div>

                {/* Card pour Nombre de Filleuls */}
                <div className="  basis-1/3  bg-gradient-to-r from-pink-400 to-purple-500 rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaUsers className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Nombre de Filleuls</h2>
                    <p className="text-white text-xl font-bold">  {compteur} </p>
                </div>

                {/* Card pour Gain Total */}
                <div className=" basis-1/3  bg-gradient-to-r from-yellow-400 to-red-500 rounded-xl shadow-lg p-3 text-center mx-2 mb-4 md:mb-0 transition-transform transform hover:scale-105">
                    <FaChartLine className="text-white text-sm mb-4" />
                    <h2 className="text-white text-xl mb-2">Total retiré</h2>
                    <p className="text-white text-xl font-bold"> { userInfos.soldeTotal} F CFA</p>
                </div>
            </div>

            {/* Section pour Copier le Lien de Parrainage */}
            <div className="bg-gray-800 rounded-xl shadow-lg p-2 text-center mt-4 mx-48">
               
                <h2 className="text-white text-xl mb-2">Copier votre Lien de Parrainage</h2>
                <p className="text-gray-300 mb-4">{textToCopy}</p>
                  <CopyToClipboard text={textToCopy} onCopy={notifySuccess}> 
                <button
                   
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    Copier le Lien
                </button>
                </CopyToClipboard>
            </div>

            {/* Bouton pour Demander un Retrait */}
            <div className="text-center mt-6">
               

                 <button
                 onClick={()=>{setLoading(true)

                    demande()
                 }}
                className="bg-bleu  py-2 px-4 rounded text-white border border-[#333333]  "
                type="submit"
              >
                {loading ? (
                  // Barre de progression circulaire en rotation
                  <div className="w-4 h-4 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "transparent",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                 ""
                )}

                <span className="text-white">
                  {loading ? "" : "Demander un Retrait"}
                </span>
              </button>
            </div>
        </div>

        </>
    );
}
