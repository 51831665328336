import BottomNavBar from "./BottomNavBar";
import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowLeft } from "react-feather";

import { TbMoneybag } from "react-icons/tb";

import TransactionCard from "./TransactionCard";

import rond from "../ressources/images/rond.png";

import checks from "../ressources/images/main.png";

import InfosContext from "../contexts/InfosAppProvider";

import { useNavigate } from "react-router-dom"; // Importation de useNavigate
export default function Accueil() {
  const [vide, setVide] = useState(false);
  const navigate = useNavigate(); // Utilisation de useNavigate
  // Fonction pour formater la date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    updatek,
  } = useContext(InfosContext);

  useEffect(() => {
    if (!transactions || Object.keys(transactions).length === 0) {
      setVide(true);
    } else {
      console.log("Transactions n'est pas vide.");
      // alert("Transactions n'est pas vide.");
      setVide(false);
    }
  }, []);
  const truncateRef = (ref) => {
    return ref.length > 8 ? ref.substring(0, 8) + "..." : ref;
  };

  return (
    <>
      <div className="bg-black min-h-screen flex flex-col pt-5 md:hidden">
        <div className="bg-[#272728FF] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
          <span className="inset-y-0 left-0 flex items-center pl-4 text-gray-500">
            <FontAwesomeIcon icon={faSearch} />
          </span>

          <input
            className="w-[307px] h-[52px] pl-4 pr-[20px] font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="text"
            placeholder="Rechercher une transaction"
          />
        </div>

        <div className=" flex flex-row mt-4  mx-auto  space-x-8">
          <div
            onClick={() => {
              navigate("/clients/depot-manuel");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full  mb-1  flex justify-center  items-center">
              <FontAwesomeIcon icon={faArrowUp} />
            </div>
            Dépôt
          </div>

          <div
            onClick={() => {
              navigate("/clients/retrait-manuel");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full mb-1  flex justify-center  items-center">
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
            Retrait
          </div>

          <div
            onClick={() => {
              navigate("/clients/parrainage");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full  mb-1 flex justify-center  items-center">
              <TbMoneybag className="text-[20px] " />
            </div>
            Affiliation
          </div>

          <div className="text-white text-[12px] text-center  font-albert">
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full mb-1 flex justify-center  items-center">
              <FontAwesomeIcon icon={faFutbol} />
            </div>
            Pronos
          </div>
        </div>

        <div className="bg-bleu rounded-[12px] border border-bleu h-24 mt-6 mx-5 flex flex-row px-2 pt-2 ">
          <div className=" basis-2/3">
            <div className="text-white  font-albert">
              {" "}
              Utilisez le code promo{" "}
            </div>

            <div className="text-white  text-2xl font-bold ">
              {" "}
              {infosAdmin.codePromo}{" "}
            </div>

            <div className="text-white font-albert  text-sm">
              pour obtenir pleins de bonus.
            </div>
          </div>

          <div className="flex items-center justify-center   w-28 pl-5 mt-6">
            {" "}
            <img
              src={checks}
              alt="Première image"
              loading="lazy"
              className="w-28 h-40 object-contain"
            />
          </div>
        </div>

        {vide && (
          <div className="bg-[#272728FF   h-64 mt-5 mx-8 rounded-xl border border-[#333333] opacity-100 flex flex-col justify-center items-center ">
            <div class="w-16 h-16 bg-[#333333] rounded-full flex justify-center items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 48 48"
              >
                <defs>
                  <mask id="ipSFolderBlock0">
                    <g fill="none" stroke-width="4">
                      <path
                        fill="#fff"
                        stroke="#fff"
                        stroke-linejoin="round"
                        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2z"
                      />
                      <circle cx="25" cy="27" r="7" fill="#000" stroke="#000" />
                      <path
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m27 25l-4 4"
                      />
                    </g>
                  </mask>
                </defs>
                <path
                  fill="white"
                  d="M0 0h48v48H0z"
                  mask="url(#ipSFolderBlock0)"
                />
              </svg>
            </div>

            <div className=" text-center text-bleu  text-2xl font-bold">
              {" "}
              Pas de transaction !
            </div>

            <div className="text-center text-white font-albert  mx-2">
              Vous n'avez effectué aucune transaction sur Payforbet.
            </div>
          </div>
        )}

        {!vide && (
          <div className="flex flex-col mt-6 mb-20   ">
            <div className="flex flex-row justify-between mx-7  ">
              <div className="text-white font-bold">Transactions </div>
              <div
                onClick={() => {
                  navigate("/clients/menu-transaction");
                }}
                className="text-bleu"
              >
                Tout afficher{" "}
                <span className="mx-2">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </div>
            </div>

            {/* Parcourir et afficher chaque transaction 

            ref ${truncateRef(transaction.idTransaction)}
            
            */}
            {Object.entries(transactions)
              .slice(0, 5) // Limite aux 5 premières transactions
              .map(([id, transaction]) => (
                <TransactionCard
                  key={id}
                  icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
                  title={`${
                    transaction.type === "depot" ? "Dépôt" : "Retrait"
                  } `}
                  date={formatDate(transaction.dateHeure)}
                  amount={`${transaction.montant.toLocaleString()} F CFA`}
                  id={`id:${transaction.idCompte1xbet}`}
                  idTran={id}
                  bgColor={
                    transaction.statut === "en cours"
                      ? "bg-[#EFB034FF]"
                      : transaction.type === "depot"
                      ? "bg-[#28C281FF]"
                      : "bg-[#AA1D22FF]"
                  }
                />
              ))}
          </div>
        )}
      </div>

      <div className="bg-black min-h-screen  mx-autos flex-col pt-5 hidden md:flex">
        <div className="bg-[#272728FF] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-auto mt-1">
          <span className="inset-y-0 left-0 flex items-center pl-4 text-gray-500">
            <FontAwesomeIcon icon={faSearch} />
          </span>

          <input
            className="w-[307px] h-[52px] pl-4 pr-[20px] font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="text"
            placeholder="Rechercher une transaction"
          />
        </div>

        <div className=" flex flex-row mt-4  mx-auto  space-x-8">
          <div
            onClick={() => {
              navigate("/clients/depot-manuel");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full  mb-1  flex justify-center  items-center">
              <FontAwesomeIcon icon={faArrowUp} />
            </div>
            Dépôt
          </div>

          <div
            onClick={() => {
              navigate("/clients/retrait-manuel");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full mb-1  flex justify-center  items-center">
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
            Retrait
          </div>

          <div
            onClick={() => {
              navigate("/clients/parrainage");
            }}
            className="text-white text-[12px] text-center font-albert"
          >
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full  mb-1 flex justify-center  items-center">
              <TbMoneybag className="text-[20px] " />
            </div>
            Affiliation
          </div>

          <div className="text-white text-[12px] text-center  font-albert">
            {" "}
            <div class="w-12 h-12 bg-bleu rounded-full mb-1 flex justify-center  items-center">
              <FontAwesomeIcon icon={faFutbol} />
            </div>
            Pronos
          </div>
        </div>

        <div className="bg-bleu rounded-[12px] border border-bleu h-24 mt-6  flex flex-row px-2 pt-2 mx-auto">
          <div className=" basis-2/3">
            <div className="text-white  font-albert">
              {" "}
              Utilisez le code promo{" "}
            </div>

            <div className="text-white  text-2xl font-bold ">
              {" "}
              {infosAdmin.codePromo}{" "}
            </div>

            <div className="text-white font-albert  text-sm">
              pour obtenir pleins de bonus.
            </div>
          </div>

          <div className="flex items-center justify-center">
            {" "}
            <img
              src={checks}
              alt="Première image"
              loading="lazy"
              className="w-2/3 h-36 object-contain"
            />
          </div>
        </div>

        {vide && (
          <div className="bg-[#272728FF] mx-auto  w-80 h-64 mt-5 rounded-xl border border-[#333333] opacity-100 flex flex-col justify-center items-center ">
            <div class="w-16 h-16 bg-[#333333] rounded-full flex justify-center items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  fill-rule="evenodd"
                  d="M2.07 5.258C2 5.626 2 6.068 2 6.95V14c0 3.771 0 5.657 1.172 6.828S6.229 22 10 22h4c3.771 0 5.657 0 6.828-1.172S22 17.771 22 14v-2.202c0-2.632 0-3.949-.77-4.804a3 3 0 0 0-.224-.225C20.151 6 18.834 6 16.202 6h-.374c-1.153 0-1.73 0-2.268-.153a4 4 0 0 1-.848-.352C12.224 5.224 11.816 4.815 11 4l-.55-.55c-.274-.274-.41-.41-.554-.53a4 4 0 0 0-2.18-.903C7.53 2 7.336 2 6.95 2c-.883 0-1.324 0-1.692.07A4 4 0 0 0 2.07 5.257M13.713 16C14.977 16 16 15.052 16 13.882c0-.927-.643-1.714-1.538-2.001C14.335 10.823 13.366 10 12.191 10c-1.263 0-2.286.948-2.286 2.118c0 .258.05.506.142.735a2 2 0 0 0-.333-.03c-.946 0-1.714.712-1.714 1.589S8.768 16 9.714 16z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <div className=" text-center text-bleu  text-2xl font-bold">
              {" "}
              Pas de transaction !
            </div>

            <div className="text-center text-white font-albert  mx-8 ">
              Vous n'avez pas de transaction sur Payforbet.
            </div>
          </div>
        )}

        {!vide && (
          <div className="flex  flex-col mt-6 mb-20  mx-96 ">
            <div className="flex flex-row justify-between mx-52 ">
              <div className="text-white font-bold">Transactions</div>
              <div
                onClick={() => {
                  navigate("/clients/menu-transaction");
                }}
                className="text-bleu"
              >
                Tout afficher{" "}
                <span className="mx-2">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </div>
            </div>

            {/* Parcourir et afficher chaque transaction 
            ref ${truncateRef(transaction.idTransaction)}
            
            */}
            {Object.entries(transactions)
              .slice(0, 5) // Limite aux 5 premières transactions
              .map(([id, transaction]) => (
                <TransactionCard
                  key={id}
                  icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
                  title={`${
                    transaction.type === "depot" ? "Dépôt" : "Retrait"
                  } `}
                  date={formatDate(transaction.dateHeure)}
                  amount={`${transaction.montant.toLocaleString()} F CFA`}
                  id={`id:${transaction.idCompte1xbet}`}
                  idTran={id}
                  bgColor={
                    transaction.statut === "en cours"
                      ? "bg-[#EFB034FF]"
                      : transaction.type === "depot"
                      ? "bg-[#28C281FF]"
                      : "bg-[#AA1D22FF]"
                  }
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
}
