import React from 'react'
import { IoMenu } from "react-icons/io5";

import logo from "../../ressources/images/logo.png"

const Header = ({ toggleSidebar }) => {
 

    return (
        <div className='admin-theme py-3 px-5 flex gap-2 items-center justify-between w-full border-b-[1px] border-b-lightGray'>
            <div className='flex justify-between items-center w-full'>
                <div className='flex items-center gap-2'>
                    <button className='h-10 w-12 items-center justify-center rounded-xl border border-gray lg:hidden flex' onClick={toggleSidebar}>
                          <IoMenu/>
                    </button>
                    <div className='flex flex-col'>
                        <h1 className=' dark:text-lightGray  text-bleu text-base font-semibold flex flex-row'>

                           <img src={logo} alt="Exemple" className="w-8 h-8 mr-2 " /> PAYFORBET



                        </h1>
                        <h2 className='text-darkGray dark:text-gray font-medium text-sm'></h2>
                    </div>
                </div>
              
            </div>
        </div>
    )
}

export default Header