import React from "react";
import ballon from "../ressources/images/ballon.png";

import basket from "../ressources/images/basket.png";

import autre from "../ressources/images/autre.png";

import "./spa.css";
export default function SplashScreen() {
  return (
    <div className={`bg-black min-h-screen flex flex-col `}>
      <div className="flex items-center justify-center  h-screen bg-gradient-to-r from-blue-500 to-green-500">
        <div className="relative flex space-x-6">
          {/* Ballon 1 */}
          <div className="animate-bounce-custom delay-0">
            <img
              src={basket}
              alt="Ballon 1"
              loading="lazy"
              className="w-12 h-16 object-contain"
            />
          </div>
          {/* Ballon 2 */}
          <div className="animate-bounce-custom delay-200">
            <img
              src={ballon}
              alt="Ballon 2"
              loading="lazy"
              className="w-12 h-16 object-contain"
            />
          </div>
          {/* Ballon 3 */}
          <div className="animate-bounce-custom delay-400">
            <img
              src={autre}
              alt="Ballon 3"
              loading="lazy"
              className="w-12 h-16 object-contain"
            />
          </div>
        </div>
        <h1 className="absolute text-white text-4xl font-bold animate-fade-in mt-20 ">
          Payfor<span className="text-bleu">Bet </span>
        </h1>
      </div>
    </div>
  );
}
