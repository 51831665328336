import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import TransactionCard from "../TransactionCard";
//import { InfosContext } from "../../context/InfosContext"; // Import du contexte

import InfosContext from "../../contexts/InfosAppProvider";

const RetraitsWithPagination = ({ transactions, itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const { selectedStatuses, removeStatus, addStatus } =
    useContext(InfosContext); // Utilisation du contexte

  const truncateRef = (ref) => {
    return ref.length > 8 ? ref.substring(0, 8) + "..." : ref;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    // Filtrer les transactions de type "depot" selon le statut sélectionné
    const filteredTransactions = Object.entries(transactions).filter(
      ([id, transaction]) =>
        transaction.type === "retrait" &&
        selectedStatuses.includes(transaction.statut)
    );

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredTransactions.slice(itemOffset, endOffset)); // Pagination après filtrage
    setPageCount(Math.ceil(filteredTransactions.length / itemsPerPage)); // Calcul du nombre de pages
  }, [itemOffset, itemsPerPage, transactions, selectedStatuses]); // Dépendance sur `selectedStatuses`

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      Object.entries(transactions).filter(
        ([id, transaction]) =>
          transaction.type === "retrait" &&
          selectedStatuses.includes(transaction.statut)
      ).length;
    setItemOffset(newOffset); // Mise à jour de l'offset
  };

  return (
    <>
      <div className="transaction-list md:mx-16">
        {currentItems.map(([id, transaction]) => (
          <TransactionCard
            key={id}
            icon={faArrowDown} // Icône pour les dépôts
            title={`Dépôt `}
            date={formatDate(transaction.dateHeure)}
            amount={`${transaction.montant.toLocaleString()} F CFA`}
            id={`id:${transaction.idCompte1xbet}`}
            bgColor={
              transaction.statut === "en cours"
                ? "bg-[#EFB034FF]"
                : transaction.type === "depot"
                ? "bg-[#28C281FF]"
                : "bg-[#AA1D22FF]"
            }
          />
        ))}
      </div>
      <ReactPaginate
        breakLabel={
          <span className="px-4 py-2 bg-bleu rounded h-full flex items-center">
            ...
          </span>
        }
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="flex justify-center space-x-2 mt-5 flex-wrap"
        pageClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
        activeClassName="bg-bleu text-white"
        previousClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
        nextClassName="px-4 py-2 bg-[#303236FF] rounded whitespace-nowrap"
      />
    </>
  );
};

export default RetraitsWithPagination;
