import React, { useContext } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

import { updateRetraitAffiliation } from "./updateRetraitAffiliation";
import { useParams, Link } from "react-router-dom";

import { action } from "./action";
const RetraitDetail = () => {
  const { id } = useParams();
  const { listeUser, token } = useContext(InfosContext);
  const agent = Object.values(listeUser).find((user) => user.id == id);

  return (
    <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-1">
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 ">
        <h2 className="text-white text-3xl font-bold text-center">
          Détails de l'Agent
        </h2>
      </div>

      <div className="p-8 space-y-2">
        <DetailRow label="Nom" value={agent.nom} />
        <DetailRow label="Email" value={agent.email} />
        <DetailRow label="WhatsApp" value={agent.whatsapp} />
        <DetailRow label="Pays" value={agent.pays} />

        <DetailRow
          label="Solde Disponible"
          value={`${agent.soldeDisponible} FCFA`}
        />

        <DetailRow
          label="Numéro MoMo Réception"
          value={agent.numeroMomo || "Non fourni"}
        />

        <DetailRow label="Nom MoMo" value={agent.nomMoMo || "Non fourni"} />
      </div>

      <div className="bg-gray-50 p-6 flex justify-evenly">
        <ActionButton
          label="Valider"
          icon={<FaCheck />}
          color="bg-green-500"
          hoverColor="hover:bg-green-600"
          onClick={() => {
            // Exemple d'utilisation
            updateRetraitAffiliation(id, "terminé", token)
              .then((data) => action())
              .catch((err) => action());
          }}
        />

        <ActionButton
          label="Rejeter"
          icon={<FaTimes />}
          color="bg-red-500"
          hoverColor="hover:bg-red-600"
          onClick={() => {
            // Exemple d'utilisation
            updateRetraitAffiliation(id, "rejeté", token)
              .then((data) => action())
              .catch((err) => action());
          }}
        />
      </div>
    </div>
  );
};

const DetailRow = ({ label, value }) => (
  <div className="flex justify-between border-b pb-4">
    <span className="text-gray-500 font-medium">{label} :</span>
    <span className="text-gray-900">{value}</span>
  </div>
);

const ActionButton = ({ label, icon, color, hoverColor, onClick }) => (
  <button
    className={`${color} ${hoverColor} text-white px-8 py-1 rounded-lg flex items-center gap-2 transition-all duration-300`}
    onClick={onClick}
  >
    {icon}
    {label}
  </button>
);

export default RetraitDetail;
