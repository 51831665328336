//const axios = require('axios'); // Assurez-vous que Axios est installé

import axios from "axios";

export default async function getUserInfos(idUser, token) {
  const baseURL = "https://back.payforbet.com/api/"; // Replace with your actual base URL
  try {
    // Construction de l'URL complète
    const url = `${baseURL}infos-user?idUser=${idUser}`;

    // Envoi de la requête GET avec le token dans les en-têtes
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Traitement de la réponse
    console.log(response.data); // Affiche les données de l'utilisateur
    return response.data; // Retourne les données si besoin
  } catch (error) {
    // Gestion des erreurs
    console.error(
      "Erreur lors de la récupération des informations utilisateur :",
      error
    );
  }
}
