import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const Verify = () => {
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <div
      className={`bg-black min-h-screen flex flex-col pt-36 ${
        inputFocused ? "keyboard-open" : ""
      }`}
    >
      <div className="bg-bleu h-24 w-24 mx-28 rounded-3xl flex justify-center items-center">
        <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "white", fontSize: "48px" }}
        />
      </div>

      <div className="text-white mx-12 mt-5 flex justify-center items-center font-albert">
        Entrez votre adresse e-mail pour recevoir votre code de connexion
      </div>

      <div className="flex flex-row space-x-5 mt-6">
        <div className="bg-[#272728] flex flex-row  border-[#333333] opacity-100 ">
          <input
            className="w-10 h-10 pl-[52px]  font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <div className="bg-[#272728] flex flex-row  border-[#333333] opacity-100 ">
          <input
            className="w-10 h-10 pl-[52px]  font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <div className="bg-[#272728] flex flex-row  border-[#333333] opacity-100 ">
          <input
            className="w-10 h-10 pl-[52px]  font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <div className="bg-[#272728] flex flex-row  border-[#333333] opacity-100 ">
          <input
            className="w-10 h-10 pl-[52px]  font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>

        <div className="bg-[#272728] flex flex-row  border-[#333333] opacity-100 ">
          <input
            className="w-10 h-10 pl-[52px]  font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
            type="email"
            placeholder="Adresse e-mail"
            onFocus={() => setInputFocused(true)} // Quand l'input est focus
            onBlur={() => setInputFocused(false)} // Quand l'input perd le focus
          />
        </div>
      </div>

      <button className="bg-bleu h-14 flex flex-row justify-center items-center text-white rounded-xl border border-[#333333] opacity-100 mx-5 mt-5">
        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
        <span className="text-white">Envoyer le code</span>
      </button>
    </div>
  );
};

export default Verify;
