import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./style.css"; // Assurez-vous d'importer le fichier CSS
import React, { useState, useContext } from "react";
import InfosContext from "../../contexts/InfosAppProvider"; // Assurez-vous d'importer le contexte

export default function Filtres({ onClose }) {
  const { selectedStatuses, removeStatus, addStatus, open, setOpen } =
    useContext(InfosContext);

  // État pour les cases à cocher
  const [checkedStatuses, setCheckedStatuses] = useState({
    réussi: selectedStatuses.includes("réussi"),
    "en cours": selectedStatuses.includes("en cours"),
    échoué: selectedStatuses.includes("échoué"),
  });

  // Fonction pour gérer les changements d'état des cases à cocher
  const handleCheckboxChange = (status) => {
    setCheckedStatuses((prev) => {
      const newCheckedStatus = !prev[status]; // Inverser la valeur actuelle
      if (newCheckedStatus) {
        // addStatus(status); // Ajouter le statut si coché
      } else {
        // removeStatus(status); // Retirer le statut si décoché
      }
      return { ...prev, [status]: newCheckedStatus }; // Mettre à jour l'état
    });
  };

  return (
    <div className="bg-[#414151FF] min-h-96">
      <div className="flex flex-row p-5">
        <div className="flex w-full justify-end items-end text-white font-bold">
          Filtres
        </div>

        <div className="flex w-full justify-end items-end text-white">
          <div
            onClick={onClose}
            className="flex items-center justify-center h-6 w-6 rounded-full bg-red-500 cursor-pointer"
          >
            <FontAwesomeIcon icon={faTimes} className="h-5 w-5 text-white" />
          </div>
        </div>
      </div>

      <div className="flex flex-col mx-10 text-white">
        <div>Statut</div>

        {["réussi", "en cours", "échoué"].map((status) => (
          <div key={status} className="flex flex-row justify-between">
            <div>{status.charAt(0).toUpperCase() + status.slice(1)}</div>
            <div>
              <input
                type="checkbox"
                id={`checkbox-${status}`}
                className={`form-checkbox h-6 w-6 rounded border-2 ${
                  checkedStatuses[status] ? "bleu accent-bleu" : "border-bleu"
                } focus:ring-2 focus:ring-bleu`}
                checked={checkedStatuses[status]}
                onChange={() => handleCheckboxChange(status)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="w-full h-[0.2px] bg-white mt-5"></div>

      <div className="flex flex-row mt-8 justify-center items-center space-x-6">
        <button className="text-white border border-white rounded-md px-2 w-28 py-1">
          Tout effacer
        </button>

        <button
          onClick={() => {
            // Met à jour les statuts en fonction de leur état
            Object.keys(checkedStatuses).forEach((status) => {
              if (checkedStatuses[status]) {
                addStatus(status); // Ajoute le statut si coché
              } else {
                removeStatus(status); // Retire le statut si décoché
              }
            });
            setOpen(false);
            // Affiche les statuts sélectionnés
            // alert("Statuts sélectionnés : " + JSON.stringify(checkedStatuses));
          }}
          className="text-white border border-bleu rounded-md px-2 bg-bleu w-28 py-1"
        >
          Valider
        </button>
      </div>
    </div>
  );
}
