import React, { useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaMoneyBillWave, FaWallet, FaBox, FaExchangeAlt, FaRegSadCry, FaArrowLeft, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

//import { InfosAppProvider } from "../../contexts/InfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

const AgentDetails = () => {
    const { id } = useParams();
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [agentsPerPage, setAgentsPerPage] = useState(10);

    const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        listeCaisses,
        token
    } = useContext(InfosContext);

    const agent = Object.values(listeUser).find(user => user.id === id);

    if (!agent) return <p>Agent non trouvé</p>;

    // Calcul des montants
    const caisses = listeCaisses.filter(caisse => caisse.idAgent === agent.id);
    const totalDepot = Object.values(transactions)
        .filter(transaction => transaction.type === 'depot' && caisses.some(caisse => caisse.id === transaction.idCaisse))
        .reduce((total, transaction) => total + Number(transaction.montant), 0);
    
    const totalRetrait = Object.values(transactions)
        .filter(transaction => transaction.type === 'retrait' && caisses.some(caisse => caisse.id === transaction.idCaisse))
        .reduce((total, transaction) => total + Number(transaction.montant), 0);

    const nombreCaisses = caisses.length;
    const nombreTransactions = Object.values(transactions)
        .filter(transaction => caisses.some(caisse => caisse.id === transaction.idCaisse)).length;

    const transactionsEnCours = Object.values(transactions)
        .filter(transaction => transaction.statut === 'en cours' && caisses.some(caisse => caisse.id === transaction.idCaisse)).length;

    const transactionsTerminees = Object.values(transactions)
        .filter(transaction => transaction.statut === 'terminée' && caisses.some(caisse => caisse.id === transaction.idCaisse)).length;

    // Filtrer les transactions de l'agent
    const transactionsAgent = Object.values(transactions).filter(transaction => caisses.some(caisse => caisse.id === transaction.idCaisse));

    return (
        <div className="p-5">
            <h1 className="text-3xl font-bold text-gray-700 mb-8">Fiche de l'agent: {agent.nom}</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <div className="bg-indigo-500 text-white p-6 rounded-lg shadow-lg flex items-center">
                    <FaMoneyBillWave className="text-4xl mr-4" />
                    <div>
                        <h3 className="text-lg font-semibold">Total Dépôt</h3>
                        <p className="text-2xl mt-2">{totalDepot} F CFA</p>
                    </div>
                </div>
                <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg flex items-center">
                    <FaWallet className="text-4xl mr-4" />
                    <div>
                        <h3 className="text-lg font-semibold">Total Retrait</h3>
                        <p className="text-2xl mt-2">{totalRetrait} F CFA</p>
                    </div>
                </div>

                 <div className="bg-purple-500 text-white p-6 rounded-lg shadow-lg flex items-center">
                    <FaExchangeAlt className="text-4xl mr-4" />
                    <div>
                        <h3 className="text-lg font-semibold">Nombre de Transactions</h3>
                        <p className="text-2xl mt-2">{nombreTransactions}</p>
                    </div>
                </div>
             
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
               
                <div className="bg-yellow-500 text-white p-6 rounded-lg shadow-lg flex items-center">
                    <FaExchangeAlt className="text-4xl mr-4" />
                    <div>
                        <h3 className="text-lg font-semibold">Transactions en cours</h3>
                        <p className="text-2xl mt-2">{transactionsEnCours}</p>
                    </div>
                </div>
                <div className="bg-teal-500 text-white p-6 rounded-lg shadow-lg flex items-center">
                    <FaExchangeAlt className="text-4xl mr-4" />
                    <div>
                        <h3 className="text-lg font-semibold">Transactions terminées</h3>
                        <p className="text-2xl mt-2">{transactionsTerminees}</p>
                    </div>
                </div>
            </div>

             <div className="bg-white p-8 rounded-xl shadow-lg max-w-6xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-3xl font-extrabold text-gray-800">📋 Liste des Transactions de l'Agent</h2>
        <Link to="/listAgents">
          <button className="flex items-center gap-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition">
            <FaArrowLeft /> Retour
          </button>
        </Link>
      </div>

      {transactionsAgent.length > 0 ? (
        <table className="min-w-full table-auto border-collapse overflow-hidden rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-100">
              {['ID Transaction', 'ID Utilisateur', 'Type', 'Date', 'Montant', 'Statut'].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactionsAgent.map((transaction) => (
              <tr
                key={transaction.idTransaction}
                className="hover:bg-gray-50 transition"
              >
                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                  {transaction.idTransaction}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {transaction.idUser}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {transaction.type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {new Date(transaction.dateHeure).toLocaleString()}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {transaction.montant} F CFA
                </td>
                <td className="px-6 py-4 text-sm flex items-center gap-2">
                  {transaction.statut === 'Validé' ? (
                    <FaCheckCircle className="text-green-500" />
                  ) : (
                    <FaTimesCircle className="text-red-500" />
                  )}
                  {transaction.statut}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col items-center justify-center gap-2 text-center py-10">
          <FaRegSadCry className="text-5xl text-gray-400" />
          <p className="text-lg font-semibold text-gray-600">
            Aucune transaction trouvée pour cet agent.
          </p>
        </div>
      )}
    </div>
        </div>
    );
};

export default AgentDetails;
