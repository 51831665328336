import axios from "axios";

export const ajouterTransaction = async (
  type,
  idUser,
  idCompte1xbet,
  codeRetrait,
  moyenPaiement,
  montant,
  mode,
  idTransactionMobile,
  token // Token passé en paramètre
) => {
  const baseURL = "https://back.payforbet.com/api/"; // Replace with your actual base URL
  try {
    // Construire l'URL avec les paramètres
    const endpoint = `${baseURL}/ajouter-transactions`;

    const params = {
      type: type,
      idUser: idUser,
      idCompte1xbet: idCompte1xbet,
      codeRetrait: codeRetrait,
      moyenPaiement: moyenPaiement,
      montant: montant,
      mode: mode,
      idTransactionMobile: idTransactionMobile,
    };

    // Configurer les headers pour inclure le token
    const headers = {
      Authorization: `Bearer ${token}`, // Assurez-vous que le token est bien formaté avec "Bearer"
    };

    // Effectuer la requête GET avec les paramètres dans l'URL et les headers
    const response = await axios.get(endpoint, {
      params,
      headers, // Ajout des headers ici
    });
    //   alert(JSON.stringify(response.data, null, 2));

    // Traiter la réponse
    //alert(response.data);
    //console.log("Réponse du serveur:", response.data);
    return response.data;
  } catch (error) {
    alert(error);
    console.error("Erreur lors de la requête:", error);
  }
};
