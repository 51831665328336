import React, { useState, useContext } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
///import { TransactionsContext } from '../../contexts/TransactionsContext'; 

//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";


import InfosContext from "../../contexts/InfosAppProvider";
const ListTransactions = () => {
 //   const { transactions,  } = useContext(TransactionsContext);
    const [input, setInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage, setTransactionsPerPage] = useState(10);
    const [filter, setFilter] = useState(''); // État pour le filtre
    //const transactions={}



    const inputHandler = (event) => {
        setInput(event.target.value);
    };

      const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
   
  } = useContext(InfosContext);

    // Convertit l'objet d'objets en un tableau
    const transactionsArray = Object.values(transactions);

    // Appliquer le filtre (statut et type) et la recherche
    const filteredTransactions = transactionsArray.filter(transaction => {
        // Filtrer par statut ou type
        if (filter === 'depot' && transaction.type !== 'depot') return false;
        if (filter === 'retrait' && transaction.type !== 'retrait') return false;
        if (filter === 'enCours' && transaction.statut !== 'en cours') return false;
        if (filter === 'termine' && transaction.statut !== 'terminé') return false;

        // Filtrer par recherche (vérifie si l'input correspond à l'une des propriétés de la transaction)
        const searchTerm = input.toLowerCase();
        return (
            transaction.moyenPaiement.toLowerCase().includes(searchTerm) ||
            transaction.montant.toString().includes(searchTerm) ||
            new Date(transaction.dateHeure).toLocaleString().toLowerCase().includes(searchTerm)
        );
    });

    // Pagination
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
    const totalTransactions = filteredTransactions.length;

    // Changer de page
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handleTransactionsPerPageChange = (event) => {
        setTransactionsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

   // if (loading) return <p>Loading transactions...</p>; // Afficher un message de chargement

    return (
        <div className='m-5'>
            <h2 className='text-3xl text-blue my-5'>Transactions</h2>
            <div className='flex bg-white rounded py-4 px-2'>
                <div>
                    <form className="" onSubmit={event => event.preventDefault()}>
                        <div className='sm:min-w-72 min-w-full p-2 border border-gray rounded flex items-center gap-2 bg-white dark:bg-[#171923]'>
                            <input
                                type="text"
                                placeholder="Rechercher"
                                required
                                onChange={inputHandler}
                                value={input}
                                className="border-none outline-none bg-transparent"
                            />
                        </div>
                    </form>
                </div>
            </div>

            <div className='bg-white rounded mt-4'>
                <div className='flex items-center justify-between mx-5 border-b border-blue'>
                    <div className='flex gap-4 h-14'>

                         <button 
                            className={`border-b-2 ${filter === 'enCours' ? 'border-b-red-500' : 'border-b-transparent'} px-4`}
                            onClick={() => { setFilter('enCours'); setCurrentPage(1); }}
                        >
                            En cours
                        </button>
                        <button 
                            className={`border-b-2 ${filter === 'depot' ? 'border-b-red-500' : 'border-b-transparent'} px-4`}
                            onClick={() => { setFilter('depot'); setCurrentPage(1); }}
                        >
                            Dépôt
                        </button>
                        <button 
                            className={`border-b-2 ${filter === 'retrait' ? 'border-b-red-500' : 'border-b-transparent'} px-4`}
                            onClick={() => { setFilter('retrait'); setCurrentPage(1); }}
                        >
                            Retrait
                        </button>
                       
                        <button 
                            className={`border-b-2 ${filter === 'termine' ? 'border-b-red-500' : 'border-b-transparent'} px-4`}
                            onClick={() => { setFilter('termine'); setCurrentPage(1); }}
                        >
                            Terminé
                        </button>
                    </div>
                    <div className="flex gap-4 items-center py-2">
                        <div className="flex justify-between items-center">
                            <select
                                id="TransactionsPerPage"
                                value={transactionsPerPage}
                                onChange={handleTransactionsPerPageChange}
                                className="border border-gray rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                        >
                            <FaChevronLeft className={`text-blue ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                        <div className="text-center text-xs">
                            <span>{indexOfFirstTransaction + 1} - {Math.min(indexOfLastTransaction, totalTransactions)} sur {totalTransactions}</span>
                        </div>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastTransaction >= totalTransactions}
                        >
                            <FaChevronRight className={`text-blue ${indexOfLastTransaction >= totalTransactions ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                    </div>
                </div>
                
                {/* Afficher un message si aucune transaction n'est trouvée */}
                {totalTransactions === 0 ? (
                    <p className="text-center text-red-500 py-8">Aucune transaction trouvée pour la recherche ou le filtre sélectionné.</p>
                ) : (
                    <div className="overflow-hidden bg-white dark:bg-[#171923] text-dark">
                        <div className="overflow-y-auto overflow-x-auto max-h-[calc(100vh-150px)] bg-white dark:bg-[#171923]">
                            <table className="min-w-full bg-white dark:bg-[#171923]">
                                <thead className='bg-white dark:bg-[#171923]'>
                                    <tr className='text-center font-medium text-sm border-b-[1px] border-gray bg-lightGray bg-opacity-0 dark:bg-opacity-10'>
                                        <th className="p-4">#</th>
                                        <th className="p-4">Compte1xbet ID</th>
                                        <th className="p-4">Transaction ID</th>
                                        <th className="py-4 px-2">Moyen de Paiement</th>
                                        <th className="p-4">Montant</th>
                                        <th className="py-4 px-2">Date</th>
                                        <th className="p-4">Statut</th>
                                        <th className="p-4">Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTransactions.map((transaction, index) => (
                                        <tr key={index} className="border-b-[1px] border-gray">
                                            <td className="py-4 px-8">{indexOfFirstTransaction + index + 1}</td>
                                            <td className="py-4 px-8">{transaction.idCompte1xbet}</td>
                                            <td className="py-4 px-8">{transaction.idTransactionMobile}</td>
                                            <td className="py-4 px-8">{transaction.moyenPaiement}</td>
                                            <td className="py-4 px-8">{transaction.montant}</td>
                                            <td className="py-4 px-8">
                                                {new Date(transaction.dateHeure).toLocaleString()}
                                            </td>
                                            <td className='py-4 w-full text-center'>
                                                <span className={`p-2 rounded-xl ${transaction.statut === 'en cours' ? 'bg-lightBlue' : 'bg-green-200'}`}>{transaction.statut}</span>
                                            </td>
                                            <td className="py-4 px-8">{transaction.type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListTransactions;
