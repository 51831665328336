import React, { useState, useContext } from 'react'; // Ajout de useContext
//import { AgentContext } from '../../contexts/AddAgentContext';  

//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

//import  AddAgent

import addAgent from '../../requtes/admin/addAgent';

import Swal from "sweetalert2";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


import { ajoutAgentSucces } from '../alert/ajoutAgentSucces';

import  {existeAgent } from  '../alert/existeAgent';

import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";





const AddAgent = () => {
 // const { addAgent } = useContext(AgentContext); // Utilisation de useContext ici
  const [agentData, setAgentData] = useState({ email: '', nom: '', whatsapp: '', pays: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


   const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple


   const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        listeCaisses,
        token
    } = useContext(InfosContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentData({ ...agentData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Réinitialiser les messages
    setSuccessMessage('');

      setLoading(true);
    
    try {
      const result = await addAgent(agentData.email, agentData.nom, agentData.whatsapp, agentData.pays,token);
      
      if (result.success) {
        setSuccessMessage(result.message); // Message de succès si l'ajout est réussi
        ajoutAgentSucces()
     
      setLoading(false);
     
      } else {
        setErrorMessage(result.message); // Message d'erreur si l'agent existe déjà ou si une erreur survient
          setLoading(false);
           existeAgent()
      }
    } catch (error) {
        setLoading(false);
         existeAgent()
      setErrorMessage('Une erreur est survenue lors de l\'ajout de l\'agent. Veuillez réessayer.');
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white shadow-md rounded-lg p-6 mt-8">
      <h2 className="text-xl font-semibold mb-4 text-center text-gray-700">Ajouter un Agent</h2>

      {/* Message d'erreur */}
      {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

      {/* Message de succès */}
      {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}

      <div className="mb-4">
        <input 
          type="email" 
          name="email" 
          value={agentData.email} 
          onChange={handleChange} 
          placeholder="Email" 
          required 
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="mb-4">
        <input 
          type="text" 
          name="nom" 
          value={agentData.nom} 
          onChange={handleChange} 
          placeholder="Nom" 
          required 
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="mb-4">
        <input 
          type="text" 
          name="whatsapp" 
          value={agentData.whatsapp} 
          onChange={handleChange} 
          placeholder="WhatsApp" 
          required 
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="mb-4">
        <input 
          type="text" 
          name="pays" 
          value={agentData.pays} 
          onChange={handleChange} 
          placeholder="Pays" 
          required 
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <button 
        type="submit" 
        className="w-full bg-bleu flex flex-row justify-center items-center hover:bg-bleu text-white font-semibold py-3 rounded-lg transition duration-200 bg-bleu"
      >
       



         {loading ? (
            // Barre de progression circulaire en rotation
            <div className="w-8 h-8 mr-2 animate-spin">
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "white",
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          )}

          <span className="text-white">
            {loading ? "Enregistrement..." : " Ajouter Agent "}
          </span>
      </button>
    </form>
  );
};

export default AddAgent;
