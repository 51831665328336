import axios from "axios"; // Importation d'Axios

export async function updateRetraitAffiliation(
  idRetrait,
  nouveauStatut,
  token
) {
  const baseURL =
    "https://back.payforbet.com/api/approuver-retrait-affiliation";

  try {
    const response = await axios.get(baseURL, {
      params: {
        idRetrait: idRetrait,
        nouveauStatut: nouveauStatut,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Ajout du token d'authentification
      },
    });

    console.log("Réponse:", response.data);
    return response.data; // Manipuler la réponse si nécessaire
  } catch (error) {
    console.error("Erreur lors de la requête:", error.message);
    throw error; // Propager l'erreur si nécessaire
  }
}

/*
// Exemple d'utilisation
const token = "votre_token_ici"; // Remplacez par le token réel
updateRetraitAffiliation("sggdhd", "terminé", token)
  .then((data) => console.log("Succès:", data))
  .catch((err) => console.error("Erreur:", err));
  */
