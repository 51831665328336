import axios from "axios";

const baseURL = "https://back.payforbet.com/api/";

export const updateStatutAgent = async (idAgent, statut, token) => {
  try {
    const response = await axios.get(`${baseURL}/update-statut-agent`, {
      params: {
        idAgent: idAgent,
        statut: statut,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Le token est ajouté dans les headers ici
      },
    });

    // Gérer la réponse ici
    console.log("Réponse du serveur:", response.data);
    return response.data;
  } catch (error) {
    // Gérer les erreurs ici
    console.error("Erreur lors de la mise à jour du statut:", error);
    throw error;
  }
};
