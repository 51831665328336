import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
  faArrowCircleLeft,
  faKeyboard,
  faExchangeAlt,
  faSyncAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "react-feather";
import { useState } from "react";

import Lottie from "lottie-react";

import bravo from "../../animation/succes.json";
import { useParams, Link, useNavigate } from "react-router-dom";

export default function RetraitManuelSucces() {
  const navigate = useNavigate();

  const { id } = useParams();

  const depotXbet = localStorage.getItem("depotxbet");
  const depotMontant = localStorage.getItem("depotmontant");

  const storedNumero = localStorage.getItem("depotnumero") || "";
  const storedNom = localStorage.getItem("depotnom") || "";
  return (
    <>
      <div className="bg-black min-h-screen flex flex-col pt-5 md:hidden">
        <div className="mt-5 flex justify-center items-center ">
          {" "}
          <Lottie className="w-36  mb-2" animationData={bravo} loop={true} />
        </div>

        <div className="text-bleu font-albert text-center text-2xl">
          Demande envoyée !
        </div>

        <div className="text-white font-albert text-center px-12">
          Vous recevrez une notification une fois traitée. Merci ! ☺
        </div>

        <div className="bg-[#272728]  flex flex-col rounded-xl border border-[#333333] opacity-100 mx-5 mt-1 py-2">
          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">ID Payforbet</div>

            <div className="text-white font-bold"> {id} </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Type de Transaction</div>

            <div className="text-white font-bold">Retrait</div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Compte xbet</div>

            <div className="text-white font-bold"> {depotXbet} </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Numéro MoMo</div>

            <div className="text-white font-bold"> {storedNumero} </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Nom Numéro </div>

            <div className="text-white font-bold"> {storedNom} </div>
          </div>

          <div className="bg-white mt-4 h-[0.6px] "></div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Montant</div>

            <div className="text-white font-bold flex flex-row">
              <button className="text-#EFB034FF border border-[#EFB034FF] rounded-xl px-2 ml-1  ">
                En cours
              </button>
              <div className="ml-1"> {depotMontant} F CFA</div>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            navigate("/clients/menu-transaction");
          }}
          className="flex flex-row items-center justify-center mx-8"
        >
          {" "}
          <button className="text-white border mt-6 border-bleu rounded-lg px-2 bg-bleu w-full  font-albert  py-1 ">
            <FontAwesomeIcon icon={faSyncAlt} className=" ml-3" />
            <span> Mes transaction</span>
          </button>
        </div>
      </div>

      <div className="bg-black min-h-screen hidden flex-col  pt-24 md:flex px-44  ">
        <div className="mt-5 flex justify-center items-center  mx-72 ">
          {" "}
          <Lottie className="w-36  mb-2" animationData={bravo} loop={true} />
        </div>

        <div className="text-bleu font-albert text-center text-2xl">
          Demande envoyée !
        </div>

        <div className="text-white font-albert text-center ">
          Vous recevrez une notification une fois traitée. Merci ! ☺
        </div>

        <div className="bg-[#272728]  flex flex-col rounded-xl border border-[#333333] opacity-100  mt-1 py-2  mx-96">
          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">ID Payforbet</div>

            <div className="text-white font-bold"> {id} </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Type de transaction </div>

            <div className="text-white font-bold"> Retrait </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Compte 1xbet </div>

            <div className="text-white font-bold"> {depotXbet} </div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Numéro MoMo</div>

            <div className="text-white font-bold"> {storedNumero}</div>
          </div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Nom Numéro</div>

            <div className="text-white font-bold">{storedNom} </div>
          </div>

          <div className="bg-white mt-4 h-[0.6px] "></div>

          <div className="flex flex-row justify-between mx-4 mt-4 ">
            <div className="text-white  font-bold">Montant</div>

            <div className="text-white font-bold flex flex-row">
              <button className="text-#EFB034FF border border-[#EFB034FF] rounded-xl px-2 ml-1  ">
                En cours
              </button>
              <div className="ml-1"> {depotMontant} F CFA</div>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-center   mx-96">
          {" "}
          <button
            onClick={() => {
              navigate("/clients/menu-transaction");
            }}
            className="text-white border mt-6 border-bleu rounded-lg px-2 bg-bleu w-full  font-albert  py-1 "
          >
            <FontAwesomeIcon icon={faSyncAlt} className=" ml-3" />
            <span> Mes transaction</span>
          </button>
        </div>
      </div>
    </>
  );
}
