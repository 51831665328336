import React , {useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";

//import InfosContext from "../contexts/InfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";
const Index = () => {

    const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
  } = useContext(InfosContext);

      const navigate = useNavigate();
  const promoDetails = {
    codePromo: "GAW229",
    minimumRetraitAffiliation: "100",
    mode: "manuel",
    montant: "1,500 €, 2,500 €, 3,500,000,000 €",
    tauxAffiliationDepot: "5",
    tauxAffiliationRetrait: "10",
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white rounded-2xl shadow-2xl mt-10 border border-white">
      <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-6">Information</h2>
      <div className="bg-gray-50 rounded-xl p-6 mb-6 shadow-inner">
        <h3 className="text-xl font-semibold text-gray-700">Code Promo:</h3>
        <p className="text-3xl text-bleu font-extrabold">{infosAdmin.codePromo}</p>
      </div>
      <ul className="space-y-4">
        <li className="flex justify-between items-center">
          <span className="font-medium text-gray-600">Montant:</span>
          <span className="text-lg font-semibold text-gray-900">{infosAdmin.montant}</span>
        </li>
       
        <li className="flex justify-between items-center">
          <span className="font-medium text-gray-600">Mode:</span>
          <span className="text-lg font-semibold text-gray-900">{infosAdmin.mode}</span>
        </li>
        <li className="flex justify-between items-center">
          <span className="font-medium text-gray-600">Taux Affiliation Dépôt:</span>
          <span className="text-lg font-semibold text-gray-900">{infosAdmin.taux_affiliation_depot} %</span>
        </li>
       
      </ul>
      <button onClick={()=>{ navigate("/admin/configuration/edit")}} className="mt-8 w-full py-3 bg-bleu text-white text-lg font-bold rounded-full shadow-lg  transition duration-300">
        Configurer
      </button>
    </div>
  );
};

export default Index;
