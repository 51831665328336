import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ImEye } from "react-icons/im";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
//import { TransactionsContext } from '../../contexts/TransactionsContext';
//import { AgentContext } from '../../contexts/AddAgentContext';
import addCaisse from '../../requtes/admin/addCaisse';

import { ajouteEffectueCaisse } from '../alert/ajouteEffectueCaisse';

//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";


import  {avisbannir } from  '../alert/avisbannir';

import { updateStatutAgent } from './updateStatutAgent';
import InfosContext from "../../contexts/InfosAppProvider";
const ListAgents = () => {
   // const { listeUser, loading } = useContext(TransactionsContext);
   
   // const { addCaisse } = useContext("");

    const idUserv = localStorage.getItem("idUser");


    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [agentsPerPage, setAgentsPerPage] = useState(10);

       const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
     listeUser,
     token
   
  } = useContext(InfosContext);

 

    const inputHandler = (event) => {
        setSearchInput(event.target.value);
    };

    const handleAddCaisse = async (idAgent, nomAgent) => {

        
        const result = await addCaisse(idAgent,token);
        if (result.success) {
            //alert(`Caisse ajoutée avec succès pour l'agent ${ nomAgent}`);

            ajouteEffectueCaisse()
        } else {
            alert(`Erreur: ${result.message}`);
        }
        
    };

    // Filtrer les utilisateurs pour obtenir uniquement ceux avec le rôle "agent"
    const agentsArray = Object.values(listeUser).filter(user => user.role === "agent");

    // Filtrer les agents selon la recherche
    const filteredAgents = agentsArray.filter(agent =>
        agent.nom.toLowerCase().includes(searchInput.toLowerCase()) ||
        agent.email.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Pagination
    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    const currentAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);
    const totalAgents = filteredAgents.length;

    // Changer de page
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Handler pour changer le nombre d'agents par page
    const handleAgentsPerPageChange = (event) => {
        setAgentsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };
 
  //  if (loading) return <p>Loading Agents...</p>;

    return (
        <div className='m-5'>
            <h2 className='text-3xl text-bleu my-5'>Agents</h2>
            <div className='flex items-center justify-between bg-white rounded py-4 px-2'>
                <div>
                    <Link to={'/admin/addAgent'}>
                        <button className='p-2 rounded bg-bleu text-white m-5'>Ajouter un agent</button>
                    </Link>
                </div>
                <div>
                    <form className="" onSubmit={event => event.preventDefault()}>
                        <div className='sm:min-w-72 min-w-full p-2 border border-gray rounded flex items-center gap-2 bg-white dark:bg-[#171923]'>
                            <input
                                type="text"
                                placeholder="Rechercher"
                                required
                                onChange={inputHandler}
                                value={searchInput}
                                className="border-none outline-none bg-transparent"
                            />
                        </div>
                    </form>
                </div>
            </div>

            <div className='bg-white rounded mt-4'>
                <div className='flex items-center justify-between mx-5 border-b border-blue'>
                    <div className="flex gap-4 items-center py-2">
                        <div className="flex justify-between items-center">
                            <select
                                id="agentsPerPage"
                                value={agentsPerPage}
                                onChange={handleAgentsPerPageChange}
                                className="border border-gray rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                        >
                            <FaChevronLeft className={`text-blue ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                        <div className="text-center text-xs">
                            <span>{indexOfFirstAgent + 1} - {Math.min(indexOfLastAgent, totalAgents)} sur {totalAgents}</span>
                        </div>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastAgent >= totalAgents}
                        >
                            <FaChevronRight className={`text-blue ${indexOfLastAgent >= totalAgents ? 'opacity-50 cursor-not-allowed' : ''}`} />
                        </button>
                    </div>
                </div>

                {/* Tableau des agents */}
                <div className="bg-white text-dark">
                    <div className="overflow-y-auto overflow-x-auto max-h-[calc(100vh-150px)] bg-white dark:bg-[#171923]">
                        <table className="min-w-full bg-white">
                            <thead className='bg-white'>
                                <tr className='text-center font-medium text-sm border-b-[1px] border-gray bg-lightGray bg-opacity-0 dark:bg-opacity-10'>
                                    <th className="p-4">#</th>
                                    <th className="p-4">Pays</th>
                                    <th className="p-4">Nom</th>
                                    <th className="p-4">Email</th>
                                    <th className="p-4">WhatsApp</th>
                                    <th className="p-4">Statut</th>
                                    <th className="p-4">Solde total</th>
                                    <th className="p-4">update</th>
                                    <th className="p-4">Détails agents</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentAgents.map((agent, index) => (
                                    <tr key={index} className="border-b-[1px] border-gray">
                                        <td className="py-4 px-8">{indexOfFirstAgent + index + 1}</td>
                                        <td className="py-4 px-8">{agent.pays}</td>
                                        <td className="py-4 px-8">{agent.nom}</td>
                                        <td className="py-4 px-8">{agent.email}</td>
                                        <td className="py-4 px-8">{agent.whatsapp}</td>
                                        <td className='py-4 w-full text-center'>
                                            <span className={`p-2 rounded-xl ${agent.statut === 'actif' ? 'bg-green-200' : 'bg-red-200'}`}>{agent.statut}</span>
                                        </td>
                                        <td className="py-4 px-8">{agent.solde}</td>
                                        <td className="py-4 px-8">
                                            <button
    className={`border text-white p-2 rounded-2xl ${
        agent.statut === 'actif' ? 'bg-red-500' : 'bg-green-500'
    }`}
    onClick={() =>
        {
           // avisbannir()


            avisbannir().then((result) => {
  if (result.isConfirmed) {
    // L'utilisateur a cliqué sur "oui"
    console.log("Action confirmée : agent banni !");

    if( agent.statut == 'actif') {
        updateStatutAgent(agent.id,"inactif",token)
    }
    else{

        updateStatutAgent(agent.id,"actif",token)
    }

    //updateStatutAgent
    // Ton code de bannissement ici
  } else if (result.isDismissed) {
    // L'utilisateur a cliqué sur "non" ou a fermé la popup
    console.log("Action annulée : l'agent n'est pas banni.");
    // Ton code pour annuler l'action ici
  }
});

            /*
            agent.statut === 'actif'
            ? handleAddCaisse(agent.id, agent.nom) // Action de bannir
            : handleReintegrate(agent.id, agent.nom) // Action de réintégrer

            */
        }
    }
>
    {agent.statut === 'actif' ? 'Bannir' : 'Réintégrer'}
</button>

                                        </td>
                                        <td className="py-4 px-8">
                                            <Link to={`/admin/agentDetails/${agent.id}`} className='border border-blue flex flex-col items-center text-blue p-2 rounded-sm'>
                                                <ImEye />
                                                <span>Détails</span>
                                            </Link>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
         
        </div>
    );
};

export default ListAgents;
