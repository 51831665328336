import React, { useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

// Composants du layout
import Header from "../admin/components/Header";
import SideBar from "../admin/components/SideBar";

// Pages de l’administration
import Dashbord from "../admin/dashbord/Dashbord";
//import Transactions from "../admin/transactions/Transactions";

import IndexAdmin from "../admin/IndexAdmin";

import { AdminInfosAppProvider } from "../contexts/admin/AdminInfosAppProvider";

import ListTransactions from "../admin/transactions/ListTransactions";

import ListAgents from "../admin/agents/ListAgents";

import AgentDetails from "../admin/agents/AgentDetails";

import AddAgent from "../admin/agents/AddAgent";

import ListClients from "../admin/clients/ListClients";

import ClientDetails from "../admin/clients/ClientDetails";

import Index from "../admin/configuration/Index";

import PromoEditor from "../admin/configuration/PromoEditor";

import RetraitListe from "../admin/retraits/RetraitListe";

import RetraitDetail from "../admin/retraits/RetraitDetail";

// Layout principal avec Heade r et Sidebar
const AdminLayout = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className="w-full overflow-x-auto bg-lightGray">
      {/* Header toujours affiché */}
      <Header toggleSidebar={toggleSidebar} />

      <div className="flex">
        {/* Sidebar : affichée/masquée selon l’état */}
        <div
          className={`lg:w-72 ${isSidebarOpen ? "block" : "hidden"} lg:block`}
        >
          <SideBar />
        </div>

        {/* Contenu principal */}
        <div className="w-full p-4">
          {/* Outlet indique où afficher les routes enfants */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

// Routes de l'administration
const AdminRoutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <AdminInfosAppProvider className="admin-theme">
      <Routes>
        {/* Route principale avec AdminLayout */}
        <Route
          path="/"
          element={
            <AdminLayout
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          }
        >
          {/* Route par défaut : Dashboard */}
          <Route index element={<Dashbord />} />

          {/* Route vers la page Transactions */}
          <Route path="transactions" element={<ListTransactions />} />

          <Route path="listAgents" element={<ListAgents />} />

          <Route path="agentDetails/:id" element={<AgentDetails />} />

          <Route path="addAgent" element={<AddAgent />} />

          <Route path="listClients" element={<ListClients />} />

          <Route path="clientDetails/:id" element={<ClientDetails />} />

          <Route path="configuration" element={<Index />} />

          <Route path="configuration/edit" element={<PromoEditor />} />

          <Route path="liste-retrait" element={<RetraitListe />} />

          <Route path="retrait-detail/:id" element={<RetraitDetail />} />
        </Route>
      </Routes>
    </AdminInfosAppProvider>
  );
};

export default AdminRoutes;
