import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexVisiteur from "../visiteurs/IndexVisiteur";

import Login from "../visiteurs/Login";

import Verify from "../visiteurs/Verify";

import Authentification from "../visiteurs/Authentification";

import Signup from "../visiteurs/Signup";

import BottomNavBarAgent from "../agents/BottomNavBarAgent";

import IndexClient from "../clients/IndexClient";

import Accueil from "../clients/Accueil";

import IndexTransaction from "../agents/Transactions/IndexTransaction";

import Accepter from "../agents/Transactions/Accepter";

import AccepterRetrait from "../agents/Transactions/AccepterRetrait";

import DepotManuel from "../clients/depots/DepotManuel";

import DepotManuelSucces from "../clients/depots/DepotManuelSucces";

import RetraitManuel from "../clients/retraits/RetraitsManuel";

import AideIndex from "../clients/aides/AideIndex";
import Tarifs from "../clients/aides/Tarifs";

import IndexParametre from "../clients/parametre/IndexParametre";

import { InfosAppProvider } from "../contexts/InfosAppProvider";

const AgentsRoutes = () => {
  return (
    <>
      <InfosAppProvider>
        <Routes>
          <Route path="/" element={<IndexTransaction />} />

          <Route path="/accepter/:id/:idTran" element={<Accepter />} />

          <Route
            path="/Accepter-retrait/:id/:idTran"
            element={<AccepterRetrait />}
          />

          <Route path="/depot-manuel" element={<DepotManuel />} />

          <Route path="/depot-manuel-succes" element={<DepotManuelSucces />} />

          <Route path="/retrait-manuel" element={<RetraitManuel />} />

          <Route path="/tarifs" element={<Tarifs />} />

          <Route path="/setting" element={<IndexParametre />} />
        </Routes>
        <BottomNavBarAgent />
      </InfosAppProvider>
    </>
  );
};

export default AgentsRoutes;
