import React, { useContext, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
//import { TransactionsContext } from '../contexts/TransactionsContext';
import { Chart } from 'chart.js/auto';

import InfosContext from "../../contexts/InfosAppProvider";

const StatisticsChart = () => {
  //  const { transactions, listeUser } = useContext(TransactionsContext);


      const { listeUser, token ,transactions} = useContext(InfosContext);
    const [chartData, setChartData] = useState(null);
    const chartRef = useRef(null); // Référence pour le graphique

    useEffect(() => {
        // Calcul des statistiques si transactions et listeUser sont disponibles
        if (transactions && listeUser) {
            const numberOfUsers = Object.values(listeUser).length;
            const numberOfAgents = Object.values(listeUser).filter(user => user.role === 'agent').length;
            const numberOfClients = Object.values(listeUser).filter(user => user.role === 'client').length;
            const numberOfTransactions = Object.keys(transactions).length;
            const totalAmountToday = Object.values(transactions).reduce((total, transaction) => {
                const transactionDate = new Date(transaction.dateHeure);
                const today = new Date();
                if (
                    transactionDate.getDate() === today.getDate() &&
                    transactionDate.getMonth() === today.getMonth() &&
                    transactionDate.getFullYear() === today.getFullYear()
                ) {
                    return total + parseFloat(transaction.montant);
                }
                return total;
            }, 0);
            
            // Définir les données du graphique
            const data = {
                labels: ['Utilisateurs', 'Agents', 'Clients', 'Transactions', 'Montant Total Aujourd\'hui'],
                datasets: [
                    {
                        label: 'Statistiques',
                        data: [numberOfUsers, numberOfAgents, numberOfClients, numberOfTransactions, totalAmountToday],
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.6)', // Utilisateurs
                            'rgba(255, 159, 64, 0.6)', // Agents
                            'rgba(54, 162, 235, 0.6)', // Clients
                            'rgba(255, 99, 132, 0.6)', // Transactions
                            'rgba(255, 206, 86, 0.6)', // Montant Total Aujourd'hui
                        ],
                    },
                ],
            };

            setChartData(data);
        }
    }, [transactions, listeUser]);

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <h2>Statistiques</h2>
            {chartData ? (
                <Bar
                    ref={chartRef} // Assigner la ref au graphique
                    data={chartData}
                    options={options}
                />
            ) : (
                <p>Chargement des données...</p>
            )}
        </div>
    );
};

export default StatisticsChart;
