import checks from "../ressources/images/check.png";
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

//import { Link, useNavigate } from "react-router-dom";

import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import logo from "../ressources/images/logo.png";

//i; //mport Select from "react-select";

//import coin from "../animation/coin.svg";

import { ReactComponent as Coin } from "../animation/coin.svg";
import { existe } from "./alert/existe";

import { erreur } from "./alert/erreur";

import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Signup() {
  const navigate = useNavigate();
  const baseURL = "https://back.payforbet.com/api/";
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple
  const [finished, setFinished] = useState(false); // Etat pour indiquer si le processus est terminé

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refValue = queryParams.get("ref"); // récupère la valeur du paramètre 'ref'

  useEffect(() => {
    if (refValue) {
      // Sauvegarde dans localStorage
      localStorage.setItem("ref", refValue);
      //  alert("Value: " + refValue);
    }
  }, [refValue]);

  // Simule la fin du chargement après 3 secondes pour cet exemple

  /*
  setTimeout(() => {
    setLoading(false);
    setFinished(true);
  }, 3000);
  */

  const rotatingStyle = {
    animation: "spin 2s linear infinite", // Animation en continu (2 secondes)
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null); // Initialisé à null pour éviter des erreurs
  const selectRef = useRef(null); // Référence pour gérer les clics à l'extérieur
  const [menuIsOpen, setMenuIsOpen] = useState(false); // État pour gérer l'ouverture du menu

  const [phone, setPhone] = useState("");

  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    phone: "",
    email: "",
    xbetId: "",
    momoNumber: "",
    momoName: "",
  });

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        const defaultCountry = data.countries.find(
          (country) => country.value === "BJ"
        );
        setSelectedCountry(defaultCountry);
        setFormData((prevData) => ({
          ...prevData,
          country: defaultCountry?.value || "", // Mettre à jour le pays dans formData
        }));
      });
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setMenuIsOpen(false); // Fermer le menu si clic en dehors
    }
  };

  // Gestionnaire de clic en dehors
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Mettre à jour le champ spécifique dans formData
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Faire la requête API avec les données du formulaire
    let idParrain = "rien";

    const storedReff = localStorage.getItem("ref");
    if (storedReff) {
      // setSavedRef(storedRef); // Met à jour l'état avec la valeur récupérée
      idParrain = storedReff;
    }

    setLoading(true);

    /*
    alert(`
      Full Name: ${formData.fullName}
      Country: ${formData.country}
      Phone: ${formData.phone}
      Email: ${formData.email}
      Xbet ID: ${formData.xbetId}
      Momo Number: ${formData.momoNumber}
      Momo Name: ${formData.momoName}
    `);

    */
    const signupURL = `${baseURL}signup?email=${encodeURIComponent(
      formData.email
    )}&nom=${encodeURIComponent(formData.fullName)}&pays=${encodeURIComponent(
      formData.country
    )}&whatsapp=${encodeURIComponent(
      formData.phone
    )}&idParrain=${idParrain}&idCompte1xbet=${encodeURIComponent(
      formData.xbetId
    )}&numeroMomoRetrait=${encodeURIComponent(
      formData.momoNumber
    )}&nomnNmeroMomoRetrait=${encodeURIComponent(formData.momoName)}`;

    //nomnNmeroMomoRetrait

    fetch(signupURL, {
      method: "GET", // ou 'POST' selon ton API
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erreur serveur: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.error) {
          const result = existe(); // Afficher l'alerte et attendre la réponse
          if (result.isConfirmed) {
            // Logique pour "Continuer"
            console.log("Continuer action");
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Logique pour "Se connecter"
            console.log("Se connecter action");
          }
        } else {
          navigate("/login");
          //  const navigate = useNavigate();
          /*

          Swal.fire({
            title: "Succès!",
            text: "Utilisateur enregistré avec succès!",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              popup: "max-w-sm p-5", // Ajuste la taille et le padding
              confirmButton:
                "bg-green-500 text-white hover:bg-green-600 py-2 px-4 rounded", // Style du bouton
            },
          });
          */
        }
      })
      .catch((error) => {
        setLoading(false);
        const result = existe(); // Afficher l'alerte et attendre la réponse
        if (result.isConfirmed) {
          // Logique pour "Continuer"
          console.log("Continuer action");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Logique pour "Se connecter"
          console.log("Se connecter action");
        }
      });
    /*
    fetch("https://votre-api.com/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData), // Envoyer les données formData
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      */
  };

  return (
    <div className="bg-black  min-h-screen w-full flex flex-col pt-5 pb-5 overflow-x-hidden">
      <form className="md:hidden" onSubmit={handleSubmit}>
        <div className=" h-14 w-14 mx-32 rounded-2xl flex justify-center items-center">
          {/*
 <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "white", fontSize: "48px" }}
        />
        */}

          <img
            src={logo}
            alt="Première image"
            loading="lazy"
            className="w-2/3 h-96 object-contain"
          />
        </div>

        <div className="text-bleu  flex justify-center items-center font-albert text-2xl mt-2 ">
          Bienvenue sur Payforbet !
        </div>

        <div className="text-white  mt-5 flex justify-center items-center font-albert text-center">
          Veuillez compléter votre profil afin de commencer par faire vos
          opérations.
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Votre nom complet
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="fullName" // Ajouter un attribut 'name' pour lier à formData
              value={formData.fullName}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="text"
              placeholder="Nom et prénom"
            />
          </div>
        </div>
        <div className="flex flex-col mt-5" ref={selectRef}>
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px] text-white">
              Votre pays
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row items-center rounded-xl border border-[#333333] opacity-100 mx-5 mt-1 relative">
            <Select
              required
              options={countries}
              value={selectedCountry}
              onChange={(selectedOption) => {
                setSelectedCountry(selectedOption);
                setMenuIsOpen(false); // Fermer le menu après sélection
              }}
              isSearchable={true} // Activation de la recherche
              placeholder="Sélectionnez votre pays"
              menuIsOpen={menuIsOpen} // Contrôle manuel de l'ouverture du menu
              onMenuOpen={() => setMenuIsOpen(true)} // Ouvrir le menu
              onMenuClose={() => setMenuIsOpen(false)} // Fermer le menu quand nécessaire
              styles={{
                control: (base) => ({
                  ...base,
                  background: "#272728", // Fond identique à la div parente
                  border: "none", // Supprimer la bordure
                  boxShadow: "none", // Supprimer l'ombre
                  color: "white", // Couleur du texte
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999, // Assurer que le menu est au-dessus
                  position: "absolute", // Positionner le menu en absolu
                  top: "100%", // Aligné juste en dessous du champ
                  width: "100%",
                  minWidth: "200%",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "white",
                }),
                input: (provided) => ({
                  ...provided,
                  color: "white",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "black", // Couleur du texte dans la liste
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none", // Masquer la flèche par défaut
                }),
                indicatorSeparator: () => ({
                  display: "none", // Masquer le séparateur de l'indicateur
                }),
              }}
            />

            {/* Flèche unique avec FontAwesome */}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="absolute right-4 top-[18px] text-white cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Empêche le clic de se propager et de fermer le menu
                setMenuIsOpen(!menuIsOpen); // Basculer l'état d'ouverture du menu
              }}
            />
          </div>
        </div>

        <div className="flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px] text-white">
              Numéro WhatsApp
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <PhoneInput
              className="text-white"
              country={selectedCountry?.value || "bj"} // Utilise le code du pays sélectionné
              value={phone}
              onChange={(value) => {
                setPhone(value);

                setFormData((prevData) => ({
                  ...prevData,
                  phone: value,
                }));
              }}
              enableSearch={true} // Permet de rechercher un pays
              placeholder="62 86 15 71"
              disableDropdown={false} // Affiche la liste déroulante des pays
              inputStyle={{
                color: "white", // Change la couleur du texte ici
                fontSize: "16px", // Personnalisation supplémentaire
                width: "100%",
                background: "#272728",
                borderColor: "#272728",
              }}
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Votre Email
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="email" // Ajouter un attribut 'name' pour lier à formData
              value={formData.email}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="email"
              placeholder="email"
            />
          </div>
        </div>

        {/*
            email: "",
    xbetId: "",
    momoNumber: "",
    momoName: "",

          */}

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Id compte 1xbet par defaut
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="xbetId" // Ajouter un attribut 'name' pour lier à formData
              value={formData.xbetId}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="number"
              placeholder="ID 1xbet"
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Numéro MoMo paiement par défaut
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="momoNumber" // Ajouter un attribut 'name' pour lier à formData
              value={formData.momoNumber}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="number"
              placeholder="229...."
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Nom Numéro MoMo
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="momoName" // Ajouter un attribut 'name' pour lier à formData
              value={formData.momoName}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="text"
              placeholder=" Nom Numéro MoMo"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-bleu hidden w-[314px]  pr-[20px]  h-14  flex-row justify-center items-center text-white rounded-xl border border-[#333333] opacity-100 mx-5 mt-5"
        >
          <FontAwesomeIcon icon={faPaperPlane} className="mr-2 " />
          <Coin
            className="mr-2 w-5 hidden"
            style={{ fill: "blue", width: "20px", height: "20px" }}
          />{" "}
          {/* Remplace 'blue' par la couleur désirée */}
          <span className="text-white">Terminer</span>
        </button>

        <button
          type="submit"
          className="bg-bleu w-[314px] pr-[20px] h-14 flex flex-row justify-center items-center text-white rounded-xl border border-[#333333]  mx-5 mt-5"
        >
          {loading ? (
            // Barre de progression circulaire en rotation
            <div className="w-8 h-8 mr-2 animate-spin">
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "white",
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          )}

          <span className="text-white">
            {loading ? "Enregistrement..." : "Terminé"}
          </span>
        </button>
      </form>

      <form
        className="hidden  md:flex flex-col w-1/3  mx-auto items-center justify-center   rounded-xl border border-[#333333] "
        onSubmit={handleSubmit}
      >
        <div className="bg-bleu h-14 w-14 mx-auto rounded-2xl flex justify-center items-center mt-6">
          <img
            src={checks}
            alt="Première image"
            loading="lazy"
            className=" h-96 object-contain"
          />
        </div>

        <div className="text-bleu  flex justify-center items-center font-albert text-2xl mt-2 ">
          Bienvenue sur Payforbet !
        </div>

        <div className="text-white mx-20 mt-5 flex justify-center items-center font-albert text-center">
          Veuillez compléter votre profil afin de commencer par faire vos
          opérations.
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Votre nom complet
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              required
              name="fullName" // Ajouter un attribut 'name' pour lier à formData
              value={formData.fullName}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="text"
              placeholder="Nom et prénom"
            />
          </div>
        </div>
        <div className="flex flex-col  w-full mt-5  px-20 " ref={selectRef}>
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans  text-[15px] text-white">
              Votre pays
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className=" flex flex-row items-center rounded-xl border border-[#333333] opacity-100 mx-5 mt-1 relative">
            <Select
              options={countries}
              value={selectedCountry}
              onChange={(selectedOption) => {
                setSelectedCountry(selectedOption);
                setMenuIsOpen(false); // Fermer le menu après sélection
              }}
              isSearchable={true} // Activation de la recherche
              placeholder="Sélectionnez votre pays"
              menuIsOpen={menuIsOpen} // Contrôle manuel de l'ouverture du menu
              onMenuOpen={() => setMenuIsOpen(true)} // Ouvrir le menu
              onMenuClose={() => setMenuIsOpen(false)} // Fermer le menu quand nécessaire
              styles={{
                control: (base) => ({
                  ...base,
                  background: "#272728", // Fond identique à la div parente
                  border: "none", // Supprimer la bordure
                  boxShadow: "none", // Supprimer l'ombre
                  color: "white", // Couleur du texte
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999, // Assurer que le menu est au-dessus
                  position: "absolute", // Positionner le menu en absolu
                  top: "100%", // Aligné juste en dessous du champ
                  width: "100%",
                  minWidth: "200%",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "white",
                }),
                input: (provided) => ({
                  ...provided,
                  color: "white",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "black", // Couleur du texte dans la liste
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none", // Masquer la flèche par défaut
                }),
                indicatorSeparator: () => ({
                  display: "none", // Masquer le séparateur de l'indicateur
                }),
              }}
            />

            {/* Flèche unique avec FontAwesome */}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="absolute right-4 top-[18px] text-white cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Empêche le clic de se propager et de fermer le menu
                setMenuIsOpen(!menuIsOpen); // Basculer l'état d'ouverture du menu
              }}
            />
          </div>
        </div>

        <div className="flex flex-col mt-5 px-20 w-full">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px] text-white">
              Numéro WhatsApp
            </div>
            <div className="font-albert-sans text-[15px] text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <PhoneInput
              className="text-white"
              country={selectedCountry?.value || "bj"} // Utilise le code du pays sélectionné
              value={phone}
              onChange={(value) => {
                setPhone(value);

                setFormData((prevData) => ({
                  ...prevData,
                  phone: value,
                }));
              }}
              enableSearch={true} // Permet de rechercher un pays
              placeholder="62 86 15 71"
              disableDropdown={false} // Affiche la liste déroulante des pays
              inputStyle={{
                color: "white", // Change la couleur du texte ici
                fontSize: "16px", // Personnalisation supplémentaire
                width: "100%",
                background: "#272728",
                borderColor: "#272728",
              }}
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Votre Email
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              name="email" // Ajouter un attribut 'name' pour lier à formData
              value={formData.email}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="email"
              placeholder="email"
            />
          </div>
        </div>

        {/*
            email: "",
    xbetId: "",
    momoNumber: "",
    momoName: "",

          */}

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Id compte 1xbet par defaut
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              name="xbetId" // Ajouter un attribut 'name' pour lier à formData
              value={formData.xbetId}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="number"
              placeholder="ID 1xbet"
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Numéro MoMo paiement par défaut
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              name="momoNumber" // Ajouter un attribut 'name' pour lier à formData
              value={formData.momoNumber}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="number"
              placeholder="229...."
            />
          </div>
        </div>

        <div className=" flex flex-col mt-5">
          <div className="flex flex-row justify-between mx-7">
            <div className="font-albert-sans text-[15px]  text-white">
              Nom Numéro MoMo
            </div>
            <div className="font-albert-sans text-[15px]  text-white">
              requis
            </div>
          </div>

          <div className="bg-[#272728] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
            <input
              name="momoName" // Ajouter un attribut 'name' pour lier à formData
              value={formData.momoName}
              onChange={handleInputChange}
              className="w-[307px] h-[52px] pl-[52px] pr-[20px] font-albert-sans text-[18px] leading-[28px]  bg-transparent text-white outline-none"
              type="text"
              placeholder=" Nom Numéro MoMo"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-bleu hidden w-[314px]  pr-[20px]  h-14  flex-row justify-center items-center text-white rounded-xl border border-[#333333] opacity-100 mx-5 mt-5"
        >
          <FontAwesomeIcon icon={faPaperPlane} className="mr-2 " />
          <Coin
            className="mr-2 w-5 hidden"
            style={{ fill: "blue", width: "20px", height: "20px" }}
          />{" "}
          {/* Remplace 'blue' par la couleur désirée */}
          <span className="text-white">Terminer</span>
        </button>

        <button
          type="submit"
          className="bg-bleu w-[314px] pr-[20px] h-14 flex flex-row justify-center items-center text-white rounded-xl border border-[#333333]  mx-5 mt-5"
        >
          {loading ? (
            // Barre de progression circulaire en rotation
            <div className="w-8 h-8 mr-2 animate-spin">
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "white",
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          )}

          <span className="text-white">
            {loading ? "Enregistrement..." : "Terminé"}
          </span>
        </button>
      </form>
    </div>
  );
}
