import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
  faHomeAlt,
  faFilter,
  faFileContract,
  faFileSignature,
  faFilterCircleXmark,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowLeft } from "react-feather";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import "react-spring-bottom-sheet/dist/style.css"; // Assurez-vous que cela est présent

import TransactionCard from "../TransactionCard";

import Tabs from "./MyTabs";

import rond from "../../ressources/images/rond.png";

import checks from "../../ressources/images/main.png";

import InfosContext from "../../contexts/InfosAppProvider";
import Filtres from "./Filtres";

import { ReactComponent as Coin } from "../../animation/filter.svg";

export default function IndexTransaction() {
  const [vide, setVide] = useState(0);

  //const [open, setOpen] = useState(false);
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    selectedStatuses,
    removeStatus,
    addStatus,
    open,
    setOpen,
    searchTerm,
    setSearchTerm,
  } = useContext(InfosContext);

  useEffect(() => {
    //const idUserv = localStorage.getItem("idUser");

    //alert("id est ", idUserv);
    if (!transactions || Object.keys(transactions).length === 0) {
      setVide(true);
    } else {
      console.log("Transactions n'est pas vide.");
      //  alert("Transactions n'est pas vide." + Object.keys(transactions).length);
      setVide(false);
    }
  }, []);
  // Example condition to determine if the sheet can be dismissed
  const canDismiss = () => {
    // Replace with your own logic
    // For example, check if there's unsaved data
    return true; // Change to true when you want to allow dismiss
  };

  const handleDismiss = () => {
    if (canDismiss()) {
      setOpen(false); // Only close if the condition is met
    }
  };

  return (
    <>
      <div className="bg-black min-h-screen flex flex-col pt-5 md:hidden">
        {vide && (
          <div className="mt-20">
            <div className=" h-64 mt-5 mx-8 opacity-100 flex flex-col justify-center items-center text-center ">
              <div class="w-16 h-16 bg-[#333333] rounded-full flex justify-center items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M2.07 5.258C2 5.626 2 6.068 2 6.95V14c0 3.771 0 5.657 1.172 6.828S6.229 22 10 22h4c3.771 0 5.657 0 6.828-1.172S22 17.771 22 14v-2.202c0-2.632 0-3.949-.77-4.804a3 3 0 0 0-.224-.225C20.151 6 18.834 6 16.202 6h-.374c-1.153 0-1.73 0-2.268-.153a4 4 0 0 1-.848-.352C12.224 5.224 11.816 4.815 11 4l-.55-.55c-.274-.274-.41-.41-.554-.53a4 4 0 0 0-2.18-.903C7.53 2 7.336 2 6.95 2c-.883 0-1.324 0-1.692.07A4 4 0 0 0 2.07 5.257M13.713 16C14.977 16 16 15.052 16 13.882c0-.927-.643-1.714-1.538-2.001C14.335 10.823 13.366 10 12.191 10c-1.263 0-2.286.948-2.286 2.118c0 .258.05.506.142.735a2 2 0 0 0-.333-.03c-.946 0-1.714.712-1.714 1.589S8.768 16 9.714 16z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="text-center text-bleu text-2xl font-bold">
                Pas de transaction !
              </div>
              <div className="text-center text-white font-albert mx-2">
                Vous n'avez reçu aucune commande pour l'instant
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <button className="bg-bleu h-12 w-64 flex flex-row justify-center items-center text-center text-white rounded-xl border border-[#333333] opacity-100 mx-5">
                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                <span className="text-white">Recharger la page </span>
              </button>
            </div>
          </div>
        )}

        {!vide && (
          <>
            <div className="flex flex-col mt-6    ">
              <div className="bg-[#272728FF] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <span className=" pl-2  flex items-center  text-gray-500">
                  <FontAwesomeIcon icon={faSearch} />
                </span>

                <div className="basis-2/3 px-2">
                  <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="  h-[52px]   font-albert-sans text-[18px] leading-[28px] font-normal bg-transparent text-white outline-none"
                    type="text"
                    placeholder="Rechercher une transaction"
                  />
                </div>

                <div
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="basis-1/3 flex items-center  text-white "
                >
                  <Coin
                    className="mr-2 w-8 "
                    style={{ fill: "blue", width: "22px", height: "22px" }}
                  />{" "}
                </div>
              </div>

              <Tabs />

              <BottomSheet
                open={open}
                onDismiss={handleDismiss} // Use custom dismiss logic
                snapPoints={({ minHeight }) => [minHeight, 335]} // Minimum height of 450
              >
                <Filtres onClose={handleDismiss} />
              </BottomSheet>
            </div>
          </>
        )}
      </div>

      <div className="bg-black min-h-screen hidden flex-col pt-5 md:flex">
        {vide && (
          <div className="mt-20">
            <div className=" h-64 mt-5 mx-8 opacity-100 flex flex-col justify-center items-center text-center ">
              <div class="w-16 h-16 bg-[#333333] rounded-full flex justify-center items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="white"
                    fill-rule="evenodd"
                    d="M2.07 5.258C2 5.626 2 6.068 2 6.95V14c0 3.771 0 5.657 1.172 6.828S6.229 22 10 22h4c3.771 0 5.657 0 6.828-1.172S22 17.771 22 14v-2.202c0-2.632 0-3.949-.77-4.804a3 3 0 0 0-.224-.225C20.151 6 18.834 6 16.202 6h-.374c-1.153 0-1.73 0-2.268-.153a4 4 0 0 1-.848-.352C12.224 5.224 11.816 4.815 11 4l-.55-.55c-.274-.274-.41-.41-.554-.53a4 4 0 0 0-2.18-.903C7.53 2 7.336 2 6.95 2c-.883 0-1.324 0-1.692.07A4 4 0 0 0 2.07 5.257M13.713 16C14.977 16 16 15.052 16 13.882c0-.927-.643-1.714-1.538-2.001C14.335 10.823 13.366 10 12.191 10c-1.263 0-2.286.948-2.286 2.118c0 .258.05.506.142.735a2 2 0 0 0-.333-.03c-.946 0-1.714.712-1.714 1.589S8.768 16 9.714 16z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="text-center text-bleu text-2xl font-bold">
                Pas de transaction !
              </div>
              <div className="text-center text-white font-albert mx-2">
                Vous n'avez reçu aucune commande pour l'instant
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <button className="bg-bleu h-12 w-64 flex flex-row justify-center items-center text-center text-white rounded-xl border border-[#333333] opacity-100 mx-5">
                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                <span className="text-white">Recharger la page </span>
              </button>
            </div>
          </div>
        )}

        {!vide && (
          <>
            <div className="flex flex-col mt-6 mx-auto    ">
              <div className="bg-[#272728FF] flex flex-row rounded-xl border border-[#333333] opacity-100 mx-5 mt-1">
                <span className=" pl-2  flex items-center  text-gray-500">
                  <FontAwesomeIcon icon={faSearch} />
                </span>

                <div className="ml-2 ">
                  <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="  h-[52px] w-60   font-albert-sans text-[17px]  font-normal bg-transparent text-white outline-none"
                    type="text"
                    placeholder="Rechercher une transaction"
                  />
                </div>

                <div
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="basis-1/3 flex items-center  text-white  ml-4"
                >
                  <Coin
                    className="mr-2 w-8 "
                    style={{ fill: "blue", width: "22px", height: "22px" }}
                  />{" "}
                </div>
              </div>

              <Tabs />

              <BottomSheet
                open={open}
                onDismiss={handleDismiss} // Use custom dismiss logic
                snapPoints={({ minHeight }) => [minHeight, 335]} // Minimum height of 450
              >
                <Filtres onClose={handleDismiss} />
              </BottomSheet>
            </div>
          </>
        )}
      </div>
    </>
  );
}
