import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ImEye } from "react-icons/im";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

const RetraitListe = () => {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [retraitsPerPage, setRetraitsPerPage] = useState(10);

  const { allRetraitAffiliations } = useContext(InfosContext);

  const inputHandler = (event) => setSearchInput(event.target.value);

  // Convertir l'objet allRetraitAffiliations en tableau
  const retraitsArray = Object.values(allRetraitAffiliations);

  // Filtrer les retraits selon la recherche
  const filteredRetraits = retraitsArray.filter(
    (retrait) =>
      retrait.mode?.toLowerCase().includes(searchInput.toLowerCase()) ||
      retrait.statut?.toLowerCase().includes(searchInput.toLowerCase()) ||
      retrait.numeroRetrait?.toString().includes(searchInput) ||
      retrait.idUtilisateur?.toString().includes(searchInput)
  );

  // Pagination
  const indexOfLastRetrait = currentPage * retraitsPerPage;
  const indexOfFirstRetrait = indexOfLastRetrait - retraitsPerPage;
  const currentRetraits = filteredRetraits.slice(indexOfFirstRetrait, indexOfLastRetrait);

  const paginateNext = () => setCurrentPage((prev) => prev + 1);
  const paginatePrevious = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <div className="m-5">
      <h2 className="text-3xl text-bleu my-5">Liste des Retraits</h2>

      {/* Barre de recherche */}
      <div className="flex items-center justify-between bg-white rounded py-4 px-2">
        <input
          type="text"
          placeholder="Rechercher"
          value={searchInput}
          onChange={inputHandler}
          className="w-full p-2 border border-gray rounded"
        />
      </div>

      {/* Tableau des retraits */}
      <div className="bg-white rounded mt-4">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="text-center font-medium text-sm border-b">
              <th className="p-4">#</th>
              <th className="p-4">Date</th>
              <th className="p-4">Montant</th>
              <th className="p-4">Numéro de Retrait</th>
              <th className="p-4">ID Utilisateur</th>
              <th className="p-4">Mode</th>
              <th className="p-4">Statut</th>
              <th className="p-4">Détails</th>
            </tr>
          </thead>
          <tbody>
            {currentRetraits.map((retrait, index) => (
              <tr key={retrait.id} className="border-b text-center">
                <td className="p-4">{indexOfFirstRetrait + index + 1}</td>
                <td className="p-4">
                  {new Date(retrait.date).toLocaleDateString()}
                </td>
                <td className="p-4">{retrait.montant} FCFA</td>
                <td className="p-4">{retrait.numeroRetrait}</td>
                <td className="p-4">{retrait.idUtilisateur}</td>
                <td className="p-4">{retrait.mode}</td>
                <td className="p-4">
                  <span
                    className={`p-2 rounded ${
                      retrait.statut === "terminé"
                        ? "bg-green-200"
                        : "bg-yellow-200"
                    }`}
                  >
                    {retrait.statut}
                  </span>
                </td>
                <td className="p-4">
                  <Link
                    to={`/admin/retrait-detail/${retrait.idUtilisateur}`}
                    className="text-bleu flex items-center gap-2"
                  >
                    <ImEye />
                    <span>Détails</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex items-center justify-between p-4">
          <button onClick={paginatePrevious} disabled={currentPage === 1}>
            <FaChevronLeft />
          </button>
          <span>
            Page {currentPage}
          </span>
          <button
            onClick={paginateNext}
            disabled={indexOfLastRetrait >= filteredRetraits.length}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RetraitListe;
