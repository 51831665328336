import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import checks from "../ressources/images/check.png";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import sendCodeToEmail from "../requtes/visiteurs/sendCodeToEmail";
import authentification from "../requtes/visiteurs/authentification";

import { codeInvalide } from "./alert/codeInvalide";

import { Link, useNavigate } from "react-router-dom";

import InfosContext from "../contexts/InfosAppProvider";

import logo from "../ressources/images/logo.png";

const Authentification = () => {
  const navigate = useNavigate();
  const [inputFocused, setInputFocused] = useState(false);
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // État contenant le code
  const inputsRef = useRef([]);

  useEffect(() => {
    const completeCode = otp.join(""); // Joindre les chiffres pour obtenir le code complet
    if (completeCode.length === 6) {
      console.log("Code complet saisi:", completeCode); // Exemple d'action
      // Vous pouvez appeler une fonction pour valider le code ici
      //alert(completeCode);
      setValide(true);
    } else {
      setValide(false);
    }
  }, [otp]);

  // Gère le changement de chaque input
  const handleChangepc = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp); // Met à jour l'état avec le nouveau tableau

      if (value && index < 5) {
        inputsRef.current[index + 1].focus(); // Passe au champ suivant si un chiffre est saisi
      }
    }
  };

  // Gère le collage de l'ensemble du code
  const handlePastepc = (event) => {
    const pastedData = event.clipboardData.getData("text").trim();
    if (/^\d{6}$/.test(pastedData)) {
      // Vérifie que le code est bien 6 chiffres
      const newOtp = pastedData.split("");
      setOtp(newOtp); // Met à jour l'état avec les nouveaux chiffres

      // Place le focus sur le dernier champ rempli
      inputsRef.current[5].focus();
    }
  };

  const handleKeyDownpc = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus(); // Retour au champ précédent
    }
  };

  const email = localStorage.getItem("userEmail");

  const idUser = localStorage.getItem("idUser");

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    setListeUser,
    listeCaisses,
    setListeCaisses,
    allRetraitAffiliations,
    setAllRetraitAffiliations,
  } = useContext(InfosContext);

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  // État pour stocker la chaîne concaténée
  const [concatenatedValue, setConcatenatedValue] = useState("");

  const [valide, setValide] = useState(false);

  useEffect(() => {
    // Vérification si concatenatedValue contient exactement 6 chiffres
    const isSixDigits = /^\d{6}$/.test(concatenatedValue);

    if (isSixDigits) {
      console.log("concatenatedValue contient exactement six chiffres.");
      setValide(true);
    } else {
      console.log("concatenatedValue ne contient pas exactement six chiffres.");
      setValide(false);
    }
  }, [concatenatedValue]); // Déclenchement à chaque changement de concatenatedValue

  // Gestion de la saisie d'un chiffre et déplacement vers l'input suivant
  const handleInputChange = (value, index) => {
    if (value.length === 1) {
      // Met à jour la valeur de l'input actuel
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);

      // Met à jour la valeur concaténée
      setConcatenatedValue(newInputValues.join(""));

      // Déplace le focus vers l'input suivant s'il existe
      if (index < inputValues.length - 1) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const [minutes, setMinutes] = useState(3); // Par exemple 1 minute
  const [seconds, setSeconds] = useState(0); // Par exemple 30 secondes
  const [isTimeUp, setIsTimeUp] = useState(false); // Pour savoir si le temps est écoulé

  useEffect(() => {
    // Décrémente le compteur chaque seconde
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(timer);
        setIsTimeUp(true); // Le temps est écoulé
      }
    }, 1000);

    // Nettoie l'intervalle à la fin
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  /*
  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("text").trim();
    if (/^\d{6}$/.test(pastedData)) {
      // Vérifie que le code est bien 6 chiffres
      const newOtp = pastedData.split("");
      //  setOtp(newOtp); // Met à jour l'état avec les nouveaux chiffres
      setInputValues(newOtp);
      // Place le focus sur le dernier champ rempli
      inputsRef.current[5].focus();
    }
  };
  */

  // Gestion de la suppression pour déplacer le focus vers l'input précédent
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newInputValues = [...inputValues];
      // Si l'input actuel est vide, aller à l'input précédent
      if (inputValues[index] === "" && index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      } else {
        // Sinon, on supprime la valeur de l'input actuel
        newInputValues[index] = "";
        setInputValues(newInputValues);
        setConcatenatedValue(newInputValues.join(""));
      }
    }
  };
  const handlePaste = (e, startIndex) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, inputValues.length - startIndex);

    // Répartir les chiffres collés dans les champs d'entrée
    const updatedValues = [...inputValues];
    for (let i = 0; i < pasteData.length; i++) {
      updatedValues[startIndex + i] = pasteData[i];
    }

    setInputValues(updatedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Faire la requête API avec les données du formulaire
    setLoading(true);

    if (isTimeUp) {
      alert("hi yes");
      sendCodeToEmail(email)
        .then((result) => {
          if (result) {
            console.log("Result:", result);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      //alert("hi no");
      //  const response = await authentification(idUser, concatenatedValue);
      //  alert("hi");

      authentification(idUser, concatenatedValue)
        .then(async (result) => {
          setLoading(false);
          if (result == null) {
            // alert("hi null");
            const repondre = await codeInvalide();
          } else {
            if (result.error) {
              const repondre = await codeInvalide();
            } else {
              //  alert(result.token);
              //  alert(result.data.userInfos);
              //   alert(JSON.stringify(result.data.userInfos, null, 2));

              localStorage.setItem("token", result.token);

              localStorage.setItem("isAuthenticated", 1);

              //InfosContext
              const role = result.data.userInfos.role;
              if (role == "client") {
                setInfosAdmin(result.data.infosAdmin);
                setUserInfos(result.data.userInfos);

                setTransactions(result.data.transactions);

                navigate("/clients/espace");
              }

              if (role == "agent") {
                navigate("/agents/");
              }
              if (role == "admin") {
                setInfosAdmin(result.data.infosAdmin);
                setUserInfos(result.data.userInfos);

                setTransactions(result.data.transactions);

                setListeUser(result.data.listeUser);

                setListeCaisses(result.data.listeCaisses);

                setAllRetraitAffiliations(result.data.allRetraitAffiliations);

                //  const [listeUser, setListeUser] = useState(null);

                //  const [listeCaisses, setListeCaisses] = useState(null);

                /*  const [allRetraitAffiliations, setAllRetraitAffiliations] =
                  useState(null);
                  */

                // navigate("/clients/espace");
                navigate("/admin/");
              }

              //navigate("/clients/espace");
            }
          }
        })
        .catch((error) => {
          //  alert("hi");
          const repondre = codeInvalide();
          console.error("An error occurred:", error);
        });
    }

    //handleLogin();
  };

  const handleSubmitpc = async (e) => {
    e.preventDefault();
    // Faire la requête API avec les données du formulaire
    setLoading(true);

    if (isTimeUp) {
      //   alert("hi yes");
      sendCodeToEmail(email)
        .then((result) => {
          if (result) {
            console.log("Result:", result);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      //alert("hi no");
      //  const response = await authentification(idUser, concatenatedValue);
      //  alert("hi");
      const completeCode = otp.join("");

      authentification(idUser, completeCode)
        .then(async (result) => {
          setLoading(false);
          if (result == null) {
            // alert("hi null");
            const repondre = await codeInvalide();
          } else {
            if (result.error) {
              const repondre = await codeInvalide();
            } else {
              //  alert(result.token);
              //  alert(result.data.userInfos);
              //   alert(JSON.stringify(result.data.userInfos, null, 2));

              localStorage.setItem("token", result.token);

              localStorage.setItem("isAuthenticated", 1);

              //InfosContext
              const role = result.data.userInfos.role;
              if (role == "client") {
                /*
                setInfosAdmin(result.data.infosAdmin);
                setUserInfos(result.data.userInfos);

                setTransactions(result.data.transactions);

*/

                setInfosAdmin(result.data.infosAdmin);
                setUserInfos(result.data.userInfos);

                //  setTransactions(response.infos.transactions);
                setListeUser(result.data.listeUser);

                let transformedData = result.data.transactions;

                let reversedTransactions = {};
                let keys = Object.keys(transformedData).reverse();

                keys.forEach((key) => {
                  reversedTransactions[key] = transformedData[key];
                });

                setTransactions(reversedTransactions);

                navigate("/clients/espace");
              }

              if (role == "agent") {
                setUserInfos(result.data.userInfos);

                setListeUser(result.data.listeUser);

                let transformedData = {};

                Object.keys(result.data.transactions).forEach((idCaisse) => {
                  result.data.transactions[idCaisse].forEach((transaction) => {
                    const idTransaction = transaction.idTransaction;
                    transformedData[idTransaction] = { ...transaction };
                  });
                });

                let reversedTransactions = {};
                let keys = Object.keys(transformedData).reverse();

                keys.forEach((key) => {
                  reversedTransactions[key] = transformedData[key];
                });

                setTransactions(reversedTransactions);

                navigate("/agents/");
              }
              if (role == "admin") {
                setInfosAdmin(result.data.infosAdmin);
                setUserInfos(result.data.userInfos);

                setTransactions(result.data.transactions);

                setListeUser(result.data.listeUser);

                setListeCaisses(result.data.listeCaisses);

                setAllRetraitAffiliations(result.data.allRetraitAffiliations);

                //  const [listeUser, setListeUser] = useState(null);

                //  const [listeCaisses, setListeCaisses] = useState(null);

                /*  const [allRetraitAffiliations, setAllRetraitAffiliations] =
                  useState(null);
                  */

                // navigate("/clients/espace");
                navigate("/admin/");
              }

              //navigate("/clients/espace");
            }
          }
        })
        .catch((error) => {
          //  alert("hi");
          const repondre = codeInvalide();
          console.error("An error occurred:", error);
        });
    }

    //handleLogin();
  };

  return (
    <div
      className={`bg-black min-h-screen flex flex-col pt-20 ${
        inputFocused ? "keyboard-open" : ""
      }`}
    >
      <form className="md:hidden" onSubmit={handleSubmit}>
        <div className="bg-bleu h-24 w-24 mx-28 rounded-3xl flex justify-center items-center">
          <img
            src={logo}
            alt="Première image"
            loading="lazy"
            className="w-2/3 h-96 object-contain"
          />
        </div>

        <div className="text-white mx-12 mt-5 flex justify-center items-center font-albert text-center">
          Entrez le code de vérification à 6 chiffres envoyé par mail. Vérifiez
          aussi votre dossier Spams.
        </div>

        <div
          className={`mx-12 mt-2 flex justify-center items-center font-bold text-center ${
            isTimeUp ? "text-red-600" : "text-vert"
          }`}
        >
          {/* Affiche "Temps expiré" si le temps est écoulé */}
          {isTimeUp
            ? "Temps expiré"
            : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
        </div>

        <div className="flex flex-row space-x-3 mt-6 justify-center">
          {inputValues.map((value, index) => (
            <div
              key={index}
              className="bg-[#272728] flex flex-row border-[#333333] opacity-100"
            >
              <input
                required
                id={`input-${index}`} // Identifiant unique pour chaque input
                className="w-10 h-10 font-albert-sans leading-[28px] text-center bg-transparent text-white outline-none font-bold"
                type="number"
                pattern="\d*"
                value={value}
                maxLength={1} // Limite à un seul chiffre
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)} // Gère le déplacement avec la touche backspace
                //   onPaste={handlePaste}

                onPaste={(e) => handlePaste(e, index)} // Gère l'événement de collage
              />
            </div>
          ))}
        </div>

        {isTimeUp && (
          <button
            type="button"
            onClick={() => {
              setLoading(true);

              sendCodeToEmail(email)
                .then((result) => {
                  if (result) {
                    console.log("Result:", result);
                    setLoading(false);
                    /*const [minutes, setMinutes] = useState(1); // Par exemple 1 minute
  const [seconds, setSeconds] = useState(0); // Par exemple 30 secondes


                    */
                    setMinutes(1);
                    setIsTimeUp(false);
                  } else {
                    setLoading(false);
                    setMinutes(1);
                    setIsTimeUp(false);
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setMinutes(1);
                  setIsTimeUp(false);
                  console.error("An error occurred:", error);
                });
            }}
            className={`bg-bleu h-14 w-[314px]  flex flex-row justify-center items-center text-white rounded-xl border border-[#333333] mx-5 mt-5 ${
              !valide && !isTimeUp && !loading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            // disabled={!valide && !isTimeUp}
          >
            {loading ? (
              "" // You can replace this with a loading spinner or something else if needed
            ) : isTimeUp ? (
              <>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span className="text-white">Renvoyer le code</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "white", fontSize: "18px" }}
                  className="mr-2"
                />
                <span className="text-white">Valider</span>
              </>
            )}

            {loading ? (
              // Barre de progression circulaire en rotation
              <div className="w-8 h-8 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "transparent",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              ""
            )}

            <span className="text-white">{loading ? "En cours ...." : ""}</span>
          </button>
        )}

        {!isTimeUp && (
          <button
            type="submit"
            className={`bg-bleu h-14 w-[314px]  flex flex-row justify-center items-center text-white rounded-xl border border-[#333333] mx-5 mt-5 ${
              !valide && !isTimeUp && !loading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            // disabled={!valide && !isTimeUp}
          >
            {loading ? (
              "" // You can replace this with a loading spinner or something else if needed
            ) : isTimeUp ? (
              <>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span className="text-white">Renvoyer le code</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "white", fontSize: "18px" }}
                  className="mr-2"
                />
                <span className="text-white">Valider</span>
              </>
            )}

            {loading ? (
              // Barre de progression circulaire en rotation
              <div className="w-8 h-8 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "transparent",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              ""
            )}

            <span className="text-white">
              {loading ? "Vérification..." : ""}
            </span>
          </button>
        )}
      </form>

      <form
        className="hidden md:flex flex-col w-1/3    mx-auto items-center justify-center   rounded-xl border border-[#333333] py-10"
        onSubmit={handleSubmitpc}
      >
        <div className=" h-24 w-24 mx-28 rounded-3xl flex justify-center items-center">
          <img
            src={logo}
            alt="Première image"
            loading="lazy"
            className="w-2/3 h-96 object-contain"
          />
        </div>

        <div className="text-white mx-12 mt-5 flex justify-center items-center font-albert text-center">
          Entrez le code de vérification à 6 chiffres envoyé par mail. Vérifiez
          aussi votre dossier Spams.
        </div>

        <div
          className={`mx-12 mt-2 flex justify-center items-center font-bold text-center ${
            isTimeUp ? "text-red-600" : "text-vert"
          }`}
        >
          {/* Affiche "Temps expiré" si le temps est écoulé */}
          {isTimeUp
            ? "Temps expiré"
            : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
        </div>

        <div className="flex flex-row space-x-3 mt-6 justify-center">
          <div className="flex flex-row space-x-4">
            {otp.map((value, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleChangepc(index, e.target.value)}
                onKeyDown={(e) => handleKeyDownpc(index, e)}
                onPaste={handlePastepc}
                ref={(el) => (inputsRef.current[index] = el)}
                className="w-10 h-10 font-[Albert-Sans] leading-[28px] text-center 
                 bg-[#333333FF] text-white outline-none font-bold border 
                 border-[#333333] rounded-md"
              />
            ))}
          </div>
        </div>

        {isTimeUp && (
          <button
            type="button"
            onClick={() => {
              setLoading(true);

              sendCodeToEmail(email)
                .then((result) => {
                  if (result) {
                    console.log("Result:", result);
                    setLoading(false);
                    /*const [minutes, setMinutes] = useState(1); // Par exemple 1 minute
  const [seconds, setSeconds] = useState(0); // Par exemple 30 secondes


                    */
                    setMinutes(1);
                    setIsTimeUp(false);
                  } else {
                    setLoading(false);
                    setMinutes(1);
                    setIsTimeUp(false);
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setMinutes(1);
                  setIsTimeUp(false);
                  console.error("An error occurred:", error);
                });
            }}
            className={`bg-bleu h-14 w-[314px]  flex flex-row justify-center items-center text-white rounded-xl border border-[#333333] mx-5 mt-5 ${
              !valide && !isTimeUp && !loading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            // disabled={!valide && !isTimeUp}
          >
            {loading ? (
              "" // You can replace this with a loading spinner or something else if needed
            ) : isTimeUp ? (
              <>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span className="text-white">Renvoyer le code</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "white", fontSize: "18px" }}
                  className="mr-2"
                />
                <span className="text-white">Valider</span>
              </>
            )}

            {loading ? (
              // Barre de progression circulaire en rotation
              <div className="w-8 h-8 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "transparent",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              ""
            )}

            <span className="text-white">{loading ? "En cours ...." : ""}</span>
          </button>
        )}

        {!isTimeUp && (
          <button
            type="submit"
            className={`bg-bleu h-14 w-[314px]  flex flex-row justify-center items-center text-white rounded-xl border border-[#333333] mx-5 mt-5 ${
              !valide && !isTimeUp && !loading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            // disabled={!valide && !isTimeUp}
          >
            {loading ? (
              "" // You can replace this with a loading spinner or something else if needed
            ) : isTimeUp ? (
              <>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span className="text-white">Renvoyer le code</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "white", fontSize: "18px" }}
                  className="mr-2"
                />
                <span className="text-white">Valider</span>
              </>
            )}

            {loading ? (
              // Barre de progression circulaire en rotation
              <div className="w-8 h-8 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "transparent",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              ""
            )}

            <span className="text-white">
              {loading ? "Vérification..." : ""}
            </span>
          </button>
        )}
      </form>
    </div>
  );
};

export default Authentification;
