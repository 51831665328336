import React from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import Button from './Button';
import { FiHome, FiDollarSign, FiUsers, FiUserCheck, FiTrendingDown,FiSettings } from 'react-icons/fi'; // import des icônes
import { FaSignOutAlt } from 'react-icons/fa';
const SideBar = ({ isSidebarOpen }) => {
    const navigate = useNavigate(); // Utilisation de useNavigate
  return (
    <aside className={`h-screen z-40 w-72 p-3 border-r border-gray bg-white text-dark transition-transform lg:translate-x-0 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}>
      <div className='flex gap-2 bg-lightBlue w-full px-4 py-2 mb-5 rounded-xl'>
        <div className='flex flex-col text-darkBlue'>
          <h1 className='text-sm font-bold'>Espace Admin</h1>
          <h2 className='text-xs'></h2>
        </div>
      </div>
      <div>
        <h2 className='text-darkGray mb-4 font-medium text-base'>
          {
            //Menu
          }
        </h2>
        <div className='space-y-4'>
          <Link to={'/admin'}>
            <Button title={"Tableau de bord"} icon={<FiHome />} />
          </Link>
          <Link to={'/admin/transactions'}>
            <Button title={"Transactions"} icon={<FiDollarSign />} />
          </Link>
          <Link to={'/admin/listAgents'}>
            <Button title={"Agents"} icon={<FiUsers />} />
          </Link>
          <Link to={'/admin/listClients'}>
            <Button title={"Clients"} icon={<FiUserCheck />} />
          </Link>
          <Link to={'/admin/liste-retrait'}>
            <Button title={"Retraits par affiliation"} icon={<FiTrendingDown />} />
          </Link>

           <Link to={'/admin/configuration'}>
            <Button title={"Configuration"} icon={<FiSettings />} />
          </Link>


           <div  onClick={() => {
              localStorage.setItem("token", "");

              localStorage.setItem("isAuthenticated", 0);
              navigate("/login");
            }}>
            <Button title={"Déconnexion"} icon={< FaSignOutAlt />} />
          </div>
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
