import React, { useContext } from "react";
import {
  FaPhoneAlt,
  FaMoneyBillWave,
  FaDice,
  FaWhatsapp,
  FaEdit,
  FaUser,
  FaEnvelope,
  FaSignOutAlt,
} from "react-icons/fa";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import InfosContext from "../../contexts/InfosAppProvider";
const IndexParametre = () => {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);
  const navigate = useNavigate(); // Utilisation de useNavigate
  return (
    <>
      <div className="min-h-screen bg-black flex pt-3 px-2 md:hidden">
        <div className=" rounded-3xl shadow-2xl max-w-3xl w-full ">
          <h2 className="text-xl font-extrabold text-center text-white mb-6 tracking-wider">
            Informations personnelles
          </h2>

          <div className="space-y-2">
            {/* Nom */}
            <InfoRow
              icon={<FaUser />}
              label="Nom"
              value={userInfos.nom}
              iconColor="text-green-500"
            />

            <InfoRow
              icon={<FaEnvelope />}
              label="Email"
              value={userInfos.email}
              iconColor="text-green-500"
            />

            {/* Numéro de Réception Mobile Money */}
            <InfoRow
              icon={<FaMoneyBillWave />}
              label="Numéro MoMo"
              value={userInfos.numeroMomo}
              iconColor="text-red-500"
            />

            {/* Numéro WhatsApp */}
            <InfoRow
              icon={<FaWhatsapp />}
              label="Numéro WhatsApp"
              value={userInfos.whatsapp}
              iconColor="text-green-400"
            />

            {/* ID 1xBet */}
            <InfoRow
              icon={<FaDice />}
              label="ID 1xBet"
              value={userInfos.idCompte1xbet}
              iconColor="text-purple-500"
            />

            {/* Nouvel élément : Nom Numéro Mobile Money */}
            <InfoRow
              icon={<FaMoneyBillWave />}
              label="Nom  Momo"
              value={userInfos.nomMomo}
              iconColor="text-yellow-500"
            />
          </div>

          {/* Bouton d'Édition avec Icône */}
          <div className="mt-6 flex justify-center">
            <button
              className="bg-bleu hover:bg-bleu text-white font-semibold py-3 px-8 rounded-full shadow-md flex items-center gap-3 transition-all duration-300 w-full  mx-4" // Ajouter w-full et max-w-xs
              onClick={
                () => {
                  let a = 8;
                }
                //alert("Mode Édition activé")
              }
            >
              <FaEdit className="h-5 w-5" /> Éditer les Informations
            </button>
          </div>

          <div className="mt-2 flex justify-center">
            <button
              className="bg-red-700  text-white font-semibold py-3 px-14 rounded-full shadow-md flex items-center gap-3 transition-all duration-300 w-full mx-4" // Ajouter w-full et max-w-xs
              onClick={() => {
                localStorage.setItem("token", "");

                localStorage.setItem("isAuthenticated", 0);
                navigate("/login");
              }}
            >
              <FaSignOutAlt className="h-5 w-5" /> Se Déconnecter
            </button>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-black  pt-3 hidden md:flex  justify-center  ">
        <div className="bg-black rounded-3xl shadow-2xl max-w-3xl w-full md:mt-24 ">
          <h2 className="text-xl font-extrabold text-center text-white mb-6 tracking-wider">
            Informations personnelles
          </h2>

          <div className="space-y-2">
            {/* Nom */}
            <InfoRow
              icon={<FaUser />}
              label="Nom"
              value={userInfos.nom}
              iconColor="text-green-500"
            />

            <InfoRow
              icon={<FaEnvelope />}
              label="Email"
              value={userInfos.email}
              iconColor="text-green-500"
            />

            {/* Numéro de Réception Mobile Money */}
            <InfoRow
              icon={<FaMoneyBillWave />}
              label="Numéro MoMo"
              value={userInfos.numeroMomo}
              iconColor="text-red-500"
            />

            {/* Numéro WhatsApp */}
            <InfoRow
              icon={<FaWhatsapp />}
              label="Numéro WhatsApp"
              value={userInfos.whatsapp}
              iconColor="text-green-400"
            />

            {/* ID 1xBet */}
            <InfoRow
              icon={<FaDice />}
              label="ID 1xBet"
              value={userInfos.idCompte1xbet}
              iconColor="text-purple-500"
            />

            {/* Nouvel élément : Nom Numéro Mobile Money */}
            <InfoRow
              icon={<FaMoneyBillWave />}
              label="Nom  Momo"
              value={userInfos.nomMomo}
              iconColor="text-yellow-500"
            />
          </div>

          {/* Bouton d'Édition avec Icône */}
          <div className="mt-6 flex justify-center">
            <button
              className="bg-bleu hover:bg-bleu text-white font-semibold py-3 px-8 rounded-full shadow-md flex items-center gap-3 transition-all duration-300 w-full md:w-1/2  md:px-20  mx-4" // Ajouter w-full et max-w-xs
              onClick={
                () => {
                  let a = 88;
                }
                // alert("Mode Édition activé")
              }
            >
              <FaEdit className="h-5 w-5" /> Éditer les Informations
            </button>
          </div>

          <div className="mt-2 flex justify-center">
            <button
              className="bg-red-700  text-white font-semibold py-3 px-14 rounded-full shadow-md flex items-center gap-3 transition-all duration-300 w-full md:w-1/2  md:px-20 mx-4" // Ajouter w-full et max-w-xs
              onClick={() => {
                localStorage.setItem("token", "");

                localStorage.setItem("isAuthenticated", 0);
                navigate("/login");
              }}
            >
              <FaSignOutAlt className="h-5 w-5" /> Se Déconnecter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

// Composant InfoRow : Disposition en flex row
const InfoRow = ({ icon, label, value, iconColor }) => (
  <div className="flex items-center justify-between border-b border-gray-700 pb-4 px-3 md:px-6">
    <div className="flex items-center">
      <div
        className={`w-12 h-12 flex items-center justify-center bg-black rounded-xl shadow-md ${iconColor}`}
      >
        {icon}
      </div>
      <p className="ml-4 text-sm font-medium text-white uppercase">{label}</p>
    </div>
    <p className="text-sm font-semibold text-white">{value}</p>
  </div>
);

export default IndexParametre;
