import { Tabs, Tab, Box } from "@mui/material";
//import { useState } from "react";

import TransactionCard from "../TransactionCard";

import InfosContext from "../../contexts/InfosAppProvider";

import PaginatedTransactions from "./PaginatedTransactions";

import DepositsWithPagination from "./DepositsWithPagination";

import RetraitsWithPagination from "./RetraitsWithPagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowLeft } from "react-feather";
import ReactPaginate from "react-paginate";

import React, { useState, useEffect, useContext } from "react";
const MyTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
  } = useContext(InfosContext);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const truncateRef = (ref) => {
    return ref.length > 8 ? ref.substring(0, 8) + "..." : ref;
  };

  // Définir les contenus des onglets
  const tabContents = [
    { title: "Toutes", content: "Bienvenue sur la page d'accueil!" },
    { title: "Dépots", content: "Découvrez nos services de dépôt." },
    { title: "Retraits", content: "Découvrez nos services de retrait." },
  ];

  return (
    <div className="mt-5 mb-16  ">
      {/* Onglets défilables */}
      <Tabs
        className="bg-bleu rounded-lg md:mx-72 mx-6 px-4"
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          borderBottom: "0px", // Aucune bordure au bas des onglets
          borderColor: "white",
        }}
      >
        {tabContents.map((tab, index) => (
          <Tab
            className="text-black "
            key={index}
            label={tab.title}
            sx={{
              color: activeTab === index ? "white" : "white", // Couleur du texte
              backgroundColor: activeTab === index ? "white" : "transparent", // Couleur de fond de l'onglet actif
              borderRadius: "10px", // Arrondi des coins
              borderBottom:
                activeTab === index ? " solid 12px #0087BFFF" : "none", // Bordure rouge pour l'onglet actif
              borderColor: activeTab === index ? "#0087BFFF" : "transparent", // Couleur de la bordure
              borderStyle: activeTab === index ? "solid  #0087BFFF" : "none", // Style de la bordure
              // Vous pouvez retirer ces lignes si elles ne sont pas nécessaires
              borderWidth: activeTab === index ? "2px" : "0px",
              // borderBlockEndWidth: "0px", // Cette propriété n'est pas nécessaire
            }}
          />
        ))}
      </Tabs>

      <div className="text-white border-none ">
        {tabContents[activeTab].title === "Toutes" && (
          <>
            <PaginatedTransactions
              transactions={transactions}
              itemsPerPage={5}
            />
          </>
        )}

        {tabContents[activeTab].title === "Dépots" && (
          <>
            <DepositsWithPagination
              transactions={transactions}
              itemsPerPage={5}
            />
          </>
        )}

        {tabContents[activeTab].title === "Retraits" && (
          <>
            <RetraitsWithPagination
              transactions={transactions}
              itemsPerPage={5}
            />
          </>
        )}

        {/*
          <h2>{tabContents[activeTab].title}</h2>
        <p>{tabContents[activeTab].content}</p>
          */}
      </div>
    </div>
  );
};

export default MyTabs;
