import React, { useState ,useContext    } from 'react';

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfosContextAdmin from "../../contexts/InfosAppProvider";


import Swal from "sweetalert2";




import { ajoutAgentSucces } from '../alert/ajoutAgentSucces';

import  {existeAgent } from  '../alert/existeAgent';

const PromoEditore = ({ promoDetails, onSave }) => {
  const [formValues, setFormValues] = useState(promoDetails);
  const [loading, setLoading] = useState(false); // État pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


 const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        listeCaisses,
        token
    } = useContext(InfosContextAdmin);


const sendRequest = async () => {
  const baseURL = "https://back.payforbet.com/api/";
 // const token = "VOTRE_TOKEN_ICI"; // Remplacez par le token réel
  const url = `${baseURL}/modifier-infos-admin?minimum_retrait_affiliation=${formValues.minimumRetraitAffiliation}&taux_affiliation_depot=${formValues.tauxAffiliationDepot}&taux_affiliation_retrait=${formValues.tauxAffiliationRetrait}&montant=${encodeURIComponent(formValues.montant)}&mode=${formValues.mode}&codePromo=${formValues.codePromo}`;

  setLoading(true); // Démarre le chargement
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`, // Ajoute le token dans l'en-tête Authorization
        "Content-Type": "application/json", // Spécifie le type de contenu
      },
    });

    /*
    {{baseURL}}modifier-infos-admin?minimum_retrait_affiliation=100&taux_affiliation_depot=5&taux_affiliation_retrait=10&montant=15000,2500,3500000000000000&mode=manuel

    */

    // Vérification du succès de la requête
    if (response.ok) {
      const data = await response.json(); // Convertit la réponse en JSON
      console.log("Réponse reçue:", data);

        setLoading(false);
      if (!data.error) {
   //     alert(data.message); // Affiche le message de succès de la réponse

        ajoutAgentSucces()
      } else {
        alert("Erreur lors de la modification: " + data.message); // Affiche le message d'erreur de la réponse
      }
    } else {
      alert("Erreur HTTP: " + response.status); // Affiche le code d'erreur HTTP si la réponse n'est pas OK
    }
  } catch (error) {
      setLoading(false);
    console.error("Erreur:", error);
    alert("Erreur de connexion au serveur: " + error.message); // Affiche le message d'erreur lors d'une exception
  } finally {
    setLoading(false); // Arrête le chargement
  }
};

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Appelle la fonction pour envoyer la requête avec les valeurs du formulaire
    sendRequest();

    onSave(formValues); // Appelle la fonction de sauvegarde
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-col">
        <label className="font-medium text-gray-600">Code Promo:</label>
        <input
          type="text"
          name="codePromo"
          value={formValues.codePromo}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="flex flex-col hidden">
        <label className="font-medium text-gray-600">Montant:</label>
        <input
          type="text"
          name="montant"
          value={formValues.montant}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="flex flex-col ">
        <label className="font-medium text-gray-600 ">Minimum Retrait Affiliation:</label>
        <input
     
        
          type="text"
          name="minimumRetraitAffiliation"
          value={formValues.minimumRetraitAffiliation}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="flex flex-col">
        <label className="font-medium text-gray-600">Mode:</label>
        <select
          name="mode"
          value={formValues.mode}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="manuel">Manuel</option>
          <option value="semi">Semi</option>
          <option value="automatique">Automatique</option>
        </select>
      </div>

      <div className="flex flex-col">
        <label className="font-medium text-gray-600">Taux Affiliation Dépôt:</label>
        <input
          type="text"
          name="tauxAffiliationDepot"
          value={formValues.tauxAffiliationDepot}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      <button className="mt-4 w-full py-2 bg-red-500 text-white  flex flex-row justify-center items-center font-bold rounded-lg hover:bg-red-600 transition duration-200">
        {loading ? (
          <div className="w-8 h-8 mr-2 animate-spin">
            <CircularProgressbar
              value={progress}
              styles={buildStyles({
                pathColor: "white",
                trailColor: "transparent",
                strokeLinecap: "round",
              })}
            />
          </div>
        ) : (
          <FontAwesomeIcon icon={faSave} className="mr-2" />
        )}
        <span className="text-white">
          {loading ? "Enregistrement..." : "  Sauvegarder"}
        </span>
      </button>
    </form>
  );
};

const PromoEditor = () => {

  const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        listeCaisses,
        token
    } = useContext(InfosContextAdmin);
  const [promoDetails, setPromoDetails] = useState({
    codePromo:infosAdmin.codePromo,
    minimumRetraitAffiliation:infosAdmin.minimum_retrait_affiliation,
    mode:infosAdmin.mode,
    montant:infosAdmin. montant,
    tauxAffiliationDepot: infosAdmin.taux_affiliation_depot,
    tauxAffiliationRetrait: "10",
  });

  const handleSave = (newDetails) => {
    setPromoDetails(newDetails);
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white rounded-2xl shadow-2xl mt-5 border border-white">
      <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-6">Modification</h2>
      <PromoEditore promoDetails={promoDetails} onSave={handleSave} />
    </div>
  );
};

export default PromoEditor;
