import React,{useContext,useState,useEffect} from 'react'
import StatisticsChart from '../components/StatisticsChart';
import { TbMoneybag } from "react-icons/tb";
import { PiMoney } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";
import { ImEye } from "react-icons/im";


//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";



//import InfosContext from "../../contexts/InfosAppProvider";
const Dashbord = () => {


  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
   
  } = useContext(InfosContext);



const [totalDepots, setTotalDepots] = useState(0);
  const [totalRetraits, setTotalRetraits] = useState(0);
  const [totalEncours, setTotalEncours] = useState(0);

  // Fonction pour formater la date (retourne un jour au format 'yyyy-mm-dd')
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0]; // Retourne la date au format 'yyyy-mm-dd'
  };

  // Fonction pour obtenir les 7 derniers jours
  const getLast7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(formatDate(date.getTime()));
    }
    return dates;
  };

  useEffect(() => {
    const dates = getLast7Days();
    let depots = 0;
    let retraits = 0;
    let enCours=0

    // Parcourir toutes les transactions
    Object.values(transactions).forEach((transaction) => {
      const dateTransaction = formatDate(transaction.dateHeure);

      // Vérifier si la transaction est dans les 7 derniers jours
      // if (dates.includes(dateTransaction)) {
      if (dates.includes(dateTransaction)) {
        const montant = parseFloat(transaction.montant);



        // Vérifier si c'est un dépôt
        if (transaction.type === 'depot') {
          if(transaction.statut === 'terminé'){
             depots += montant;

          }
         
        }

        // Vérifier si c'est un retrait
        if (transaction.type === 'retrait') {
        

           if(transaction.statut === 'terminé'){
            // depots += montant;

               retraits += montant;

          }
        }


         
          if(transaction.statut === 'en cours'){
             enCours += montant;

          }
         
      
      }
    });

    // Mettre à jour les totaux dans le state
    setTotalDepots(depots);
    setTotalRetraits(retraits);
     setTotalEncours(enCours)
  }, [transactions]); // Recalcule lorsque `transactions` change

  return (
    <div className='mx-5 my-10 admin-theme'>
      <div className='bg-white p-10 rounded'>
        <h1 className='text-start font-semibold text-2xl text-black mb-10'>Recapitulatif 7 derniers jours </h1>
        <div className='flex items-center gap-4 justify-between md:flex-nowrap flex-wrap'>
          <div className='border-2 border-blue bg-lightBlue rounded-md flex items-center justify-between py-4 px-8 gap-4'>
            <div className='rounded-full  p-4 bg-lightBlue text-blue text-2xl'>
              <TbMoneybag />
            </div>
            <div>
              <div className='text-base uppercase'>
             total  Dépôt 
              </div>
              <div className='flex items-center gap-1'>
                <span> {totalDepots}   F CFA  </span>
              
              </div>
            </div>
          </div>
          <div className='border-2  border-orange-400 bg-orange-100 rounded-md flex items-center justify-between py-4 px-8 gap-4'>
            <div className='rounded-full p-4 bg-orange-200 text-orange-400 text-2xl'>
              <PiMoney />
            </div>
            <div>
              <div className='text-base uppercase'>
                total  Retrait
              </div>
              <div className='flex items-center gap-1'>
                <span>  {totalRetraits} F CFA </span>

              </div>
            </div>
          </div>
          <div className='border-2 border-green-400 bg-green-100 rounded-md flex items-center justify-between py-4 px-8 gap-4'>
            <div className='rounded-full p-4 bg-green-200 text-green-400 text-2xl'>
              < GiReceiveMoney  />
            </div>
            <div>
              <div className='text-base uppercase'>
              Total en cours
              </div>
              <div className='flex items-center gap-1'>
                <span> {totalEncours}  F CFA</span>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-10 text-center'>
        <h1 className='text-3xl'>Tableau de bord</h1>
       {
        // staistqiues 

        <StatisticsChart />


       }
      </div>
    </div>
  )
}

export default Dashbord
